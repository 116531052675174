import axios from "axios";
import React, { createRef, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";

export default function LiscenseKeyInput() {
  const { state, dispatch } = useContext(UserContext);
  const [fullKey, setFullKey] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState([] as string[]);
  const inputs = [0, 1, 2, 3, 4];
  const inputsRef = useRef(inputs.map(() => createRef<HTMLInputElement>()));
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const updateCode = () => {
    let newCode = "";
    if (inputsRef.current) {
      newCode = inputsRef.current
        .map((ref) => {
          if (ref.current) {
            return ref.current.value.toUpperCase() || " ";
          } else {
            return "";
          }
        })
        .join("-");
    }
    setFullKey(newCode);
    if (checkCode(newCode)) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const checkCode = (key: string) => {
    if (key.length !== 29) return false;
    let keys = key.split("-");
    let crc = keys[keys.length - 1];
    let check = 0;
    let checkAsString = "";
    let valid = true;
    keys.slice(0, 4).forEach((section) => {
      if (section.length !== 5 || !section.match(/^[a-z0-9]+$/i)) {
        valid = false;
      } else {
        let keynum = parseInt(section.toLowerCase(), 36);
        check ^= keynum;
        checkAsString = check.toString(36).toUpperCase().slice(0, 5);
      }
    });
    if (!valid) return false;
    while (checkAsString.length < 5) {
      checkAsString += "Z";
    }
    return crc === checkAsString;
  };
  const submitKey = () => {
    if (!state.isAuthenticated) {
      navigate("/login");
      return;
    }
    if (!checkCode(fullKey)) {
      setButtonDisabled(true);
      return;
    }
    setButtonDisabled(true);
    axios.get("/api/users/get_csrf/").then(() => {
      axios
        .post("/api/licenses/" + fullKey + "/activate/")
        .then((res) => {
          if (res.status === 200) {
            axios.get("/api/users/me/").then((userdataRes) => {
              dispatch({ type: AuthTypes.UpdateUserData, payload: userdataRes.data });
              setButtonDisabled(false);
              setFullKey("");
              navigate("/");
            });
          }
        })
        .catch((err) => {
          setButtonDisabled(false);
          if (err && err.response && err.response.data) {
            for (let key in err.response.data) {
              let errors: string[] = [];
              if (typeof err.response.data[key] === "string") {
                errors = [err.response.data[key]];
              } else {
                errors = err.response.data[key];
              }
              setErrors(errors);
            }
          }
        });
    });
  };

  return (
    <>
      <div className="flex flex-wrap justify-center items-center">
        {inputs.map((id) => {
          return (
            <div key={id} className="flex">
              <input
                onPaste={(ev) => {
                  ev.preventDefault();
                  let codes = ev.clipboardData.getData("text").split("-");
                  if (inputsRef.current) {
                    inputsRef.current.forEach((ref, i) => {
                      if (ref.current) {
                        ref.current.value = codes[i] || "";
                      }
                    });
                  }
                  updateCode();
                }}
                ref={inputsRef.current[id]}
                className="w-[6ch] mx-1 bg-transparent uppercase border-b-2 border-primary-900 dark:border-primary-200 focus:ring-0 focus:outline-none text-primary-1100 dark:text-primary-200 text-lg text-center"
                type="text"
                maxLength={5}
                placeholder="XXXXX"
                onChange={(ev) => {
                  const event = ev.nativeEvent as InputEvent;
                  if (["deleteContentForward", "deleteContentBackward", "deleteContent"].includes(event.inputType)) {
                    if (inputsRef && inputsRef.current[id - 1] && inputsRef.current[id - 1].current && inputsRef!.current[id]!.current!.value === "" && inputsRef.current[id - 1].current?.focus) {
                      inputsRef!.current[id - 1]!.current!.focus();
                    }
                  } else {
                    if (inputsRef && ev.target.value.length >= 5 && inputsRef.current[id + 1] && inputsRef.current[id + 1].current && inputsRef.current[id + 1].current?.focus) {
                      inputsRef!.current[id + 1]!.current!.focus();
                    }
                  }
                  updateCode();
                }}
              />
              {id !== inputs.length - 1 ? (
                <>
                  <p className="text-primary-900 dark:text-primary-500">-</p>
                </>
              ) : (
                <></>
              )}
            </div>
          );
        })}
        <button
          onClick={(ev) => {
            ev.preventDefault();
            submitKey();
          }}
          className={
            "text-white font-thin m-4 duration-100 ease-in-out focus:ring-1 rounded-md text-md w-full sm:w-auto px-3 py-1.5 text-center self-center justify-self-center " +
            (buttonDisabled ? "bg-gray-500 text-gray-200 pointer-events-none" : "bg-primary-500 hover:bg-primary-400 focus:ring-primary-200 focus:outline-none")
          }
        >
          Activate
        </button>
      </div>
      {errors.length ? (
        <div className="errors text-red-500 dark:text-red-300 w-full flex  flex-col">
          {errors.map((err, i) => {
            return (
              <p key={i} className="font-thin before:content-['_•_'] px-2 w-fit">
                {err.trim() === "" ? "Something went wrong. Please try again later." : err}
              </p>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
