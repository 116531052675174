import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import { LicenseObject } from "../auth/LicenseBadge";

const item = (key: number, name: string, data: string) => {
  return (
    <li key={key} className="row w-full flex justify-between odd:border-y-2 border-dashed border-gray-400 py-1 odd:bg-gray-100 dark:odd:bg-slate-800">
      <h5 className="text-primary-1200 dark:text-white font-medium">{name}:</h5>
      <h5 className="text-blue-600 dark:text-blue-300 font-thin">{data}</h5>
    </li>
  );
};

export const calculateDaysRemaining = (license: LicenseObject) => {
  if (license.is_active) {
    let time_activated = new Date(0);
    time_activated.setMilliseconds(new Date().getTime() - Date.parse(license.time_activated));
    return Math.ceil(license.duration - time_activated.getTime() / (1000 * 24 * 60 * 60));
  }
  if (license.is_expired) {
    return 0;
  }
  return license.duration;
};

export default function PlanView() {
  const { state, dispatch } = useContext(UserContext);
  const params = useParams();
  const location = useLocation();
  const [license, setLicense] = useState({} as LicenseObject);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState([] as string[]);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "My Plan | whatstheword.io";
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
          navigate("/");
        });
      return;
    }
    if (location.state) {
      setLicense(location.state as LicenseObject);
    } else {
      axios.get("/api/licenses/" + params.id + "/").then((res) => {
        setLicense(res.data);
      });
    }
  }, [state.isAuthenticated, dispatch, location.state, params.id]);

  const getData = () => {
    if (!license.id) return [];
    let daysRemaining = calculateDaysRemaining(license);

    return [
      { name: "License Key", data: license.id, opts: { show: true } },
      { name: "License Owner", data: license.user_username, opts: { show: true } },
      { name: "Plan Type", data: license.license_type + (license.is_trial ? " Trial" : " Plan"), opts: { show: true } },
      { name: "Date Purchased", data: license.date_purchased, opts: { show: true } },
      { name: "Currently Active?", data: license.is_active ? "Active" : "Inactive", opts: { show: true } },
      { name: "Will Automatically Renew?", data: license.will_auto_renew ? "Yes" : "No", opts: { show: !license.is_trial } },
      { name: "Will Renew On", data: license.renew_time, opts: { show: license.will_auto_renew } },
      { name: "Is Expired?", data: license.is_expired ? "Expired" : "No", opts: { show: true } },
      { name: "Days Remaining", data: daysRemaining.toString(), opts: { show: true } },
    ];
  };

  const handleCancel = () => {
    if (!license.will_auto_renew) return;
    setButtonDisabled(true);
    setErrors([]);
    if (window.confirm("Are you sure you want to cancel your billing subscription? Your license will no longer auto-renew.")) {
      axios.get("/api/users/get_csrf/").then(() => {
        axios
          .post("/api/licenses/" + license.id + "/deactivate/")
          .then((res) => {
            setLicense(res.data);
          })
          .catch((err) => {
            setButtonDisabled(false);
            if (err && err.response && err.response.data) {
              for (let key in err.response.data) {
                let errors: string[] = [];
                if (typeof err.response.data[key] === "string") {
                  errors = [err.response.data[key]];
                } else {
                  errors = err.response.data[key];
                }
                setErrors(errors);
              }
            }
          });
      });
    }
  };

  return (
    <div className="bg-stone-50 dark:bg-slate-900 h-full w-full p-2">
      <div className="shadow-md dark:shadow-gray-700 bg-white:darkbg-slate-800 rounded-md flex flex-col">
        <ul className="list-inside p-5">
          {getData()
            .filter((data) => data.opts.show)
            .map((data, i) => {
              return item(i, data.name, data.data);
            })}
        </ul>
        {license.will_auto_renew ? (
          <div className="flex flex-col justify-end items-end m-2">
            <button
              onClick={() => {
                handleCancel();
              }}
              disabled={buttonDisabled}
              className="bg-red-500 dark:bg-red-800 dark:hover:bg-red-700 hover:bg-red-400 disabled:bg-gray-500 duration-100 ease-in-out py-1.5 px-2 rounded m-2 text-white"
            >
              Cancel Subscription
            </button>
            <p className="text-red-700 dark:text-red-300 text-sm">* this will cancel the next billing cycle, but will not delete the license. You can still use it for the days remaining.</p>
          </div>
        ) : (
          <></>
        )}
        {errors.length ? (
          <div className="errors text-red-500 dark:text-red-300 w-full flex  flex-col">
            {errors.map((err, i) => {
              return (
                <p key={i} className="font-thin before:content-['_•_'] px-2 w-fit">
                  {err.trim() === "" ? "Something went wrong. Please try again later." : err}
                </p>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
