import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import OnlineButton from "../basic/OnlineButton";
import ImagePicker from "./ImagePicker";

export default function ImageManager() {
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "My Images | whatstheword.io";
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
          navigate("/login");
        });
    }
    if (state.isAuthenticated && !state.has_valid_email) {
      navigate("/activate/reminder");
      return;
    }
  }, [state.isAuthenticated, dispatch, navigate]);
  return (
    <div className="w-full bg-stone-50 dark:bg-slate-900 ">
      <div className="min-h-[5rem] relative gap-4 p-3 flex flex-col md:flex-row justify-center items-center bg-slate-200 dark:bg-slate-700">
        <p className="text-lg text-primary-500 dark:text-primary-100 font-bold">Manage Your Images Here!</p>
        <div className="h-full w-fit relative md:absolute right-0 pr-4 flex justify-center items-center">
          <OnlineButton
            onClick={() => {
              navigate("/public-images");
            }}
          ></OnlineButton>
        </div>
      </div>
      <div className="flex flex-col h-full lg:grid lg:grid-cols-2 lg:grid-rows-2  bg-slate-50 dark:bg-slate-800">
        <ImagePicker isPublic={false} type="CHARACTERS"></ImagePicker>
        <ImagePicker isPublic={false} type="MONSTERS"></ImagePicker>
        <ImagePicker isPublic={false} type="BACKGROUNDS"></ImagePicker>
        <ImagePicker isPublic={false} type="PROJECTILES"></ImagePicker>
      </div>
    </div>
  );
}
