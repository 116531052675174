import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Emphasis from "../basic/Emphasis";
import HowToContainer from "./HowToContainer";
import HowToSection from "./HowToSection";
import alienchasebasic1 from "src/assets/images/howto/alienchasebasic.webp";
import alienchase from "src/assets/images/howto/alienchase.webp";
import alienchase2 from "src/assets/images/howto/alienchase2.webp";
import fighters from "src/assets/images/howto/fighters.webp";
import hangmanbattle from "src/assets/images/howto/hangmanbattle.webp";
import hangmanbattle2 from "src/assets/images/howto/hangmanbattle2.webp";
import monstermash from "src/assets/images/howto/monstermash.webp";
import twister from "src/assets/images/howto/twister.webp";
import openthesafe from "src/assets/images/howto/openthesafe.webp";
import HowToLine from "./HowToLine";
import HowToImageBox from "./HowToImageBox";

export default function HowToPage() {
  useEffect(() => {
    document.title = "How To | whatstheword.io";
  }, []);
  return (
    <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900 p-5">
      <div className="w-full p-3 bg-stone-50 dark:bg-gray-800 shadow-gray-900 dark:shadow-primary-700 shadow-sm rounded-md mb-10">
        <h4 className="font-bold lg:text-lg text-primary-1100 dark:text-white mb-3">Click a game to jump to the section</h4>
        <div
          className="grid grid-cols-1 grid-row-4 mb-5
        md:grid-cols-2 md:grid-rows-2 xl:grid-cols-4 xl:grid-rows-1"
        >
          <ul className="list-inside p-2 text-center lg:text-lg underline text-primary-800 dark:text-white">
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#wordsearch">Word Search</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#alienchasebasic">Alien Chase Basic</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#fightersbasic">Fighters Basic</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#openthesafe">Open The Safe</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#wordbrain">Word Brain</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#hangmanbattle">Hangman Battle</a>
            </li>
          </ul>
          <ul className="list-inside p-2 text-center lg:text-lg underline text-primary-800 dark:text-white">
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#prepositions">Prepositions</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#numberline">Number Line</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#timetrial">Time Trial</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#spottheword">Spot The Word</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#alienchase">Alien Chase</a>
            </li>
          </ul>
          <ul className="list-inside p-2 text-center lg:text-lg underline text-primary-800 dark:text-white">
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#ufoblockade">UFO Blockade</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#fighters">Fighters</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#monstermash">Monster Mash</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#twister">Twister</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#alienblaster">Alien Blaster</a>
            </li>
          </ul>
          <ul className="list-inside p-2 text-center lg:text-lg underline text-primary-800 dark:text-white">
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#fourinarow">Four In A Row</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#characterwheel">Character Wheel</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#numberwheel">Number Wheel</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#wordwheel">Word Wheel</a>
            </li>
            <li className="font-medium text-primary-800 dark:text-white mb-2">
              <a href="/how-to#spacefight">Space Fight</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <HowToContainer title="Word Search">
          <HowToSection>
            <HowToLine>This is a word search. Click a letter and drag your finger/mouse to the final letter. Let go to select.</HowToLine>
            <HowToLine>
              <Emphasis>You can choose the size of the word search before you start</Emphasis>, to adjust to the level of your students.
            </HowToLine>
            <HowToLine>
              If you click the "<span className="text-primary-500">?</span>" Button in the top left corner, the <Emphasis>hidden words will quickly flash a color</Emphasis>. This lets the students see the hidden words, and they can try one
              by one.
            </HowToLine>
            <HowToLine>
              You can let the student who tried to choose the next student, and <Emphasis>you can relax, stand back, and let the students take over the class procedure</Emphasis>.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Alien Chase Basic">
          <HowToSection>
            <HowToLine>
              <Emphasis>This game can be used for any length of word. </Emphasis>
            </HowToLine>
            <HowToLine>Ask a student to either pick a word, or ask them to read the word in front of them. Then either you or the student can choose a difficulty.</HowToLine>
            <HowToImageBox image={alienchasebasic1} className="md:w-[16rem] md:h-[12rem] w-[10rem]"></HowToImageBox>
            <HowToLine>
              <Emphasis>You can always toggle the difficulty mid-game</Emphasis>, if a student chose something too easy or too difficult. There is also <Emphasis>a secret "crazy" difficulty</Emphasis>, that is only available through the{" "}
              <Emphasis>difficulty toggle in the top left corner</Emphasis>.{" "}
            </HowToLine>
            <HowToLine>
              All they need to do is construct the word again. <Emphasis>You can ask the whole class to help out and shout the next letter.</Emphasis>{" "}
            </HowToLine>
            <HowToLine>Once a student tried, they can then choose who will try next, (making sure they choose someone who didn't try). </HowToLine>
            <HowToLine>
              This means that{" "}
              <Emphasis>
                you can relax, stand back, and only click the "<span className="text-primary-700 dark:text-primary-300">retry</span>" button in the top left corner every now and then
              </Emphasis>
              , and the students take over the whole class procedure.
            </HowToLine>
            <HowToLine>
              This game was extremely popular with our students, and was expanded greatly with{" "}
              <a href="/how-to#alienchase" className="text-blue-500 underline">
                Alien Chase
              </a>
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Fighters Basic">
          <HowToSection>
            <HowToLine>
              This game is very simple, but is very useful. We use it to <Emphasis>get all the kids attention, as a reward, or just to use up the last minutes of class</Emphasis> while reviewing the topic.
            </HowToLine>
            <HowToLine>
              Start with the character select. The select screen will choose 4 random characters out of your character list. We have found that limiting the choices to 4 is the best, as having them all available means the class will only
              choose the same character every time, and having one removes the choice from the students.
            </HowToLine>
            <HowToLine>
              You can ask the students which character they like best, by a show of hands.<Emphasis> Pick the character they choose.</Emphasis>
            </HowToLine>
            <HowToLine>
              You can ask the students to repeat the flashcards, or the part you are reading, all at once. If they repeat loudly and correctly,{" "}
              <Emphasis>
                hit the "<span className="text-yellow-500">POWER</span>" button a few times
              </Emphasis>
              . If they don't repeat, hit it once or not at all.
            </HowToLine>
            <HowToLine>
              Every now and then, have they say the target sentence or some more words, and
              <Emphasis>
                {" "}
                hit the "<span className="text-green-500">BLOCK</span>" or "<span className="text-red">SHOOT</span>" buttons
              </Emphasis>
              .
            </HowToLine>
            <HowToLine>
              If you are doing a class with reading, you can ask each student to read a section, and <Emphasis>they can choose whether to power up, block or shoot</Emphasis>.
            </HowToLine>
            <HowToLine>
              Once you beat the monster, you can click the "<span className="text-primary-500">retry</span>" button that appears.
            </HowToLine>
            <HowToLine>
              <Emphasis>There is no secret button or hardmode, and no bosses</Emphasis> like in the full version of{" "}
              <a className="text-blue-500 underline" href="/how-to#fighters">
                Fighters
              </a>
              .
            </HowToLine>
          </HowToSection>
        </HowToContainer>

        <HowToContainer title="Open The Safe">
          <HowToSection>
            <HowToLine>
              This game <Emphasis>can be used for any length of word.</Emphasis>{" "}
            </HowToLine>
            <HowToLine>Choose a size of board you want. Tiny is usually 3x3, small is usually 4x4, and big is 5x5, but the board will adjust to the number of letter-word combinations available. </HowToLine>
            <HowToImageBox image={openthesafe} className="w-[25rem] h-[9.5rem]"></HowToImageBox>
            <HowToLine>
              Press <span className="capitalize text-primary-500">start</span> to begin the game.{" "}
            </HowToLine>
            <HowToLine>
              Once the keypad appears, you can type the keywords in.<Emphasis> Some of the keywords might not be included, if their letters are missing.</Emphasis> So the kids must figure out which words are available based on the letters
              on the keypad.{" "}
            </HowToLine>
            <HowToLine>
              You get 3 tries. <Emphasis>If you make 3 spelling mistakes, the word is reset</Emphasis>.{" "}
            </HowToLine>
            <HowToLine>You can ask the whole class to help out and shout the next letter. </HowToLine>
            <HowToLine>Once a kid tried, they can then choose who will try next, (making sure they choose someone who didn't try). </HowToLine>
            <HowToLine>
              This means that <Emphasis>you can relax, stand back, and let the kids take over the whole class procedure.</Emphasis>
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Word Brain">
          <HowToSection>
            <HowToLine>
              This is the classic game of mastermind. You have a hidden word, and you <Emphasis>must guess what the hidden word</Emphasis>.
            </HowToLine>
            <HowToLine>Red letters means the letter doesn't exist in the word. Yellow means it exists, but is in the wrong location. Green means the letter is correct and is in the correct location.</HowToLine>
            <HowToLine>
              Students have an <Emphasis>unlimited number of tries</Emphasis>. You can keep track, and see who gets the lowest score. You can also give each student a number of tries, and make it a team effort to solve.
            </HowToLine>
            <HowToLine>
              Like most of the games, you can ask the students to choose who will try next, and <Emphasis>you can relax, stand back, and hit the "play again" button</Emphasis> every now and then.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Hangman Battle">
          <HowToSection>
            <HowToImageBox image={hangmanbattle} className="md:w-[13rem] md:h-[33rem]"></HowToImageBox>
            <HowToLine>
              This game is <Emphasis>designed for a full class participation</Emphasis>, and can be used for any words or sentences.{" "}
            </HowToLine>
            <HowToLine>
              Split the class up into 2 teams. Press the "<span className="text-green-500">GO</span>" button, and let each team choose their character.{" "}
            </HowToLine>
            <HowToLine>
              Once the game starts each team can choose one letter. (Basic hangman rules). You press the "<span className="text-green-500">READY</span>" button for both teams, and then press the green check mark. The team who got the
              correct letter, will attack the other team.{" "}
            </HowToLine>

            <HowToLine>You can either have everyone shout the letter, or you can have them raise their hands. Or you can have every kid pick a letter one by one each turn.</HowToLine>
            <HowToLine>
              The <Emphasis>words do not repeat</Emphasis> (unless the entire world list is used) and the words will try to be as equal as possible to give both teams a fair chance. So the strategy is to remember the words your team already
              completed to help you guess the next ones. Completing a word will allow the team to do a big final attack.{" "}
            </HowToLine>

            <HowToImageBox image={hangmanbattle2} right={true} className="md:w-[25rem] md:h-[8rem]"></HowToImageBox>
            <HowToLine>
              <Emphasis>There is a secret button</Emphasis> here. <Emphasis>Double tapping the health bar</Emphasis> of the team will give that team a slight advantage. For the next 2-3 turns they will do a bit more damage and will win any
              tie breakers. This can be used if a team has been very unlucky and the students are not happy. This will give them the "luck" they need.{" "}
            </HowToLine>
            <HowToLine>
              There is <Emphasis>another secret tool</Emphasis>. If any of the characters, letters, or attacks get stuck, there is an <Emphasis>invisible refresh button</Emphasis> in the top left corner. Clicking it will refresh the
              character positions, the words, etc. It won't restart anything.{" "}
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Prepositions">
          <HowToSection>
            <HowToLine>This is a helpful tool for teaching or reviewing prepositions.</HowToLine>
            <HowToLine>
              The various words are at the top. Click them to make the character go to that location, or press "<span className="text-primary-500">random</span>" to go to a random one.
            </HowToLine>
            <HowToLine>
              You can <Emphasis>click the character sprite</Emphasis> to change to a new character.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Number Line">
          <HowToSection>
            <HowToLine>This is a helpful tool to help teach or review numbers, or ordinal numbers.</HowToLine>
            <HowToLine>
              You can <Emphasis>choose the number of characters</Emphasis> to use, and clicking "<span className="text-gray-500 dark:text-white">GO</span>" will make the characters go to the correct position.
            </HowToLine>
            <HowToLine>
              You can click "<span className="text-primary-500">numbers</span>" to make the numbers appear or disappear. (The color of the number is the character's favourite color).
            </HowToLine>
            <HowToLine>
              You can click "<span className="text-primary-500">ordinals</span>" to show the ordinal text next to the numbers.
            </HowToLine>
            <HowToLine>If you choose a number bigger than the number of characters you have, repeat characters will be differently colored.</HowToLine>
            <HowToLine>
              You can use this is many ways, it's up to you and how you teach your class. We usually hide the numbers, and ask, e.g. "which position is the fairy?" And have a student answer. Or, "who is 7th?" And have the students answer.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Time Trial">
          <HowToSection>
            <HowToLine>
              This game is <Emphasis>best for medium length words</Emphasis>. Although it does work for other lengths, 5-7 letters are the most fun.
            </HowToLine>
            <HowToLine>
              The goal is to <Emphasis>complete the words under the time limit</Emphasis>. It is very flexible, and pressing the <span className="text-primary-500 font-bold">+</span> or <span className="text-primary-500 font-bold">-</span>{" "}
              buttons lets you adjust the time.
            </HowToLine>
            <HowToLine>
              <Emphasis>There are 3 difficulties</Emphasis>, easy means the letters sometimes spin, medium means some of the letters randomly turn upside down, and hard means the letters go crazy.
            </HowToLine>
            <HowToLine>
              The <Emphasis>letters can sometimes jump away</Emphasis> instead of being pressed, so the student must pay attention.
            </HowToLine>
            <HowToLine>
              The <Emphasis>first letter of the word is outlined in blue</Emphasis>. This lets you try to guess what the word will be. You can make this much harder if all of your words are similar lengths and start with the same letter.
            </HowToLine>
            <HowToLine>
              Then just press <span className="text-primary-500">retry</span> to get a new word. The way we play it in our classes, is the student that wins gets to choose how many seconds the next student gets. (Within reason)
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Spot The Word">
          <HowToSection>
            <HowToLine>
              This is a game that can be very flexible. You can click <span className="text-primary-500">start</span> or click the words to start the game.
            </HowToLine>
            <HowToLine>
              Choose 2 cards. After the time limit, the students <Emphasis>must find the one word that appears on both cards</Emphasis>. The first one to find it is the winner.
            </HowToLine>
            <HowToLine>
              You can <Emphasis>adjust the difficulty</Emphasis> (mid-game if needed) to change the number of words that appears on the cards.
            </HowToLine>
            <HowToLine>If there are more words than in your wordlist, a random selection of words will be selected.</HowToLine>
            <HowToLine>
              You can also <Emphasis>disable the images on the cards if you need</Emphasis>. We found some of our slower computers lag with images when shuffling the deck, so if that's something that affects you, you can choose to disable
              them. Using the colored backs means you can also ask the kids which color card they want.
            </HowToLine>
            <HowToLine>
              You can split the class into teams, and have one student from each team come up, and do a 1v1 for 3-4 cards. Or you can do a 2v2, 3v3, etc. You can have the whole class participate at the same time, or you can have the whole
              class go against the teacher. You can have a tournament bracket, a double elimination tournament, whatever you like!
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Alien Chase">
          <HowToSection>
            <HowToLine>
              This game <Emphasis>can be used for any length of word</Emphasis>.{" "}
            </HowToLine>
            <HowToLine>Ask a student to either pick a word, or ask them to read the word in front of them. Then either you or the student can choose a difficulty.</HowToLine>

            <HowToLine>
              There is also a custom difficulty, which is the most fun. You can click "<span className="text-gray-600 dark:text-gray-200">Choose your Own</span>" and have the students select the aliens they want to deal with.{" "}
              <Emphasis>Each color has its own personality</Emphasis>, and some are more difficult than others. For example the blue ones slowly move in cardinal directions, while the scary ones at the end chase you down really fast.{" "}
            </HowToLine>
            <HowToImageBox image={alienchase} className="md:w-[25rem] md:h-[8rem]"></HowToImageBox>

            <HowToLine>
              There are <Emphasis>two secret buttons here</Emphasis>. One is clicking the "<span className="text-gray-600 dark:text-gray-200">Max: 10</span>" text. This will case a scrollbar to appear in the top left corner. This lets you
              change the maximum difficulty the students can pick.{" "}
            </HowToLine>

            <HowToImageBox image={alienchase2} right={true} className="md:w-[20rem] md:h-[15rem]"></HowToImageBox>
            <HowToLine>
              There's a <Emphasis>second secret button, in the top right corner</Emphasis>. It is a secret admin panel. It has a challenges button, which when clicked while in the "choose your option", will open up some challenges. These
              allow difficulties that far surpass the maximum difficulty. (Be warned, having so many aliens on the screen can be very laggy on slow computers, which is why the challenges are hidden away.){" "}
            </HowToLine>

            <HowToLine>
              The other option in the admin panel, are two scroll bars. They affect the speed and momentum of the aliens. <Emphasis>Set it to 0 to stop them temporarily</Emphasis>, if a student is having trouble. The other buttons are just
              in case of lag or some bugs, but they are usually not needed.{" "}
            </HowToLine>

            <HowToLine>
              All they need to do is construct the word again. <Emphasis>You can ask the whole class to help out</Emphasis> and shout the next letter.{" "}
            </HowToLine>

            <HowToLine>
              Once a student tried, they can then choose who will try next, (making sure they choose someone who didn't try). This means that{" "}
              <Emphasis>you can relax, stand back, and only click the "retry" button in the top left corner</Emphasis> every now and then, and the students take over the whole class procedure.
            </HowToLine>
            <HowToLine>You can also change the rules, if a student succeeds to build the word, he can choose the aliens the next student has to deal with.</HowToLine>
          </HowToSection>
        </HowToContainer>

        <HowToContainer title="UFO Blockade">
          <HowToSection>
            <HowToLine>
              This game is <Emphasis>best for longer words</Emphasis>. Small words are a bit too easy.{" "}
            </HowToLine>
            <HowToImageBox image={monstermash} className="md:w-[16rem] md:h-[12rem] w-[10rem]"></HowToImageBox>
            <HowToLine>The UFOs will block off certain letters, meaning you must be able to spell the word in a different order.</HowToLine>
            <HowToLine>Ask a student to either pick a word, or ask them to read the word in front of them. Then either you or the student can choose a difficulty.</HowToLine>
            <HowToLine>
              <Emphasis>You can always toggle the difficulty mid-game</Emphasis>, if a student chose something too easy or too difficult. There is also <Emphasis>a secret "crazy" difficulty</Emphasis>, that is only available through the{" "}
              <Emphasis>difficulty toggle in the top left corner</Emphasis>.{" "}
            </HowToLine>
            <HowToLine>
              All they need to do is construct the word again. <Emphasis>You can ask the whole class to help out</Emphasis> and shout the letter of the available spaces.{" "}
            </HowToLine>
            <HowToLine>Once a student tried, they can then choose who will try next, (making sure they choose someone who didn't try). </HowToLine>
            <HowToLine>
              This means that{" "}
              <Emphasis>
                you can relax, stand back, and only click the "<span className="text-primary-700 dark:text-primary-300">retry</span>" button in the top left corner every now and then
              </Emphasis>
              , and the students take over the whole class procedure.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Monster Mash">
          <HowToSection>
            <HowToLine>
              This game is <Emphasis> best for students who find the other "build a word" games too difficult</Emphasis>. This game is much easier, and <Emphasis>can be used for any length of word</Emphasis>.
            </HowToLine>
            <HowToImageBox image={monstermash} className="md:w-[16rem] md:h-[12rem] w-[10rem] " right={true}></HowToImageBox>
            <HowToLine>Ask a student to either pick a word, or ask them to read the word in front of them. Then either you or the student can choose a difficulty.</HowToLine>
            <HowToLine>
              <Emphasis>You can always toggle the difficulty mid-game</Emphasis>, if a student chose something too easy or too difficult. There is also <Emphasis>a secret "crazy" difficulty</Emphasis>, that is only available through the{" "}
              <Emphasis>difficulty toggle in the top left corner</Emphasis>.{" "}
            </HowToLine>
            <HowToLine>
              <Emphasis>You can tap the monsters to make the go flying.</Emphasis>
            </HowToLine>
            <HowToLine>
              All they need to do is construct the word again. <Emphasis>You can ask the whole class to help out</Emphasis> and shout the next letter.{" "}
            </HowToLine>
            <HowToLine>Once a student tried, they can then choose who will try next, (making sure they choose someone who didn't try). </HowToLine>
            <HowToLine>
              This means that{" "}
              <Emphasis>
                you can relax, stand back, and only click the "<span className="text-primary-700 dark:text-primary-300">retry</span>" button in the top left corner every now and then
              </Emphasis>
              , and the students take over the whole class procedure.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Twister">
          <HowToSection>
            <HowToLine>
              This game is <Emphasis>best for short words</Emphasis>. Longer words are a bit too chaotic.{" "}
            </HowToLine>
            <HowToImageBox image={twister} className="md:w-[16rem] md:h-[12rem] w-[10rem]"></HowToImageBox>
            <HowToLine>Ask a student to either pick a word, or ask them to read the word in front of them. Then either you or the student can choose a difficulty.</HowToLine>
            <HowToLine>
              <Emphasis>You can always toggle the difficulty mid-game</Emphasis>, if a student chose something too easy or too difficult. There is also <Emphasis>a secret "crazy" difficulty</Emphasis>, that is only available through the{" "}
              <Emphasis>difficulty toggle in the top left corner</Emphasis>.{" "}
            </HowToLine>
            <HowToLine>All they need to do is construct the word again. You can ask the whole class to help out and shout the next letter. </HowToLine>
            <HowToLine>Once a student tried, they can then choose who will try next, (making sure they choose someone who didn't try). </HowToLine>
            <HowToLine>
              This means that{" "}
              <Emphasis>
                you can relax, stand back, and only click the "<span className="text-primary-700 dark:text-primary-300">retry</span>" button in the top left corner every now and then
              </Emphasis>
              , and the students take over the whole class procedure.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Alien Blaster">
          <HowToSection>
            <HowToLine>
              This is a game <Emphasis>best for medium length words</Emphasis>, but works for anything. Be warned, slower computers might have trouble with lots of aliens on the screen.{" "}
            </HowToLine>
            <HowToLine>
              Once you press the <span className="text-primary-500">GO</span> button, aliens will steal the letters and hide behind the buildings. You can{" "}
              <Emphasis>use the gun at the bottom to shoot them. Drag it left and right to aim, and click it to shoot.</Emphasis>
            </HowToLine>
            <HowToLine>
              <Emphasis>New weapons will sometimes fall down from the UFOs</Emphasis>. Click them to switch weapons. Each one is different, there's lots of weapons to find!
            </HowToLine>
            <HowToLine>
              There is a loss condition, but it's hard to achieve. If the first base pistol runs out of bullets, the UFOs will come after you. Running out of ammo on any subsequent weapon will return to the previous weapon with no
              negatives.
            </HowToLine>
            <HowToLine>
              There is a <Emphasis>time limit of 60 seconds, or 2 completed words</Emphasis>, whichever is faster. This allows a fair chance for kids who are fast, and those who are slow. Once the "Next Player" text shows up, click it to
              continue.{" "}
            </HowToLine>
            <HowToLine>You can ask the whole class to help out and shout the next letter.</HowToLine>
            <HowToLine>
              Once a kid tried, they can then choose who will try next, (making sure they choose someone who didn't try). This means that <Emphasis>you can relax, stand back, and only click the "Next Player" button</Emphasis> every now and
              then, and the kids take over the whole class procedure.
            </HowToLine>{" "}
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Fighters">
          <HowToSection>
            <HowToLine>
              This game is very simple, but has a <Emphasis>few secret buttons</Emphasis> to make the game more fun.
            </HowToLine>
            <HowToLine>
              Start with the character select. The select screen will choose 4 random characters out of your character list. We have found that limiting the choices to 4 is the best, as having them all available means the class will only
              choose the same character every time, and having one removes the choice from the students.
            </HowToLine>
            <HowToImageBox image={fighters} className="md:w-[25rem] md:h-[14rem]"></HowToImageBox>
            <HowToLine>You can ask the students which character they like best, by a show of hands. Pick the character they choose.</HowToLine>
            <HowToLine>
              If your class is a vocabulary class, you can ask the students to repeat the flashcards all at once. If they repeat loudly and correctly,
              <Emphasis>
                hit the "<span className="text-amber-500">POWER</span>" button a few times
              </Emphasis>
              . If they don't repeat, hit it once or not at all. Every now and then, have they say the target sentence or some more words, and{" "}
              <Emphasis>
                hit the "<span className="text-green-500">BLOCK</span>" or "<span className="text-red-500">SHOOT</span>" buttons
              </Emphasis>
              .
            </HowToLine>
            <HowToLine>If you are doing a class with reading, you can ask each student to read a section, and they can choose whether to power up, block or shoot.</HowToLine>
            <HowToLine>
              <Emphasis>
                There is a secret button right above the "<span className="text-amber-500">POWER</span>" button.
              </Emphasis>{" "}
              If you double click it, you will notice the "playing" button will become "Playing" (with a capital P). This denotes that you are now in hard mode, and the monster will shoot and block more often, and when he dies, a new
              monster will spawn.
            </HowToLine>
            <HowToLine>
              If you continue with hardmode, and <Emphasis>double tap the secret button again</Emphasis>, the next spawning monster becomes a boss trigger. At 50% health, a random effect will occur that spawns a boss event.
            </HowToLine>
            <HowToLine>
              If you beat the monster without hard mode, you can either <Emphasis>click the "retry" button that appears, or you can double click the secret button</Emphasis>, and it will secretly spawn a new monster after a few seconds.
              This let's you pretend the game is over, spawn the monster, and walk away, and oh no a new monster is coming down and the students better start shouting the key words.
            </HowToLine>
          </HowToSection>
        </HowToContainer>

        <HowToContainer title="Character Wheel">
          <HowToSection>
            <HowToLine>Need a character in a story? Or playing mad libs? Use the wheel to choose a random character. </HowToLine>

            <HowToLine>
              Click the "<span className="text-primary-500">SPIN</span>" button or just drag the wheel manually. You can also click the "<span className="text-yellow-500">NEW</span>" button if you want new characters.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Number Wheel">
          <HowToSection>
            <HowToLine>Need a random number for a sticky ball game? Or any game with points? Use this wheel to help make the choice more fun!</HowToLine>

            <HowToLine>
              You can choose what is <Emphasis>the maximum number that appears on the wheel</Emphasis>. If you want to use the wheel for, e.g "how many words to read", use <span className="text-primary-500">10</span>, want a crazy amount of
              points? Use <span className="text-primary-500">1000</span>.{" "}
            </HowToLine>

            <HowToLine>You can also choose if you want the wheel to have negative numbers or multiplication, if that's something that you can use for your game. </HowToLine>

            <HowToLine>
              Click the "<span className="text-primary-500">SPIN</span>" button or just drag the wheel manually. You can also click the "<span className="text-yellow-500">NEW</span>" button if you want new numbers on the wheel, or click the
              "<span className="text-green-500">ADD</span>" to increase the numbers.{" "}
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Word Wheel">
          <HowToSection>
            <HowToLine>
              This game is a tool <Emphasis>if you need a random word</Emphasis> in a fun way, for any other game you've come up with. You can rotate the wheel manually by dragging it or click the spin button.{" "}
            </HowToLine>

            <HowToLine>Perhaps you have a key sentence, you can ask a kid to say most of it, but the key word can be chosen by the wheel.</HowToLine>

            <HowToLine>
              You can choose how many section of the wheel to use. <Emphasis>The words will only repeat if you have more sections than words</Emphasis> in your wordlist.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Four In A Row">
          <HowToSection>
            <HowToLine>
              This is a modified version of the classic game Go. The goal is to <Emphasis>connect 4 tiles in a row</Emphasis>.
            </HowToLine>
            <HowToLine>
              <Emphasis>Start by drawing a player piece</Emphasis>. You can ask the students to draw, or the students can give ideas for a quick drawing,, e.g. a yellow triangle, a blue car, a word, a number, etc. You can use the slider to
              change colors. The picture button changes the canvas background color.
            </HowToLine>
            <HowToLine>
              <Emphasis>
                Click the green <span className="text-green-500">check mark</span> to finalize the piece
              </Emphasis>
              . You can change the picture at any time by clicking the <span className="text-amber-500">pencil</span> icon, adding some changes, and clicking the green checkmark again.
            </HowToLine>
            <HowToLine>
              You can then simply<Emphasis> click on the main grid where to place a piece</Emphasis>. 4 in a row will give that team the victory.
            </HowToLine>
            <HowToLine>
              The game doesn't end once one team wins, if you so choose,<Emphasis> you can keep playing for 2nd, 3rd and 4th</Emphasis> positions.
            </HowToLine>
            <HowToLine>You can also ask the students to click, or tell you the coordinate values, A5 B7 etc.</HowToLine>
            <HowToLine>There is an undo button if someone makes a mistake.</HowToLine>
            <HowToLine>
              <Emphasis>You can make this game more interesting by disabling tiles, and making a non-square board</Emphasis>. Click the clock button to see some pre-made patterns, or click the <span className="text-amber-500">pencil</span>{" "}
              inside the lock menu to draw your own. Experiment with the buttons, as they combine and overlap in interesting ways. The button to the left of the pencil and eraser is an invert button, which will invert the blocked pieces to
              be unblocked and vice versa. The <span className="text-pink-500">eraser</span> tool removes specific blocked tiles.
            </HowToLine>
            <HowToLine>
              The yellow <span className="text-yellow-500">X</span> button will clear all the pieces.
            </HowToLine>
            <HowToLine>
              <Emphasis>
                The <span className="text-red-500">question mark</span> button will display a demo game
              </Emphasis>
              , which allows you to show the game rules very easily to the students.
            </HowToLine>
            <HowToLine>
              <Emphasis>
                The blue <span className="text-blue-500">save button</span> will save the game
              </Emphasis>
              , and they cyan <span className="text-cyan-500">load button</span> will load it. Note: this saves the game to the computer, not to the account. Meaning that if you have the classes in multiple classrooms, you can have a
              separate save game on each computer, one for each class.
            </HowToLine>
          </HowToSection>
        </HowToContainer>
        <HowToContainer title="Space Fight">
          <HowToSection>
            <HowToLine>
              This is a more advanced version of{" "}
              <a className="text-blue-500 underline" href="/how-to#fighters">
                Fighters
              </a>
              . It is currently in beta, and will receive updates in the future.
            </HowToLine>
            <HowToLine>Just like in the How To for Fighters, the same secret buttons apply. </HowToLine>
            <HowToLine>
              <Emphasis>Double click the hidden button</Emphasis> to activate hardmode.{" "}
            </HowToLine>
            <HowToLine>Currently, there are no boss fights. They are planned in a future update. </HowToLine>
            <HowToLine>
              In this game, you can also{" "}
              <Emphasis>
                press "<span className="capitalize text-blue-500">fly up</span>" or "<span className="capitalize text-blue-500">fly down</span>"
              </Emphasis>
              , <Emphasis>attacks will only hit if you're in the same lane.</Emphasis>{" "}
            </HowToLine>
            <HowToLine>
              You can also hit "<span className="text-purple-500">Dance</span>" which will make your ship spin around. (The secret strategy is to shoot, the monster will dodge, and dancing will taunt the monster to come back to your lane).
            </HowToLine>
            <HowToLine>You can then hit the retry button or doubletap the secret button to spawn another monster.</HowToLine>
          </HowToSection>
        </HowToContainer>
      </div>
    </div>
  );
}
