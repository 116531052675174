import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { InputType } from "src/utils/InputTypes";
import { UserContext } from "src/utils/UserContext";
import BlackBgBackdrop from "../basic/BlackBgBackdrop";
import CurrencySelector from "../basic/CurrencySelector";
import { InputGroup } from "../basic/InputGroup";
import LicenseKeyInput from "./LicenseKeyInput";
import PaypalButton from "./PaypalButton";

/*//!IMPORTANT
 *   process.env.SHOW_ALIPAY is set to false because Stripe has taken 1 month to confirm alipay eligibility
 *   set it to true to show the alipay button
 */

interface couponValue {
  type: "dollar" | "percent";
  value: number;
  payment: "SUB" | "SINGLE" | "BOTH";
}

const PRO_BASE_PRICE = 599; //in cents
const PREMIUM_BASE_PRICE = 999;
const SINGLE_PURCHASE_FEE = 30;
const PRO_BASE_PRICE_CNY = 3900; //in cents
const PREMIUM_BASE_PRICE_CNY = 6600;
const SINGLE_PURCHASE_FEE_CNY = 200;
enum plans {
  PRO,
  PREMIUM,
  BASIC,
}

type VALID_DURATIONS = 30 | 90 | 180;
type VALID_FREE_DAYS = 0 | 7 | 30;
const FREE_DAYS: Record<VALID_DURATIONS, VALID_FREE_DAYS> = {
  30: 0,
  90: 7,
  180: 30,
};

type valid_currency = "USD" | "CNY";

const price_book: Record<plans, Record<string, Record<number, number>>> = {
  [plans.PREMIUM]: {
    USD: {
      30: 999,
      90: 2699,
      180: 4999,
    },
    CNY: {
      30: 6600,
      90: 18100,
      180: 33000,
    },
  },
  [plans.PRO]: {
    USD: {
      30: 599,
      90: 1649,
      180: 2999,
    },
    CNY: {
      30: 3900,
      90: 10600,
      180: 19500,
    },
  },
  [plans.BASIC]: {
    USD: {
      30: 0,
      90: 0,
      180: 0,
    },
    CNY: {
      30: 0,
      90: 0,
      180: 0,
    },
  },
};

export default function OrderBox() {
  const { state } = useContext(UserContext);
  const [selectedPlan, setSelectedPlan] = useState<plans>(plans.PRO);
  const [duration, setDuration] = useState<VALID_DURATIONS>(30);
  const [couponInput, setCouponInput] = useState({ ...({} as InputType), label: "Coupon Code", placeholder: " ", type: "text", value: "", errors: [] as string[], invalid: false, required: true });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [hasCoupon, setHasCoupon] = useState(false);
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [validCouponCode, setValidCouponCode] = useState("");
  const [finalErrors, setFinalErrors] = useState([] as string[]);
  const [oneTimePayment, setOneTimePayment] = useState(false);
  const [currency, setCurrency] = useState<valid_currency>("USD");
  const [couponCode, setCouponCode] = useState<couponValue>({
    type: "percent",
    value: 10,
    payment: "SUB",
  });
  const [planCode, setPlanCode] = useState("PROPLAN1");
  const [reloadingPaypal, setReloadingPaypal] = useState(false);

  const couponCodeConverter = (str: string, payment: "SUB" | "SINGLE" | "BOTH"): couponValue => {
    ///str will be MULT0.9 or SUB2
    // meaning     -10%    or  -$2
    let type = "percent" as "dollar" | "percent";
    let value = 0;
    if (str.startsWith("MULT")) {
      let mult = parseFloat(str.slice(4));
      value = Math.round((1 - mult) * 100); // no decimals
    } else if (str.startsWith("SUB")) {
      type = "dollar";
      value = parseFloat(str.slice(3));
    }
    return {
      type: type,
      value: value,
      payment: payment,
    };
  };

  useEffect(() => {
    setReloadingPaypal(true);
    setTimeout(() => {
      setReloadingPaypal(false);
    }, 100);
  }, [planCode, validCouponCode]);

  const currencySymbol = () => {
    switch (currency) {
      case "USD":
        return "$";
      case "CNY":
        return "¥";
      default:
        return "$";
    }
  };

  const closePopup = () => {
    setShowCouponPopup(false);
  };

  const getPaidDuration = (duration: VALID_DURATIONS) => {
    switch (duration) {
      case 30:
        return "1 Month";
      case 90:
        return "2 Months + 3 Weeks";
      case 180:
        return "5 Months";
    }
  };
  const getFreeDuration = (duration: VALID_DURATIONS) => {
    switch (duration) {
      case 30:
        return "";
      case 90:
        return "1 Week";
      case 180:
        return "1 Month";
    }
  };
  const getTotalDuration = (duration: VALID_DURATIONS) => {
    switch (duration) {
      case 30:
        return "1 Month";
      case 90:
        return "3 Months";
      case 180:
        return "6 Months";
    }
  };

  const getCouponText = (coupon: couponValue): string => {
    let str = "-";
    if (coupon.type === "dollar") {
      str += currencySymbol() + ((coupon.value | 0) === coupon.value ? coupon.value : (coupon.value | 0) + "" + (coupon.value - (coupon.value | 0)).toPrecision(2).slice(1));
    } else {
      str += coupon.value + "%";
    }

    return str;
  };
  const getCouponPaymentText = (coupon: couponValue): string => {
    if (!couponCodeValid()) {
      return "";
    }
    let str = "-";
    let value = coupon.value;
    if (coupon.type === "dollar") {
      str += currencySymbol() + convertCentsToString(value * 100);
    } else {
      str += coupon.value + "%";
    }

    return str;
  };

  const convertCentsToString = (num: number): string => {
    let newNum: number = (num / 100) as number;
    function getDecimal(dec: number) {
      let val = (dec - (dec | 0)).toPrecision(2).slice(1);
      if (val.length > 3) {
        return parseFloat(val).toPrecision(1).slice(1);
      }
      if (val.length === 2) {
        val += 0;
      }
      if (currency === "CNY" && val === ".00") {
        val = "";
      }
      return val;
    }
    return (newNum | 0) + getDecimal(newNum);
  };

  const getBasePrice = (plan: plans) => {
    if (plan === plans.BASIC) {
      return 0;
    }
    switch (currency) {
      case "USD":
        if (plan === plans.PREMIUM) {
          return PREMIUM_BASE_PRICE;
        } else {
          return PRO_BASE_PRICE;
        }
      case "CNY":
        if (plan === plans.PREMIUM) {
          return PREMIUM_BASE_PRICE_CNY;
        } else {
          return PRO_BASE_PRICE_CNY;
        }
    }
  };

  const getSinglePurchaseFee = () => {
    switch (currency) {
      case "USD":
        return SINGLE_PURCHASE_FEE;
      case "CNY":
        return SINGLE_PURCHASE_FEE_CNY;
    }
  };

  const getFullPrice = (plan: plans, duration: VALID_DURATIONS) => {
    return price_book[plan][currency][duration];
  };

  const getPrice = (applyCoupon = false) => {
    if (![30, 90, 180].includes(duration)) {
      setDuration(30);
    }
    function calcCoupon(num: number) {
      if (!applyCoupon || !couponCodeValid()) return num;
      if (couponCode.type === "dollar") {
        return num - couponCode.value * 100;
      } else {
        return (num * (100 - couponCode.value)) / 100;
      }
    }
    return calcCoupon(getFullPrice(selectedPlan, duration) + (oneTimePayment ? getSinglePurchaseFee() : 0));
  };

  const applyCoupon = () => {
    setCouponInput((prev) => ({ ...prev, errors: [], invalid: false }));
    if (couponInput.value === "") {
      setCouponInput((prev) => ({ ...prev, errors: ["Please enter a code."], invalid: true }));
      return;
    }
    if (!couponInput.value.match(/^\w+$/)) {
      setCouponInput((prev) => ({ ...prev, errors: ["This is an invalid code."], invalid: true }));
      return;
    }
    setButtonDisabled(true);
    axios.get("/api/users/get_csrf/").then(() => {
      axios
        .get("/api/payments/check-coupon/" + couponInput.value + "/")
        .then((res) => {
          setHasCoupon(true);
          setValidCouponCode(couponInput.value);
          let coupon = couponCodeConverter(res.data.value, res.data.payment);
          setCouponCode(coupon);
          setButtonDisabled(false);
          closePopup();
        })
        .catch((err) => {
          setButtonDisabled(false);
          if (err && err.response && err.response.data) {
            for (let key in err.response.data) {
              let errors: string[] = [];
              if (typeof err.response.data[key] === "string") {
                errors = [err.response.data[key]];
              } else {
                errors = err.response.data[key];
              }
              setCouponInput((prev) => ({ ...prev, errors: errors, invalid: true }));
            }
          }
        });
    });
  };

  const checkmark = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  );

  const cross = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5  text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  );

  const getPlanCode = (pln = plans.PRO, dur = 30, curr = "USD", otp: boolean | null = null) => {
    let plan = "PROPLAN";
    if (selectedPlan === plans.PREMIUM || pln === plans.PREMIUM) {
      plan = "PREMIUMPLAN";
    }
    let one_time = otp === null ? oneTimePayment : otp;
    if (one_time) {
      plan = "SINGLE_" + plan;
    }
    dur = dur || duration;
    switch (dur) {
      case 30:
        plan = plan + "1";
        break;
      case 90:
        plan = plan + "3";
        break;
      case 180:
        plan = plan + "6";
        break;
    }
    if (currency === "CNY" || curr === "CNY") {
      plan += "_CNY";
    }
    return plan;
  };

  const couponCodeValid = () => {
    return hasCoupon && (couponCode.payment === "BOTH" || (oneTimePayment && couponCode.payment === "SINGLE") || (!oneTimePayment && couponCode.payment === "SUB"));
  };

  const processPayment = (payment_option: "wechat" | "alipay" | "card") => {
    setFinalErrors([]);
    if (!state.isAuthenticated) return;
    setButtonDisabled(true);
    let data: {
      email: string;
      plan_selected: string;
      coupon_code?: string;
      payment: "SINGLE" | "SUB";
      provider: "STRIPE";
      payment_option: "wechat" | "alipay" | "card";
      currency: valid_currency;
    } = {
      email: state.email,
      plan_selected: planCode,
      payment: oneTimePayment ? "SINGLE" : "SUB",
      provider: "STRIPE",
      payment_option: payment_option,
      currency: currency,
    };
    if (hasCoupon) {
      data.coupon_code = validCouponCode;
    }
    axios.get("/api/users/get_csrf/").then(() => {
      axios
        .post("/api/payments/process-payment/", data)
        .then((res) => {
          window.location.replace(res.data);
        })
        .catch((err) => {
          setButtonDisabled(false);
          if (err && err.response && err.response.data) {
            for (let key in err.response.data) {
              let errors: string[] = [];
              if (typeof err.response.data[key] === "string") {
                errors = [err.response.data[key]];
              } else {
                errors = err.response.data[key];
              }
              setFinalErrors(errors);
            }
          }
        });
    });
  };

  return (
    <div className="shadow-sm shadow-primary-500 dark:shadow-primary-900 rounded-lg  flex flex-col  overflow-hidden  bg-white dark:bg-slate-700 ">
      <div className=" flex flex-col overflow-hidden">
        <div className="left-side flex-col gap-3 grow border-gray-400 dark:border-gray-600 border-b-[1px] lg:border-b-0 p-5">
          <h4 className="font-medium text-3xl text-center dark:text-white ">Purchase a Full Plan!</h4>
          <div className="flex items-center gap-4">
            <h4 className="font-medium text-lg text-primary-500 dark:text-primary-100">Choose a Plan</h4>
            <CurrencySelector
              onChange={(ev) => {
                setCurrency(ev.target.value as valid_currency);
              }}
              value={currency}
            ></CurrencySelector>
          </div>
          <div className="choice-box flex flex-col md:flex-row justify-center items-center md:items-stretch p-5 gap-5">
            <div
              onClick={() => {
                setSelectedPlan(plans.BASIC);
                setPlanCode(getPlanCode(plans.BASIC, duration, currency, oneTimePayment));
              }}
              className={
                " rounded border-2 relative bg-transparent flex-col w-80  lg:gap-10 flex justify-center items-center py-5 hover:bg-blue-400/10 duration-100 ease-in-out cursor-pointer  " +
                (selectedPlan === plans.BASIC ? "   text-primary-500 dark:text-primary-300 border-primary-500 dark:border-primary-300" : "border-gray-300 text-gray-300")
              }
            >
              <p className="font-bold text-xl lg:text-4xl lg:font-medium">BASIC</p>
              <h2 className="text-emerald-500 dark:text-emerald-200 text-[3rem] font-thin">FREE</h2>
              <p className="text-sm text-gray-400 dark:text-gray-200 text-center">forever</p>
              <ul
                className="marker:text-primary-900 w-auto flex flex-col gap-1
              text-md  text-primary-1200  dark:text-primary-200 list-inside"
              >
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} 1 device logged in at a time</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{cross} access to all released games</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{cross} access to beta games</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} create your own wordlists</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{cross} upload your own images</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} choose from uploaded images</li>
              </ul>
              {hasCoupon ? (
                <div className="rounded-full bg-amber-300 dark:bg-amber-600 absolute top-[-1rem] right-[-1rem] text-white px-2 py-3">
                  <p className="text-sm">{getCouponText(couponCode)}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              onClick={() => {
                setSelectedPlan(plans.PRO);
                setPlanCode(getPlanCode(plans.PRO, duration, currency, oneTimePayment));
              }}
              className={
                " rounded border-2 relative bg-transparent flex-col w-80  lg:gap-10 flex justify-center items-center py-5 hover:bg-blue-400/10 duration-100 ease-in-out cursor-pointer  " +
                (selectedPlan === plans.PRO ? "   text-blue-500 dark:text-blue-300 border-blue-500 dark:border-blue-300" : "border-gray-300 text-gray-300")
              }
            >
              <p className="font-bold text-xl lg:text-4xl lg:font-medium">PRO</p>
              <h2 className="text-emerald-500 dark:text-emerald-200 text-[3rem] font-thin">
                <span className="text-xl align-text-top mr-[-0.8rem]">{currencySymbol()}</span> {convertCentsToString(getBasePrice(plans.PRO))}
              </h2>
              <p className="text-sm text-gray-400 dark:text-gray-200 text-center">per month</p>
              <ul
                className="marker:text-primary-900 w-auto flex flex-col gap-1
              text-md  text-primary-1200  dark:text-primary-200 list-inside"
              >
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} 1 device logged in at a time</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} access to all released games</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{cross} access to beta games</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} create your own wordlists</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} upload your own images</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} upload 50 images per month</li>
              </ul>
              {hasCoupon ? (
                <div className="rounded-full bg-amber-300 dark:bg-amber-600 absolute top-[-1rem] right-[-1rem] text-white px-2 py-3">
                  <p className="text-sm">{getCouponText(couponCode)}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              onClick={() => {
                setSelectedPlan(plans.PREMIUM);
                setPlanCode(getPlanCode(plans.PREMIUM, duration, currency, oneTimePayment));
              }}
              className={
                " rounded border-2 relative bg-transparent flex-col w-80  lg:gap-10 flex justify-center items-center py-5 hover:bg-blue-400/10 duration-100 ease-in-out cursor-pointer  " +
                (selectedPlan === plans.PREMIUM ? "   text-emerald-500 dark:text-emerald-300 border-emerald-500 dark:border-emerald-300" : "border-gray-300 text-gray-300")
              }
            >
              <p className="font-bold text-xl lg:text-4xl lg:font-medium">PREMIUM</p>
              <h2 className="text-emerald-500 dark:text-emerald-200 text-[3rem] font-thin">
                <span className="text-xl align-text-top mr-[-0.8rem]">{currencySymbol()}</span> {convertCentsToString(getBasePrice(plans.PREMIUM))}
              </h2>
              <p className="text-sm text-gray-400 dark:text-gray-200 text-center">per month</p>
              <ul
                className="marker:text-primary-900 w-auto flex flex-col gap-1
              text-md  text-primary-1200  dark:text-primary-200 list-inside"
              >
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} 3 devices logged in at a time</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} access to all released games</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} access to beta games</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} create your own wordlists</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} upload your own images</li>
                <li className="flex items-center gap-3 md:text-sm xl:text-base md:gap-1 lg:gap-3">{checkmark} upload 100 images per month</li>
              </ul>
              {hasCoupon ? (
                <div className="rounded-full bg-amber-300 dark:bg-amber-600 absolute top-[-1rem] right-[-1rem] text-white px-2 py-3">
                  <p className="text-sm">{getCouponText(couponCode)}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="my-4 mb-6">
            <h5
              className="text-emerald-600 dark:text-emerald-100 underline text-md text-center hover:text-emerald-300 cursor-pointer duration-100 ease-in-out"
              onClick={() => {
                setShowCouponPopup(true);
              }}
            >
              Got a Coupon Code?
            </h5>
          </div>
          <h4 className="font-medium text-lg text-primary-500 dark:text-primary-100">Choose a Duration</h4>
          <div className="choice-box flex flex-wrap justify-center flex-row items-center p-5 gap-5">
            <div
              onClick={() => {
                setDuration(30);
                setPlanCode(getPlanCode(selectedPlan, 30, currency, oneTimePayment));
              }}
              className={
                "rounded border-2 bg-transparent select-none lg:gap-5 w-40 lg:w-60 h-[9rem] lg:h-40 flex flex-col justify-center items-center py-5 hover:bg-primary-400/10 duration-100 ease-in-out cursor-pointer  " +
                (duration === 30 ? " text-primary-700 border-primary-700 dark:text-sky-300 dark:border-sky-300" : "text-gray-300 border-gray-300")
              }
            >
              <p className="font-bold text-xl lg:text-3xl">1 Month</p>
            </div>
            <div
              onClick={() => {
                setDuration(90);
                setPlanCode(getPlanCode(selectedPlan, 90, currency, oneTimePayment));
              }}
              className={
                "rounded border-2 bg-transparent select-none lg:gap-5 w-40 lg:w-60 h-[9rem] lg:h-40 flex flex-col justify-center items-center py-5 hover:bg-primary-400/10 duration-100 ease-in-out cursor-pointer  " +
                (duration === 90 ? " text-primary-700 border-primary-700 dark:text-sky-300 dark:border-sky-300" : "text-gray-300 border-gray-300")
              }
            >
              <p className="font-bold text-xl lg:text-3xl">3 Months</p>
              <p className="font-bold text-xs">(1 free week included)</p>
            </div>
            <div
              onClick={() => {
                setDuration(180);
                setPlanCode(getPlanCode(selectedPlan, 180, currency, oneTimePayment));
              }}
              className={
                "rounded border-2 bg-transparent select-none lg:gap-5 w-40 lg:w-60 h-[9rem] lg:h-40 flex flex-col justify-center items-center py-5 hover:bg-primary-400/10 duration-100 ease-in-out cursor-pointer  " +
                (duration === 180 ? " text-primary-700 border-primary-700 dark:text-sky-300 dark:border-sky-300" : "text-gray-300 border-gray-300")
              }
            >
              <p className="font-bold text-xl lg:text-3xl">6 Months</p>
              <p className="font-bold text-xs">(1 free month included)</p>
            </div>
            <div className="flex flex-col items-stretch w-40 lg:w-60 gap-2 lg:h-40">
              <div
                onClick={() => {
                  setOneTimePayment(true);
                  setPlanCode(getPlanCode(selectedPlan, duration, currency, true));
                }}
                className={
                  "rounded border-2 bg-transparent select-none basis-0 flex-1 flex flex-col justify-center items-center py-5 hover:bg-teal-400/10 duration-100 ease-in-out cursor-pointer whitespace-nowrap  " +
                  (oneTimePayment ? " text-teal-700 border-teal-700 dark:text-teal-300 dark:border-teal-300" : "text-gray-300 border-gray-300")
                }
              >
                <p className="font-bold text-base lg:text-xl">One Time Payment</p>
              </div>
              <div
                onClick={() => {
                  setOneTimePayment(false);
                  setPlanCode(getPlanCode(selectedPlan, duration, currency, false));
                }}
                className={
                  "rounded border-2 bg-transparent select-none basis-0 flex-1 flex flex-col justify-center items-center py-5 hover:bg-teal-400/10 duration-100 ease-in-out cursor-pointer  " +
                  (!oneTimePayment ? " text-teal-700 border-teal-700 dark:text-teal-300 dark:border-teal-300" : "text-gray-300 border-gray-300")
                }
              >
                <p className="font-bold text-xl lg:text-xl">Subscription</p>
              </div>
            </div>
          </div>
          <h4 className="font-medium text-2xl text-primary-800 dark:text-white">Order Summary</h4>
          <div className="namebox flex flex-col gap-2 m-3 border-primary-200 border-t-2 p-2">
            {state.isAuthenticated ? (
              <>
                <h5 className="text-md dark:text-white">
                  Username: <span className="text-blue-600 dark:text-blue-300">{state.username}</span>
                </h5>
                <h5 className="text-md dark:text-white">
                  Email: <span className="text-blue-600 dark:text-blue-300">{state.email}</span>
                </h5>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="namebox flex flex-col gap-2 m-3 border-primary-200 border-y-2 border-dashed p-2">
            <div className="flex justify-between">
              <h5 className="text-md dark:text-white">
                Plan: <span className="text-emerald-500 dark:text-emerald-200">{selectedPlan === plans.PRO ? "Pro" : selectedPlan === plans.PREMIUM ? "Premium" : "Basic"}</span>
              </h5>
              <h5 className="text-md text-emerald-500 dark:text-emerald-200 font-bold">
                {currencySymbol()} {convertCentsToString(getBasePrice(selectedPlan))}
                /mo
              </h5>
            </div>
            {oneTimePayment ? (
              <div className="flex justify-between">
                <h5 className="text-md dark:text-white">Single Purchase Fee:</h5>
                <h5 className="text-md text-emerald-500 dark:text-emerald-200 font-bold">
                  {currencySymbol()}
                  {convertCentsToString(getSinglePurchaseFee())}
                </h5>
              </div>
            ) : (
              <></>
            )}
            <h5 className="text-md dark:text-white">
              Duration: <span className="text-emerald-500 dark:text-emerald-200">{getPaidDuration(duration)}</span>
            </h5>

            {FREE_DAYS[duration] ? (
              <div className="flex justify-between">
                <h5 className="text-md dark:text-white">
                  Extra: <span className="text-emerald-500 dark:text-emerald-200">{getFreeDuration(duration)}</span>
                </h5>
                <h5 className="text-md text-emerald-500 dark:text-emerald-200 font-bold">FREE</h5>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="namebox flex flex-col gap-2 m-3 border-primary-200 border-b-2 border-solid p-2">
            {hasCoupon ? (
              <div className="flex justify-between">
                <h5 className="text-md dark:text-white">
                  Coupon Code:<span className={couponCodeValid() ? "text-amber-500" : "text-red-500 line-through"}> {validCouponCode}</span>
                  {couponCodeValid() ? <></> : <span className="text-sm ml-2 text-red-500">This coupon can only be used for {couponCode.payment === "SUB" ? "subscription payments" : "a one time payment"}.</span>}
                </h5>
                <h5 className="text-md text-green-500 font-bold">{getCouponPaymentText(couponCode)}</h5>
              </div>
            ) : (
              <></>
            )}
            <div className="flex justify-between">
              <h5 className="text-md dark:text-white">
                TOTAL: <span className="text-emerald-500 dark:text-emerald-200">{getTotalDuration(duration)}</span>{" "}
              </h5>
              <span>
                <h5 className={"text-md  font-bold " + (hasCoupon && couponCodeValid() ? " text-gray-400 line-through" : "text-black  dark:text-white")}>
                  {currencySymbol()} {convertCentsToString(getPrice())}
                </h5>
                {hasCoupon && couponCodeValid() ? (
                  <h5 className="text-md  font-bold text-black  dark:text-white">
                    {currencySymbol()} {convertCentsToString(getPrice(true))}
                  </h5>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </div>
          {/*
                if process.env.REACT_APP_COMING_SOON -> display "purchases are coming soon" (logging in overrides it)
                if process.env.REACT_APP_PAYMENTS_DISABLED -> display "sorry"
                if plan === basic -> display "log in"
                if plan === paid plan, but not logged in -> display "log in"
                display purchase buttons
              
              */}

          {process.env.REACT_APP_COMING_SOON === "true" && !state.isAuthenticated ? (
            <>
              <h5 className="text-md font-bold dark:text-white mt-2">Purchases are coming soon!</h5>
            </>
          ) : (
            <>
              {process.env.REACT_APP_PAYMENTS_DISABLED === "true" ? (
                <>
                  <h5 className="text-md font-bold text-center text-red-700 dark:text-red-300 mt-2">We are currently undergoing maintenance and purchases are disabled. Sorry for the inconvenience! Check back later!</h5>
                </>
              ) : (
                <>
                  {selectedPlan === plans.BASIC ? (
                    <>
                      <h5 className="text-md font-bold dark:text-white mt-2 text-center">
                        You don't need to purchase anything for the basic plan. Just{" "}
                        <a href="/login" className="underline text-teal-700 dark:text-teal-300">
                          Log In
                        </a>{" "}
                        or{" "}
                        <a href="/signup" className="underline text-teal-700 dark:text-teal-300">
                          Create an Account
                        </a>{" "}
                        !
                      </h5>
                    </>
                  ) : (
                    <>
                      {state.isAuthenticated ? (
                        <>
                          <div className="flex w-full flex-col lg:flex-row justify-end items-end lg:items-center mb-3">
                            {reloadingPaypal || currency === "CNY" ? (
                              <button disabled={true} className="submit-btn min-w-[200px] px-3 py-2 m-2 bg-emerald-600 disabled:bg-gray-500 text-white disabled:text-gray-200 rounded-md hover:bg-emerald-500 duration-100 ease-in-out">
                                Pay With Paypal
                              </button>
                            ) : (
                              <PaypalButton
                                product={planCode}
                                oneTimePayment={oneTimePayment}
                                coupon={couponCodeValid() ? validCouponCode : undefined}
                                onError={(err: string[]) => {
                                  setFinalErrors(err);
                                }}
                              ></PaypalButton>
                            )}
                            <button
                              onClick={() => {
                                processPayment("wechat");
                              }}
                              disabled={buttonDisabled || currency !== "CNY" || !oneTimePayment}
                              className="submit-btn min-w-[200px] px-3 py-2 m-2 bg-emerald-400 text-emerald-900 hover:bg-emerald-300 dark:bg-emerald-600 disabled:bg-gray-300 dark:disabled:bg-gray-500 dark:text-white disabled:text-white dark:disabled:text-gray-200 rounded-md dark:hover:bg-emerald-500 duration-100 ease-in-out"
                            >
                              Checkout By WeChat
                            </button>
                            {process.env.SHOW_ALIPAY === "true" ? (
                              <button
                                onClick={() => {
                                  processPayment("alipay");
                                }}
                                disabled={buttonDisabled || currency !== "CNY" || !oneTimePayment}
                                className="submit-btn min-w-[200px] px-3 py-2 m-2 bg-blue-600 disabled:bg-gray-500 text-white disabled:text-gray-200 rounded-md hover:bg-blue-500 duration-100 ease-in-out"
                              >
                                Checkout By AliPay
                              </button>
                            ) : (
                              <></>
                            )}
                            <button
                              onClick={() => {
                                processPayment("card");
                              }}
                              disabled={buttonDisabled}
                              className="submit-btn min-w-[200px] px-3 py-2 m-2 bg-yellow-400 hover:bg-yellow-200 text-yellow-900 dark:bg-yellow-600 disabled:bg-gray-500 dark:text-white disabled:text-gray-200 rounded-md dark:hover:bg-yellow-500 duration-100 ease-in-out"
                            >
                              Checkout By Card
                            </button>
                            <div className="flex justify-center items-center">
                              <CurrencySelector
                                onChange={(ev) => {
                                  setCurrency(ev.target.value as valid_currency);
                                }}
                                value={currency}
                              ></CurrencySelector>
                            </div>
                          </div>
                          {currency === "CNY" ? <p className="text-sm text-red-500 text-right">Paypal does not allow RMB pricing. You can still use a Chinese paypal account with USD.</p> : <></>}
                          {couponCodeValid() && !oneTimePayment ? (
                            <p className="text-sm text-red-500 text-right">Unfortunately, PayPal does not accept our coupon codes for subscriptions. One time payments and the other checkout options will accept it.</p>
                          ) : (
                            <></>
                          )}
                          {currency !== "CNY" || !oneTimePayment ? (
                            <p className="text-sm text-red-500 text-right">
                              {process.env.SHOW_ALIPAY === "true"
                                ? " WeChat and AliPay currently only support one time payments of RMB. We apologize for the inconvenience. "
                                : "WeChat only supports one time payments of RMB. We apologize for the inconvenience."}{" "}
                              <span
                                onClick={() => {
                                  setOneTimePayment(true);
                                  setCurrency("CNY");
                                  setPlanCode(getPlanCode(selectedPlan, duration, "CNY", true));
                                }}
                                className="text-blue-500 underline cursor-pointer"
                              >
                                Click to switch to One Time Payment
                              </span>
                            </p>
                          ) : (
                            <></>
                          )}
                          {state.current_plan_type && !oneTimePayment ? (
                            <p className="text-sm text-amber-600 dark:text-amber-400 text-right">
                              {/*//TODO make the bottom tertiary better*/}
                              You currently have a <span className=" capitalize">{state.current_plan_type.toLowerCase() === "pre" ? "Premium" : "Pro"}</span> plan. By purchasing a new subscription, you will pause your current plan and it
                              will automatically resume when this new plan expires. You can extend a plan without a subscription by making a one-time payment.
                            </p>
                          ) : (
                            <></>
                          )}
                          {state.current_plan_type && oneTimePayment ? (
                            <p className="text-sm text-teal-500 text-right">
                              {/*//TODO make the bottom tertiary better*/}
                              You currently have a <span className=" capitalize">{state.current_plan_type.toLowerCase() === "pre" ? "Premium" : "Pro"}</span> plan. By purchasing the same plan, you will extend the duration of your current
                              plan by the purchased amount.{" "}
                            </p>
                          ) : (
                            <></>
                          )}
                          <p className="text-sm text-emerald-500 dark:text-emerald-300 text-right">
                            Your plan will activate automatically once the purchase is successful. For any questions or concerns, you can email us at{" "}
                            <a className="text-emerald-700 dark:text-emerald-200 underline cursor-pointer" href="mailto:billing@whatstheword.io">
                              billing@whatstheword.io
                            </a>
                            .
                          </p>
                        </>
                      ) : (
                        <div className="flex p-5 justify-center">
                          <>
                            <h5 className="text-md font-bold dark:text-white mt-2">
                              Please{" "}
                              <a href="/login" className="underline text-teal-700 dark:text-teal-300">
                                Log In
                              </a>{" "}
                              or{" "}
                              <a href="/signup" className="underline text-teal-700 dark:text-teal-300">
                                Create an Account
                              </a>{" "}
                              before purchasing a plan{" "}
                            </h5>
                          </>
                        </div>
                      )}
                      {finalErrors.length ? (
                        <div className="errors text-red-500 dark:text-red-300 w-full flex  flex-col">
                          {finalErrors.map((err, i) => {
                            return (
                              <p key={i} className="font-thin before:content-['_•_'] px-2 w-fit">
                                {err.trim() === "" ? "Something went wrong. Please try again later." : err}
                              </p>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="license-code-screen p-5 border-t-[1px] border-gray-400 dark:border-gray-600 flex flex-col gap-2">
        <h4 className="font-medium text-xl text-primary-800 dark:text-white">Already have a license key?</h4>
        <p className="text-sm text-gray-400 dark:text-gray-200 ml-4">Type it or copy paste it here:</p>
        <div className="ml-4">
          <LicenseKeyInput></LicenseKeyInput>
        </div>
      </div>

      {showCouponPopup ? (
        <BlackBgBackdrop
          onClose={() => {
            closePopup();
          }}
        >
          <div>
            <form
              onSubmit={(ev) => {
                applyCoupon();
                ev.preventDefault();
              }}
              id="couponform"
              className="flex flex-col justify-around min-w-[300px] items-center gap-5 bg-white dark:bg-slate-900 p-5 rounded-lg"
            >
              <h4 className="text-center title text-xl font-bold text-primary-900 dark:text-white">Got a Coupon?</h4>
              <InputGroup
                {...couponInput}
                value={couponInput.value}
                onChange={(ev) => {
                  setCouponInput((prev) => ({ ...prev, value: ev.target.value }));
                }}
              ></InputGroup>
              <button
                type="submit"
                form="couponform"
                disabled={buttonDisabled}
                className="text-white font-thin focus:ring-1  rounded-md text-md  w-full sm:w-auto px-3 py-1 text-center self-center justify-self-center disabled:bg-gray-500 disabled:text-gray-200 disabled:pointer-events-none bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 focus:outline-none"
              >
                Apply Coupon
              </button>
            </form>
          </div>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
    </div>
  );
}
