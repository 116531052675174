import React, { useState, useEffect } from "react";
import { useSprings, animated, config } from "react-spring";

const paths = [
  "m 301.91986,53.587845 c 0,0 -174.48255,8.638437 -179.40137,189.296455 2.54056,186.18359 184.90686,197.19389 184.90686,197.19389",
  "m 301.91986,53.587845 c 0,0 -122.11576,24.29747 -123.21685,196.070965 -1.10112,171.77353 128.72234,190.41938 128.72234,190.41938",
  "m 301.91986,53.587845 c 0,0 -53.60992,22.417374 -54.711,194.190845 -1.10113,171.77343 60.21649,192.2995 60.21649,192.2995",
  "m 301.91986,53.587845 c 0,0 3.30332,16.516708 2.20218,188.290115 -1.10104,171.77353 3.30331,198.20023 3.30331,198.20023",
  "m 301.91986,53.587845 c 0,0 59.09863,23.140213 57.9975,194.913715 -1.10114,171.77347 -52.492,191.57663 -52.492,191.57663",
  "m 301.91986,53.587845 c 0,0 134.75362,24.049778 133.65249,195.823285 -1.10114,171.77346 -128.147,190.66706 -128.147,190.66706",
  "m 306.04307,52.615922 c 0,0 193.24571,7.281917 192.02136,198.278258 -1.15945,180.87138 -187.68408,189.47713 -187.68408,189.47713",
];

export default function Globe() {
  const pathAnimations = useSprings(paths.length, (index) => {
    return {
      from: { path: paths[index % paths.length] },
      to: { path: paths[(index + 1) % paths.length] },
      loop: true,
      config: {
        clamp: true,
        duration: 10000,
      },
    };
  });
  const opacityAnimations = useSprings(
    paths.length,

    (index) => {
      return {
        from: { opacity: index % paths.length === 0 ? "0" : "1" },
        to: { opacity: (index + 1) % paths.length === 0 ? "0" : "1" },
        config: { tension: 0, friction: 0.9 },
        loop: true,
      };
    }
  );
  return (
    <svg viewBox="-50 -25 410 480" version="1.1" id="svg54" xmlns="http://www.w3.org/2000/svg">
      <defs id="defs58" />
      <g id="g52" transform="matrix(1.0122354,-0.29492535,0.30952829,0.96448011,-293.01818,30.167934)">
        <g transform="rotate(45,310.17816,246.28247)" id="g1006">
          <animated.path d="m 499.01886,246.28247 a 188.84071,193.7957 0 1 0 -377.68141,0 188.84071,193.7957 0 1 0 377.68141,0 z" id="left0" strokeWidth="4" strokeLinecap={"round"} stroke="currentColor" fillRule="evenodd" fill="none" />

          {paths.map((style, i) => {
            return <animated.path key={i} d={pathAnimations[0][i].path} opacity={opacityAnimations[0][i].opacity} id="left1" strokeWidth="4" strokeLinecap={"round"} stroke="currentColor" fillRule="evenodd" fill="none" />;
          })}
          <path
            d="m 301.91986,53.587845 c 0,0 -174.48255,8.638437 -179.40137,189.296455 2.54056,186.18359 184.90686,197.19389 184.90686,197.19389"
            id="path7108"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 306.04307,52.615922 c 0,0 193.24571,7.281917 192.02136,198.278258 -1.15945,180.87138 -187.68408,189.47713 -187.68408,189.47713"
            id="path7108"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 172.04114,114.54266 c 0,0 48.07841,-46.606139 130.40868,-46.606139 82.33015,0 143.2969,40.264789 141.09476,41.365949"
            id="path7108"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 143.3751,155.50608 c 0,0 24.27992,-39.73962 162.48645,-41.95608 147.25153,-2.36152 177.5147,54.0297 177.5147,54.0297"
            id="path7109"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 121.49655,238.76072 c 0,0 25.88275,15.33299 183.86408,15.33299 157.98117,0 192.29652,-5.2965 192.29652,-5.2965"
            id="path7110"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 127.86595,200.34618 c 0,0 23.14539,-19.00493 181.12672,-19.00493 157.98117,0 185.11554,26.81322 185.11554,26.81322"
            id="path7110-3"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 123.59848,265.0014 c 0,0 22.97523,46.24665 180.48898,46.24665 157.51361,0 191.54503,-27.93231 191.54503,-27.93231"
            id="path7111"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 134.4984,313.45029 c 0,0 22.30659,42.94339 170.12504,42.94339 147.81833,0 177.67336,-27.13928 177.67336,-27.13928"
            id="path7112"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 158.4877,360.7981 c 0,0 18.73569,37.4378 146.70822,37.4378 127.97241,0 155.5716,-35.23553 155.5716,-35.23553"
            id="path7113"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
          <path
            d="m 193.36554,396.03363 c 0,0 15.5979,35.23566 114.03879,35.23566 98.44092,0 118.48541,-33.03339 118.48541,-33.03339"
            id="path7114"
            strokeWidth="4"
            strokeLinecap={"round"}
            stroke="currentColor"
            fillRule="evenodd"
            fillOpacity="0.75"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
}
