import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Astronaut } from "../../assets/images/astronaut.svg";

export default function PageNotFound() {
  useEffect(() => {
    document.title = "Page Not Found | whatstheword.io";
  }, []);

  return (
    <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900 flex flex-col justify-center items-center gap-10">
      <h2 className="title font-bold text-3xl text-primary-500 dark:text-white">Sorry, theres nothing here!</h2>
      <Astronaut className="max-w-[80%] md:max-w-[500px]" />

      <Link to="/">
        <div className="bg-primary-500 dark:bg-primary-1000 hover:bg-primary-400 dark:hover:bg-primary-800 duration-100 ease-in-out rounded-xl px-5 py-3 text-white">GO HOME</div>
      </Link>
    </div>
  );
}
