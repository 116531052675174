import React, { MouseEventHandler } from "react";
import { useHover } from "src/utils/functions";

interface props {
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  text?: string;
}

export default function AddImageButton(props: props) {
  const [imageHovered, imageHoverProps] = useHover();
  return (
    <div
      {...imageHoverProps}
      onClick={props.onClick}
      className={
        "border-[1px] border-primary-800 peer flex justify-center overflow-hidden m-0 items-center rounded-md hover:bg-primary-500/50 dark:hover:bg-primary-800/50 duration-100 ease-in-out cursor-pointer relative " + props.className
      }
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-primary-800 stroke-[1px]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <p className={"bg-primary-400 text-primary-1000 dark:bg-primary-1100 dark:text-primary-500 font-thin bottom-0 right-0 p-1 absolute rounded capitalize peer text-[0.6rem] " + (imageHovered ? "" : "hidden")}>
        {props.text || "Add New Power"}
      </p>
    </div>
  );
}
