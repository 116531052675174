import { ChangeEvent, useState } from "react";
import { InputType } from "src/utils/InputTypes";
import { InputGroup } from "../basic/InputGroup";
import { PUNCTUATION, wordlistToString } from "../wordlists/WordListChooser";

interface props {
  value: string[];
  onChange: Function;
}

export default function TagInput(props: props) {
  const [newListState, setNewListState] = useState<InputType>({ ...({} as InputType), errors: [] as string[], invalid: false, value: wordlistToString(props.value) });
  const [tagList, setTagList] = useState(props.value);

  const handleTagType = (ev: ChangeEvent<HTMLInputElement>) => {
    setNewListState((prevState) => ({ ...prevState, value: ev.target.value, errors: [], invalid: false }));
    let currentWord = "";
    let allWords: string[] = [];
    let waitingForClose = "";
    for (let letter of ev.target.value.split("")) {
      if (PUNCTUATION.includes(letter) && waitingForClose === "") {
        if (currentWord !== "") {
          allWords.push(currentWord);
          allWords = Array.from(new Set(allWords));
          currentWord = "";
        } else {
          continue;
        }
      } else if (['"', "'"].includes(letter) && waitingForClose === "") {
        waitingForClose = letter;
      } else if (waitingForClose !== "") {
        if (letter === waitingForClose) {
          allWords.push(currentWord);
          allWords = Array.from(new Set(allWords)).map((x) => x.toLowerCase());
          currentWord = "";
          waitingForClose = "";
        } else {
          currentWord += letter;
        }
      } else {
        currentWord += letter;
      }
    }
    if (currentWord !== "") {
      allWords.push(currentWord);
      allWords = Array.from(new Set(allWords)).map((x) => x.toLowerCase());
      currentWord = "";
    }
    setTagList(allWords);
    props.onChange(allWords);
  };
  return (
    <div>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
        className="w-full flex  mb-2 rounded-md"
      >
        <InputGroup
          name="tags"
          value={newListState.value}
          placeholder=" "
          errors={newListState.errors}
          label="Add some tags"
          required={false}
          type="text"
          invalid={newListState.invalid}
          onChange={(ev) => {
            setNewListState((prevState) => ({ ...prevState, errors: [], invalid: false }));
            handleTagType(ev);
          }}
          submitted={newListState.submitted}
        />
      </form>
      <div className="p-1 flex flex-wrap gap-1 w-full rounded-md items-center">
        <p className="text-primary-900 dark:text-primary-100 font-bolder leading-tight">Tags: </p>
        {tagList.map((tag) => {
          return (
            <span key={tag} className="text-primary-1200 dark:text-white lowercase rounded-full bg-primary-200 dark:bg-primary-1200 px-1 py-0.5">
              {tag}
            </span>
          );
        })}
      </div>
    </div>
  );
}
