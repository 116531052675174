import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthTypes, LicenseType, UserContext } from "src/utils/UserContext";
import LicenseBadge, { LicenseObject } from "../auth/LicenseBadge";
import Footer from "../home/Footer";
import InfoBox from "./InfoBox";

export default function Profile() {
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [licenses, setLicenses] = useState<LicenseObject[]>([]);

  const getLicenseTypeFromString = (str: string) => {
    switch (str) {
      case "Premium":
        return LicenseType.PREMIUM;
      case "Pro":
        return LicenseType.PRO;
      case "VIP":
        return LicenseType.VIP;
      default:
        return LicenseType.BASIC;
    }
  };

  useEffect(() => {
    document.title = "Profile | whatstheword.io";
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
          navigate("/login");
          return;
        });
    } else {
      axios.get("/api/licenses/").then((res) => {
        setLicenses(res.data.results);
      });
    }
  }, [state.isAuthenticated, dispatch, navigate]);

  return (
    <>
      <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900 flex flex-col">
        <div className="h-20 flex relative justify-center items-center bg-slate-200 dark:bg-slate-700">
          <h3 className="text-lg text-primary-500 dark:text-primary-100 font-bold"> Hello, {state.display_name || state.username}! Edit your info here!</h3>
        </div>

        <div className="p-2 h-full flex-1">
          <div className="bg-white dark:bg-slate-800 w-full flex flex-col md:flex-row justify-around place-content-around gap-5 items-center p-5 shadow-sm shadow-primary-500 dark:shadow-primary-900 rounded-md">
            <InfoBox></InfoBox>
            {state.has_active_plan && licenses.length !== 0 ? (
              <>
                <div className="flex flex-col gap-5">
                  <h3 className="font-thin text-lg text-primary-900 dark:text-white">Click on a plan to see more details or cancel the billing</h3>
                  <div className="flex gap-3 flex-col items-center md:flex-row">
                    <LicenseBadge
                      isSmall={false}
                      key={"main"}
                      username={state.display_name || licenses.filter((x) => x.is_active)[0]?.user_username}
                      type={getLicenseTypeFromString(licenses.filter((x) => x.is_active)[0]?.license_type)}
                      full_info={licenses.filter((x) => x.is_active)[0]}
                    ></LicenseBadge>
                    <div className="flex flex-wrap items-center justify-center">
                      {licenses
                        .filter((x) => !x.is_active)
                        .map((license) => {
                          return <LicenseBadge isSmall={true} key={license.id} username={state.display_name || license.user_username} type={getLicenseTypeFromString(license.license_type)} full_info={license}></LicenseBadge>;
                        })}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <LicenseBadge username={state.display_name || state.username} type={state.current_plan_type}></LicenseBadge>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
