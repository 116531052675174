import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import BlogEntry, { BlogEntryInterface } from "./BlogEntry";

export default function News() {
  const [blogEntries, setBlogEntries] = useState<BlogEntryInterface[]>([] as BlogEntryInterface[]);
  const { state, dispatch } = useContext(UserContext);
  useEffect(() => {
    document.title = "News | whatstheword.io";
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
        });
    }
    axios.get("/api/news/").then((res) => {
      setBlogEntries(
        res.data.results.map((entry: BlogEntryInterface) => {
          if (state.voted_posts && state.voted_posts[entry.id.toString()]) {
            if (state.voted_posts[entry.id.toString()].vote) {
              entry.isLiked = true;
            } else {
              entry.isDisliked = true;
            }
          }
          return entry;
        })
      );
    });
  }, [state.isAuthenticated, dispatch, state.voted_posts]);

  const sendDeleteVote = (id: number) => {
    axios.get("/api/users/get_csrf/").then(() => {
      axios.post("/api/news/delete-vote/", {
        id: id,
      });
    });
  };
  const sendVote = (id: number, vote: boolean) => {
    axios.get("/api/users/get_csrf/").then(() => {
      axios.post("/api/news/vote/", {
        id: id,
        vote: vote,
      });
    });
  };

  const handleLike = (entry: BlogEntryInterface) => {
    if (!state.isAuthenticated) return;
    if (!state.voted_posts) {
      state.voted_posts = {};
    }
    if (state.voted_posts[entry.id.toString()] && state.voted_posts[entry.id.toString()].vote) {
      sendDeleteVote(entry.id);
      let newEntries = blogEntries.map((x) => {
        if (x === entry) {
          x.likes = Math.max(x.likes - 1, 0);
          x.isLiked = false;
          x.isDisliked = false;
        }
        return x;
      });
      let new_voted_posts = state.voted_posts;
      delete new_voted_posts[entry.id.toString()];
      dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, voted_posts: new_voted_posts } });
      setBlogEntries(newEntries);
    } else {
      sendVote(entry.id, true);
      let newEntries = blogEntries.map((x) => {
        if (x === entry) {
          x.likes += 1;
          x.isLiked = true;
          if (x.isDisliked) {
            x.isDisliked = false;
            x.dislikes = Math.max(x.dislikes - 1, 0);
          }
        }
        return x;
      });
      let new_voted_posts = state.voted_posts;
      new_voted_posts[entry.id.toString()] = {
        vote: true,
      };
      dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, voted_posts: new_voted_posts } });
      setBlogEntries(newEntries);
    }
  };
  const handleDislike = (entry: BlogEntryInterface) => {
    if (!state.isAuthenticated) return;
    if (!state.voted_posts) {
      state.voted_posts = {};
    }
    if (state.voted_posts[entry.id.toString()] && state.voted_posts[entry.id.toString()].vote === false) {
      sendDeleteVote(entry.id);
      let newEntries = blogEntries.map((x) => {
        if (x === entry) {
          x.dislikes = Math.max(x.dislikes - 1, 0);
          x.isLiked = false;
          x.isDisliked = false;
        }
        return x;
      });
      let new_voted_posts = state.voted_posts;
      delete new_voted_posts[entry.id.toString()];
      dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, voted_posts: new_voted_posts } });
      setBlogEntries(newEntries);
    } else {
      sendVote(entry.id, false);
      let newEntries = blogEntries.map((x) => {
        if (x === entry) {
          x.dislikes += 1;
          x.isDisliked = true;
          if (x.isLiked) {
            x.isLiked = false;
            x.likes = Math.max(x.likes - 1, 0);
          }
        }
        return x;
      });
      let new_voted_posts = state.voted_posts;
      new_voted_posts[entry.id.toString()] = {
        vote: false,
      };
      dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, voted_posts: new_voted_posts } });
      setBlogEntries(newEntries);
    }
  };

  return (
    <div className="w-full min-h-full flex flex-col dark:bg-slate-900 p-2 gap-2">
      {blogEntries.map((entry) => {
        return (
          <BlogEntry
            key={entry.id}
            data={entry}
            allowLikes={state.isAuthenticated}
            isLiked={entry.isLiked}
            isDisliked={entry.isDisliked}
            onLike={() => {
              handleLike(entry);
            }}
            onDislike={() => {
              handleDislike(entry);
            }}
          ></BlogEntry>
        );
      })}
    </div>
  );
}
