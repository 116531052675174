import axios from "axios";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import BlackBgBackdrop from "../basic/BlackBgBackdrop";
import { InputGroup } from "../basic/InputGroup";
import { ListExtras, ListInput, PUNCTUATION, wordlistToString } from "./WordListChooser";
import { WordListInterface } from "./WordList";

interface props {
  list: WordListInterface;
  onClose: Function;
}

export default function WordListEditor(props: props) {
  const { state, dispatch } = useContext(UserContext);
  const initalListState = { listAsString: "", list: [], errors: [], submitted: false, invalid: false };
  const [newListState, setNewListState] = useState<ListInput>(initalListState);
  const [newListExtras, setNewListExtras] = useState<ListExtras>({ ...({} as ListExtras), theme: "", is_public: false, errors: [], submitted: false, invalid: false });
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    setNewListState((prev) => ({ ...prev, list: props.list.words, invalid: false, errors: [], listAsString: wordlistToString(props.list.words) }));
    setNewListExtras((prev) => ({ ...prev, theme: props.list.theme, errors: [], invalid: false }));
  }, [props.list]);

  const handleNewWordListType = (ev: ChangeEvent<HTMLInputElement>) => {
    setNewListState((prevState) => ({ ...prevState, listAsString: ev.target.value, errors: [], invalid: false }));
    let currentWord = "";
    let allWords: string[] = [];
    let waitingForClose = "";
    for (let letter of ev.target.value.split("")) {
      if (PUNCTUATION.includes(letter) && waitingForClose === "") {
        if (currentWord !== "") {
          allWords.push(currentWord);
          allWords = Array.from(new Set(allWords));
          currentWord = "";
        } else {
          continue;
        }
      } else if (['"', "'"].includes(letter) && waitingForClose === "") {
        waitingForClose = letter;
      } else if (waitingForClose !== "") {
        if (letter === waitingForClose) {
          allWords.push(currentWord);
          allWords = Array.from(new Set(allWords)).map((x) => x.toLowerCase());
          currentWord = "";
          waitingForClose = "";
        } else {
          currentWord += letter;
        }
      } else {
        currentWord += letter;
      }
    }
    if (currentWord !== "") {
      allWords.push(currentWord);
      allWords = Array.from(new Set(allWords)).map((x) => x.toLowerCase());
      currentWord = "";
    }
    setNewListState((prevState) => ({ ...prevState, list: allWords }));
  };

  const handleSubmitEdit = () => {
    setSubmitDisabled(true);
    if (newListExtras.theme === "") {
      setNewListExtras((prev) => ({ ...prev, errors: ["Please add a name or a theme."], invalid: true }));
    }
    if (newListState.list.length === 0) {
      setNewListState((prevState) => ({ ...prevState, errors: ["Enter at least one word."], invalid: true }));
    }
    if (newListExtras.theme === props.list.theme && newListState.listAsString === wordlistToString(props.list.words)) {
      setNewListState((prevState) => ({ ...prevState, errors: ["You haven't changed anything."], invalid: true }));
    }
    axios
      .patch(`/api/wordlists/${props.list.id}/`, { words: newListState.list, theme: newListExtras.theme })
      .then((res) => {
        let newList = state.favourite_wordlists.map((x: WordListInterface) => {
          if (x.id === props.list.id) {
            x.words = newListState.list.map((x) => x.toLowerCase());
            x.theme = newListExtras.theme;
          }
          return x;
        });
        dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, favourite_wordlists: newList } });
        props.onClose();
      })
      .catch((err) => {
        setSubmitDisabled(false);
        for (let key in err.response.data) {
          let errors: string[] = [];
          if (typeof err.response.data[key] === "string") {
            errors = [err.response.data[key]];
          } else if (typeof err.response.data[key] === "object" && !(err.response.data[key] instanceof Array)) {
            //words has a special case, will tell which one is wrong
            //should add this later
            errors = err.response.data[key][Object.keys(err.response.data[key])[0]];
          } else {
            errors = err.response.data[key];
          }
          switch (key) {
            case "words":
              setNewListState({ ...newListState, errors: errors });
              break;
            case "theme":
              setNewListExtras({ ...newListExtras, errors: errors });
              break;
            default:
              setNewListState({ ...newListState, errors: errors });
          }
        }
      });
  };
  return (
    <BlackBgBackdrop onClose={() => [props.onClose()]}>
      <div className={"w-full flex flex-col justify-center items-center p-2 bg-white dark:bg-slate-900 rounded-md m-2 max-w-[700px]"}>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            handleSubmitEdit();
          }}
          className="w-full flex  mb-2 rounded-md"
        >
          <InputGroup
            name="words"
            value={newListState.listAsString}
            placeholder=" "
            errors={newListState.errors}
            label="Type Some words Here!"
            required={false}
            type="text"
            invalid={newListState.invalid}
            onChange={(ev) => {
              setSubmitDisabled(false);
              setNewListExtras((prevState) => ({ ...prevState, errors: [], invalid: false }));
              setNewListState((prevState) => ({ ...prevState, errors: [], invalid: false }));
              handleNewWordListType(ev);
            }}
            submitted={newListState.submitted}
          />
        </form>
        <div className="m-w p-3 w-full bg-slate-50 dark:bg-primary-1200 rounded-md">
          <p className="text-primary-900 dark:text-primary-100 font-bolder text-xl leading-tight uppercase">
            Your New List ({newListState.list.length === 1 ? newListState.list.length + " word" : newListState.list.length + " words"}) : <span className="text-primary-1200 dark:text-white ">{wordlistToString(newListState.list)}</span>
          </p>
        </div>
        <div className="flex flex-col gap-2 justify-center items-centerrounded-md w-full lg:flex-row lg:gap-8">
          <InputGroup
            name="theme"
            className="max-w-[400px]"
            value={newListExtras.theme}
            placeholder=" "
            errors={newListExtras.errors}
            label="Word List Theme or Name"
            required={false}
            type="text"
            invalid={newListExtras.invalid}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              setSubmitDisabled(false);
              setNewListState((prevState) => ({ ...prevState, errors: [], invalid: false }));
              setNewListExtras((prevState) => ({ ...prevState, theme: ev.target.value, errors: [], invalid: false }));
            }}
            submitted={newListExtras.submitted}
          />
          <div className="flex items-center"></div>
          <button
            className={
              "duration-300 p-3 m-3 h-12 rounded px-5 py-1.5 font-thin " + (!submitDisabled ? "bg-primary-600 text-white  dark:bg-primary-900 " : "bg-gray-300 dark:bg-gray-700 dark:text-gray-500 text-gray-100 disabled pointer-events-none ")
            }
          >
            UPDATE LIST
          </button>
        </div>
      </div>
    </BlackBgBackdrop>
  );
}
