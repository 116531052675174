import React, { useEffect } from "react";
import GameDescription from "./GameDescription";
import GameText from "./GameText";
import tileImages from "src/assets/images/tiles/all_game_images3.webp";
import { Link } from "react-router-dom";

export default function GamesOverview() {
  useEffect(() => {
    document.title = "Games | whatstheword.io";
  }, []);
  return (
    <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900 p-5 flex flex-col gap-5">
      <div className="header bg-stone-100 dark:bg-slate-800 rounded-md p-5 flex flex-col md:flex-row gap-2 justify-center items-center">
        <div className="w-full h-fit m-5 rounded-lg overflow-hidden">
          <img src={tileImages} alt="" />
        </div>
        <div className="textbox flex flex-col gap-2 justify-center w-full items-center">
          <h4 className="font-bold text-2xl text-primary-1100 dark:text-primary-100 mb-3">Have a fun class with no effort!</h4>
          <p className="text-primary-1100 dark:text-primary-100 max-w-[65ch] indent-8">
            All of these games are designed to be fun, while being extremely flexible on how they are played. Majority of them require next to no setup or preparation, meaning you can have a fun class without any work on your part.
          </p>

          <p className="text-primary-1100 dark:text-primary-100 max-w-[65ch] indent-8">
            You can play the games as a reward for answering questions, you can use the games to review vocabulary, you can use them to get everyone to pay attention, pause the game, make the class read the story, and continue the game.
          </p>
          <p className="text-primary-1100 dark:text-primary-100 max-w-[65ch] indent-8">
            You can use them to practice spelling, you can use them to have the kids write the words, you can use them to just use up the last minutes of class! Anything is possible!
          </p>
        </div>
      </div>
      <div className="bg-stone-100 dark:bg-slate-800 rounded-md flex flex-col gap-2 justify-center items-center overflow-hidden">
        <div className="flex justify-around p-5 w-full bg-slate-100 dark:bg-slate-700 items-center gap-2">
          <h2 className="title text-blue-500  dark:text-blue-300 lg:text-4xl text-3xl font-bold">Pro Games</h2>
          <p className="font-medium md:text-lg text-primary-800 dark:text-primary-300">some of the games included in the Pro plan</p>
        </div>
        <GameDescription title="Fighters" image="/assets/images/games/fighters2.webp">
          <GameText>By far the students' favorite game. They will complain if you do not play it!</GameText>
          <GameText>
            This is a side-view fighting game, with everything customizable. Upload images of your students, their favourite characters, drawings they made, or inside jokes of your class, or choose among hundreds of images posted by us or
            other teachers. Kids love to see the characters they're used to fighting it out, even if its some cute animal or princess.
          </GameText>
          <GameText>It has a hardmode and bossfights, and using a secret button you can spawn in more monsters to keep the fight going.</GameText>
          <GameText>This game can be your best class control tool, a reward tool, or a way to practice the lesson.</GameText>
        </GameDescription>
        <GameDescription title="Hangman Battle" image="/assets/images/games/hangmanbattle.webp">
          <GameText>Kids love hangman, so why not make it a team based fight?</GameText>
          <GameText>You can upload your own images for the character, so you can use your students' favorite cartoon characters, images of them, or anything they would find fun.</GameText>
          <GameText>Both teams choose a letter, and if the letter appears in the word, their character attacks! More letters means stronger attacks. Finish the word to unleash a powerful final hit!</GameText>
        </GameDescription>
        <GameDescription title="Open The Safe" image="/assets/images/games/openthesafe.webp">
          <GameText>You are a team of thieves trying to crack the password of a safe, containing loads of money!</GameText>
          <GameText>The password seems to be, conveniently, all of your key words you just studied!</GameText>
          <GameText>Have the kids go one by one typing in the key words. Some key words might be missing, so they must check that all the letters are available on the keypad. Or have the whole class try to figure it out together.</GameText>
        </GameDescription>
        <GameDescription title="Twister" image="/assets/images/games/twister.webp">
          <GameText>A twister is in the area, messing up all your words! Put it back together!</GameText>
          <GameText>
            The twister also seems to have grabbed some random fires, magic, or even socks! (This will use the projectiles you have selected, used as powers in Fighters). Has a high chance of a hilarious object to be flying around,
            depending on what you have selected.
          </GameText>
          <GameText>The difficulty can be adjusted, and you can ask the other kids to help shout the next letter, or have the teacher help out.</GameText>
        </GameDescription>
        <GameDescription title="Alien Chase" image="/assets/images/games/alienchase.webp">
          <GameText>A word is shown on the screen, and is scrambled. And oh no! Aliens came by to attack! You must rebuild the word while avoiding the aliens.</GameText>
          <GameText>
            Either you or the students can choose their own difficulty. Each color of alien has a unique personality, and behaves differently. Some are easier to deal with than others. You, or the kids can mix and match the aliens how they
            want!
          </GameText>
          <GameText>
            It doesn't have to only be played one by one. Have other students who are sitting help the one who is trying by shouting out which letters they need to do. Perhaps whoever helped the most gets to try next. This gets the whole
            class involved in the words.
          </GameText>
        </GameDescription>
        <GameDescription title="Wordbrain" image="/assets/images/games/wordbrain.webp">
          <GameText>What word could it be?</GameText>
          <GameText>Attempting a guess will give you clues on which letters are correct but in the wrong position, what words are in the right position, and which are incorrect. See who gets the best score in the class!</GameText>
          <GameText>Have them try one by one, or have each student get one guess, or have the whole class try to solve it together.</GameText>
        </GameDescription>
        <GameDescription title="Time Trial" image="/assets/images/games/timetrial.webp">
          <GameText>Type the word before the time runs out! Easy right?</GameText>
          <GameText>Unfortunately the letters are spinning, jumping away, and being rather annoying!</GameText>
          <GameText>The time and difficulty can be changed, to either make it easy or hard depending on the level of your students. Write down their times to see who is the best, or just have fun one by one!</GameText>
        </GameDescription>
        <GameDescription title="Spot The Word" image="/assets/images/games/spottheword.webp">
          <GameText>Shuffle a deck of cards containing your key words. Pick two cards. Find the matching word!</GameText>
          <GameText>Extremely popular game among all students! Whoever finds the one word that appears on both cards first, is the winner.</GameText>
          <GameText>You can play it student vs student, 2vs2, 3vs3, team vs team, have everyone try at the same time, or have the students go against the teacher.</GameText>
        </GameDescription>
        <GameDescription title="UFO Blockade" image="/assets/images/games/ufoblockade.webp">
          <GameText>Constructing the word became too easy? All the kids know how to spell the words? Too bad! Theres a UFO blocking the letters!</GameText>
          <GameText>You now must construct the word in a different order. Perhaps only the 6th letter is available, perhaps only the 4th and 7th are.</GameText>
          <GameText>For this game, having the students help the one who is trying is best. have the kids who are sitting shout "#8 is E!" to help the one trying. This gets all the students involved.</GameText>
        </GameDescription>
        <GameDescription title="Alien Blaster" image="/assets/images/games/shoottheletters.webp">
          <GameText>Another kid favourite. You can play this game for an entire week without the kids getting tired of it.</GameText>
          <GameText>UFOs have stolen your word! You must shoot them in order, or else more UFOs will appear.</GameText>
          <GameText>
            Sometimes, a UFO will drop one of its weapons! Quickly grab it before it falls down. Theres dozens of weapons to find, each with different stats, meaning kids will have loads of fun taking down the UFOs, while learning the
            words.
          </GameText>
        </GameDescription>
        <div id="tools" className="flex flex-col w-full p-5">
          <div className=" w-full flex items-center px-5 py-5 border-l-[1px] border-t-[1px] rounded-tl-md border-primary-200">
            <h4 className="title dark:text-white text-3xl">Several Tools for teaching!</h4>
          </div>
          <div className="header min-h-[600px] lg:min-h-0 bg-stone-100 dark:bg-slate-800 flex flex-col lg:flex-row gap-2 p-2 border-l-[1px] max-h-[300px] border-primary-200 justify-center items-stretch">
            <div className=" border-primary-200 flex-1 border-[1px] rounded-md overflow-hidden object-cover">
              <img src="/assets/images/games/firstsecond.webp" alt="ordinal number game" className="w-full h-full object-cover" />
            </div>
            <div className=" border-primary-200 flex-1 border-[1px] rounded-md overflow-hidden object-cover">
              <img src="/assets/images/games/overunder.webp" alt="ordinal number game" className="w-full h-full object-cover" />
            </div>
            <div className=" border-primary-200 flex-1 border-[1px] rounded-md overflow-hidden object-cover">
              <img src="/assets/images/games/wordwheel.webp" alt="ordinal number game" className="w-full h-full object-cover" />
            </div>
          </div>
          <div className="textbox flex flex-col gap-2 justify-center items-center w-full p-3 border-l-[1px] border-primary-200">
            <GameText>
              These fun little tools are perfect for quickly teaching numbers, ordinals, or prepositions. You can also use the various wheel spinners to use as extra tools in your own games, to make your class even more fun.
            </GameText>
          </div>
          <div className="h-4 w-full border-l-[1px] border-b-[1px] rounded-bl-md mb-5 border-primary-200"></div>
        </div>
        <h4 className="text-xl lg:text-2xl font-bold dark:text-white my-5 mb-10">These games and more await!</h4>
        <p className="text font-bold dark:text-primary-300 text-primary-1100 my-5 mb-10">
          For an exhaustive list, visit our{" "}
          <Link to="/how-to" className="text-blue-500 dark:text-blue-300 underline">
            How To
          </Link>{" "}
          page.
        </p>
      </div>
      <div className="bg-stone-100 dark:bg-slate-800 rounded-md flex flex-col gap-2 justify-cemnter items-center overflow-hidden">
        <div className="flex justify-around p-5 w-full bg-slate-100 dark:bg-slate-700 items-center gap-2">
          <h2 className="title text-gray-800 dark:text-white lg:text-4xl text-3xl font-bold">Free Games</h2>
          <p className="font-medium md:text-lg text-primary-800 dark:text-primary-300">games included in the basic plan (and paid plans)</p>
        </div>
        <GameDescription title="Word Search" image="/assets/images/games/wordsearch.webp">
          <GameText>A typical wordsearch, using the words you typed in.</GameText>
          <GameText>Word searches are always a fun way to spend some time. Have the students go one by one finding the words. Ask them to read a word/sentence or answer a question before they try.</GameText>
          <GameText>
            Requires almost no effort on your part. You can adjust the size of the search to make it easier or harder depending on the level of the students. We found that esl students who have never seen wordsearches before struggle with
            finding words, so this wordsearch has a built in hint button, that quickly flashes the locations of the words to help the students who are struggling to find anything.
          </GameText>
        </GameDescription>
        <GameDescription title="Fighters Basic" image="/assets/images/games/fightinggamebasic.webp">
          <GameText>A basic version of Fighters, with no hardmode or boss fights. Choose some characters, enemies, powers, and backgrounds, and play!</GameText>
          <GameText>With a Pro plan, you can upload images of your students, their favourite cartoon characters, drawings they made, or inside jokes you have with the class to make it more fun.</GameText>
          <GameText>
            This game requires 0 prep work. You can play as a whole class, ask the class to repeat after you, and if they repeat correctly, you push the buttons to make the character power up, shoot or block. Or play one by one, ask a kid
            to answer a question or read the section, and they can pick if they want to power up block or shoot. (Recommended that the teacher clicks the buttons for this game.)
          </GameText>
        </GameDescription>
        <GameDescription title="Alien Chase Basic" image="/assets/images/games/alienchasebasic.webp">
          <GameText>A basic version of Alien Chase, with no choice of aliens, and no difference in personalities.</GameText>

          <GameText>This game was by far the most popular, so an upgraded version was created, and is now the Alien Chase that comes with the Pro plan.</GameText>
          <GameText>
            One word appears on the screen, and gets scrambled. Students need to put the word back together, while aliens chase them! This game is perfect for vocabulary lessons, or having the kids review the key words of a reading they are
            doing.
          </GameText>
        </GameDescription>
      </div>
    </div>
  );
}
