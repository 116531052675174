import React, { MouseEventHandler } from "react";

interface props {
  icon: "delete" | "edit" | "download" | "upload" | "published" | "cancel-edit" | "submit" | "flip" | "remove-upload" | "select";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text: string;
  disabled?: boolean;
}

export default function Button(props: props) {
  const iconRender = (type: props["icon"]) => {
    switch (type) {
      case "delete":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-red-900 dark:text-red-400 dark:group-hover:text-red-100 group-hover:text-red-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        );
      case "edit":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-amber-900 group-hover:text-amber-500 dark:text-amber-400 dark:group-hover:text-amber-100"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          </svg>
        );
      case "download":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-teal-800 dark:text-teal-300 dark:group-hover:text-teal-100 group-hover:text-teal-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
          </svg>
        );
      case "upload":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-sky-800 dark:text-sky-300 dark:group-hover:text-sky-100 group-hover:text-sky-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
          </svg>
        );
      case "published":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 duration-100 ease-in-out text-emerald-800 dark:text-emerald-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
      case "cancel-edit":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-amber-900 group-hover:text-amber-500 dark:text-amber-400 dark:group-hover:text-amber-100"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
      case "submit":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-emerald-800 group-hover:text-emerald-500 dark:text-emerald-300 dark:group-hover:text-emerald-100"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
          </svg>
        );
      case "flip":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-purple-800 group-hover:text-purple-500 dark:text-purple-300 dark:group-hover:text-purple-100"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
          </svg>
        );
      case "remove-upload":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-orange-800 group-hover:text-orange-500 dark:text-orange-300 dark:group-hover:text-orange-100"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z" />
          </svg>
        );
      case "select":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 duration-100 ease-in-out text-sky-800 dark:text-sky-300 dark:group-hover:text-sky-100 group-hover:text-sky-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
          </svg>
        );
    }
  };
  const borderColors = (type: props["icon"]) => {
    switch (type) {
      case "delete":
        return "border-red-500 hover:border-red-300";
      case "download":
        return "border-teal-500 hover:border-teal-300";
      case "edit":
        return "border-amber-500 hover:border-amber-300";
      case "select":
      case "upload":
        return "border-sky-500 hover:border-sky-300";
      case "published":
        return "border-emerald-500 pointer-events-none";
      case "cancel-edit":
        return "border-amber-500 hover:border-amber-300";
      case "submit":
        return "border-emerald-500 hover:border-emerald-300";
      case "flip":
        return "border-purple-500 hover:border-purple-300";
      case "remove-upload":
        return "border-orange-500 hover:border-orange-300";
    }
  };
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={
        "group duration-100 flex cursor-pointer gap-1 justify-center items-center rounded whitespace-nowrap px-2.5 py-0.5 outline-red-500 border-[1px]  " + borderColors(props.icon) + " disabled:border-gray-500 disabled:pointer-events-none"
      }
    >
      {iconRender(props.icon)} <span className="text-sm dark:text-white">{props.text}</span>
    </button>
  );
}
