import React, { ChangeEvent, useState } from "react";
import { InputType } from "src/utils/InputTypes";
import { InputGroup } from "./InputGroup";

interface props {
  label?: string;
  searching: boolean;
  onSubmit: Function;
  onClear: Function;
}

export default function SearchInput(props: props) {
  const [search, setSearch] = useState<InputType>({ ...({} as InputType), value: "", label: props.label || "Search", errors: [] as string[], invalid: false, placeholder: " " });
  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        props.onSubmit(search.value);
      }}
      className="flex gap-3 items-center justify-center p-2"
    >
      <InputGroup
        className=" w-[70%]"
        {...search}
        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
          setSearch((prev) => ({ ...prev, value: ev.target.value }));
        }}
      ></InputGroup>
      <button type="submit">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 dark:text-white text-primary-900 duration-100 ease-in-out cursor-pointer hover:dark:text-primary-200 hover:text-primary-1000"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
      {props.searching ? (
        <div
          onClick={() => {
            props.onClear();
            setSearch((prev) => ({ ...prev, value: "" }));
          }}
          className="bg-transparent border-2 border-primary-900 dark:border-white hover:border-primary-1000 hover:dark:border-primary-200 text-primary-900 duration-100 ease-in-out cursor-pointer hover:dark:text-primary-200 hover:text-primary-1000 dark:text-white px-4 py-1 rounded-md"
        >
          Clear
        </div>
      ) : (
        <></>
      )}
    </form>
  );
}
