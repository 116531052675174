import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "src/components/home";
import { Login } from "src/components/auth/Login";
import Dashboard from "src/components/dashboard";
import Navbar from "src/components/Navbar";
import GamesLoader from "./games";
import WordListManager from "./wordlists";
import ImageManager from "./images";
import PublicWordListManager from "./wordlists/PublicWordListManager";
import PublicImageManager from "./images/PublicImageManager";
import SignUp from "./auth/SignUp";
import Activate from "./auth/Activate";
import ChangePassword from "./auth/ChangePassword";
import Profile from "./profile";
import News from "./news";
import Pricing from "./orders";
import PlanView from "./orders/PlanView";
import GamesOverview from "./home/GamesOverview";
import ContactFormPage from "./home/ContactFormPage";
import HowToPage from "./games/HowToPage";
import PageNotFound from "./basic/PageNotFound";
import TermsAndConditionsPage from "./home/TermsAndConditionsPage";
import CancellationPolicyPage from "./home/CancellationPolicyPage";
import Success from "./orders/Success";
import ChangeEmail from "./auth/ChangeEmail";

export default function Body() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="" element={<Dashboard />} />
        <Route path="/welcome" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/games" element={<Navigate to="/all-games" replace />} />
        <Route path="/games/:game" element={<GamesLoader />} />
        <Route path="/my-wordlists" element={<WordListManager />} />
        <Route path="/public-wordlists" element={<PublicWordListManager />} />
        <Route path="/my-images" element={<ImageManager />} />
        <Route path="/public-images" element={<PublicImageManager />} />
        <Route path="/activate/:id" element={<Activate />} />
        <Route path="/activate/:id/:token" element={<Activate />} />
        <Route path="/reset-password" element={<ChangePassword />} />
        <Route path="/change-email" element={<ChangeEmail />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/news" element={<News />} />
        <Route path="/plans" element={<Pricing />} />
        <Route path="/plan/:id" element={<PlanView />} />
        <Route path="/all-games" element={<GamesOverview />} />
        <Route path="/contact-us" element={<ContactFormPage />} />
        <Route path="/how-to" element={<HowToPage />} />
        <Route path="/terms" element={<TermsAndConditionsPage />} />
        <Route path="/cancellation" element={<CancellationPolicyPage />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </Router>
  );
}
