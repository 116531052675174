import React from "react";
import { ImageType } from "./ImagePicker";

interface props {
  onClose: Function;
  type: ImageType;
}

const gameDict: Record<ImageType, Record<string, string>> = {
  CHARACTERS: {
    "Fighters (+ Basic)": "Used as the primary character that you fight with.",
    "Hangman Battle": "Used as the two fighters in the battle",
    "Prepositions": "Used as the primary character that will go on/in/under the box.",
    "Number Line": "Used in the lineup, will be duplicated if you have fewer characters than needed.",
    "Character Wheel": "Used at the random images in the wheel. Will repeat if the number selected exceeds the number of characters.",
  },
  MONSTERS: {
    "Fighters (+ Basic)": "Used as the main enemies that you fight.",
    "Monster Mash": "These are the monsters that will jump around.",
  },
  BACKGROUNDS: {
    "Fighters (+ Basic)": "Used randomly as the game background.",
    "Hangman Battle": "Used randomly as the battle background.",
    "WordSearch": "Used randomly as the game background.",
    "Monster Mash": "Used randomly as the game background.",
    "UFO Blockade": "Used randomly as the game background.",
    "Time Trial": "Used randomly as the game background.",
    "Wordbrain": "Used randomly as the game background.",
    "Prepositions": "Used randomly as the game background.",
    "Number Line": "Used randomly as the game background.",
    "Spot The Word": "Used as the card back design.",
    "Number/Word/Character Wheel": "Used randomly as the game background.",
  },
  PROJECTILES: {
    "Fighters (+ Basic)": "Used as the powers of the characters and monsters.",
    "Hangman Battle": "Used as the powers of the two fighters.",
    "Twister": "Will be used as debris flying around.",
  },
};

export default function ImageHelpText(props: props) {
  const type = props.type || "CHARACTERS";
  return (
    <div className="rounded-md relative bg-stone-50 dark:bg-slate-900 flex flex-col gap-3 p-3 m-3 overflow-y-scroll">
      <>
        <h4 className="text-primary-1100 dark:text-primary-100 text-lg pr-6">{type[0] + type.toLowerCase().slice(1, -1)} images are used in the following games:</h4>
        {Object.entries(gameDict[type]).map((entry) => {
          return (
            <p key={entry[1]}>
              <span className="text-primary-1000 dark:text-white text-md font-bold">{entry[0]}: </span> <span className="text-primary-1000 dark:text-primary-200 text-md">{entry[1]}</span>
            </p>
          );
        })}
        {/* close button */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
          className="h-10 w-10 absolute top-0 right-0 text-gray-700 hover:text-gray-500 dark:hover:text-primary-1100 dark:text-white cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </>
    </div>
  );
}
