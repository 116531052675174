import React from "react";

interface props {
  className?: string;
  image: string;
  right?: boolean;
}

export default function HowToImageBox({ className, image, right }: props) {
  return (
    <div className={(right ? "float-right" : "float-left") + " m-5  border-2 border-primary-500 dark:border-white rounded-md object-cover overflow-hidden " + className}>
      <img src={image} alt="Visual instructions" className="w-full h-auto object-cover" />
    </div>
  );
}
