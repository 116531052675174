import { useContext, useState } from "react";
import { UserContext } from "src/utils/UserContext";
import Button from "../basic/Button";
import { ReactComponent as Logo } from "../../assets/images/logo/logo-v1.svg";
import { getShownName } from "../images/ImagePicker";

export interface WordListInterface {
  id: number;
  words: string[];
  likes: number;
  theme: string;
  age_group: string;
  is_private: boolean;
  created_by: {
    id: number;
    username: string;
    display_name: string | null | undefined;
  };
  favourited_by: number;
  has_been_published: boolean;
  is_banned: boolean;
}

interface props {
  is_public: boolean;
  onDelete: Function;
  onFavourite?: Function;
  onPublish?: Function;
  onEdit?: Function;
  list: WordListInterface;
  listAsString: string;
  onSelect?: Function;
}

export default function WordListItem(props: props) {
  const { state } = useContext(UserContext);
  const [fakeFavourited, setFakeFavourited] = useState(false);
  const currentlySelectedWordlist = () => {
    return state.locallySelectedWordlist;
  };
  return (
    <div className="flex flex-col justify-center items-center lg:items-center lg:flex-row w-full lg:justify-between border-b-[1px] mt-1 pt-2 border-primary-500 dark:border-primary-200">
      <div className={"flex w-full gap-2 p-2 justify-center lg:justify-start flex-wrap items-center rounded lg:rounded-t " + (props.is_public ? "bg-sky-200/50 dark:bg-sky-800/50" : "bg-primary-200/50 dark:bg-primary-900/50")}>
        <p className={"theme h-fit text-center flex-0 rounded py-1 px-4 w-fit " + (props.is_public ? " text-primary-1100  dark:text-sky-200" : " text-primary-900 dark:text-primary-100  ")}>{props.list.theme}</p>
        {props.list.words.map((word, i) => {
          return (
            <p key={i} className={" font-medium text-lg uppercase bg-white dark:bg-slate-800/50 py-0.5 px-1.5 rounded-md " + (props.is_public ? "text-blue-900 dark:text-white" : "text-slate-800 dark:text-white")}>
              {word}
            </p>
          );
        })}
      </div>
      <div className="icons flex flex-wrap flex-1 lg:flex-nowrap gap-4 justify-center items-center border-[1px] border-primary-200 lg:border-none p-2 rounded m-2">
        {!props.is_public ? (
          <Button
            onClick={() => {
              if (props.onSelect) {
                props.onSelect();
              }
            }}
            text={currentlySelectedWordlist() === props.list.id ? "Selected" : "Use"}
            icon={currentlySelectedWordlist() === props.list.id ? "published" : "select"}
          ></Button>
        ) : (
          <></>
        )}
        {props.is_public && getShownName(props.list.created_by).name !== "" ? (
          <>
            <p className="dark:text-white whitespace-nowrap flex gap-2">
              By:
              {getShownName(props.list.created_by).id.toString() === process.env.REACT_APP_OFFICIAL_USER_ID ? (
                <span className="text-primary-800 dark:text-primary-200 flex gap-1">
                  <Logo className="h-6 w-6" /> {getShownName(props.list.created_by).name}
                </span>
              ) : (
                <span className="text-blue-800 dark:text-blue-300 flex gap-1">
                  <>{getShownName(props.list.created_by).name}</>
                </span>
              )}
            </p>
          </>
        ) : (
          <></>
        )}
        {props.onEdit && !props.is_public ? (
          <Button
            onClick={() => {
              if (props.onEdit) {
                props.onEdit();
              }
            }}
            text="Edit"
            icon="edit"
          ></Button>
        ) : (
          <></>
        )}
        {getShownName(props.list.created_by).id === state.id || !props.is_public ? (
          <Button
            onClick={() => {
              props.onDelete();
            }}
            text="Delete"
            icon="delete"
          ></Button>
        ) : (
          <></>
        )}
        {props.onFavourite && !fakeFavourited && getShownName(props.list.created_by).id !== state.id ? (
          <Button
            onClick={() => {
              if (props.onFavourite) {
                props.onFavourite();
                setFakeFavourited(true);
              }
            }}
            text="Add To List"
            icon="download"
          ></Button>
        ) : (
          <></>
        )}
        {fakeFavourited ? <Button icon="published" text="Saved"></Button> : <></>}
        {props.onPublish && !props.list.is_banned ? (
          <Button
            onClick={() => {
              if (props.onPublish && !props.list.has_been_published) {
                props.onPublish();
              }
            }}
            text={props.list.has_been_published ? "Published" : "Publish Online"}
            icon={props.list.has_been_published ? "published" : "upload"}
          ></Button>
        ) : (
          <></>
        )}
        {props.is_public ? (
          <span className={"flex items-center justify-center gap-1 text-green-900 dark:text-teal-200"}>
            {props.list.likes + (fakeFavourited ? 1 : 0)}
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
