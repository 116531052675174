import React, { createContext, ReactNode, useReducer } from "react";
import { ImageInterface } from "src/components/images/ImagePicker";
import { WordListInterface } from "src/components/wordlists/WordList";

export enum LicenseType {
  BASIC,
  PRO = "PRO",
  PREMIUM = "PRE",
  VIP = "VIP",
}

export interface User {
  //from backend
  id: Number;
  username: string;
  email: string;
  email_to_activate: string;
  is_active: boolean;
  display_name: string | null;
  date_joined: string;
  selected_wordlist: number;
  favourite_wordlists: WordListInterface[];
  favourite_monsters: number[];
  favourite_characters: number[];
  favourite_projectiles: number[];
  favourite_backgrounds: number[];
  has_active_plan: boolean;
  current_plan_type: LicenseType;
  has_valid_email: boolean;
  is_superuser: boolean;
  used_pro_plan_trial: boolean;
  pro_plan_trial_started_on: boolean;
  used_premium_plan_trial: boolean;
  premium_plan_trial_started_on: boolean;
  voted_posts: Record<string, { vote: boolean }>;
  read_posts: Record<string, { read: boolean }>;
  max_sessions_allowed: number;
  always_save_wordlist_across_devices: boolean;
  groups: number[];

  // used by frontend
  isAuthenticated: boolean | false;
  locallySelectedWordlist: number; //only used if max_devices >1

  // object data of images are stored here, to reduce requests on the server
  favourite_monsters_images: ImageInterface[];
  favourite_characters_images: ImageInterface[];
  favourite_projectiles_images: ImageInterface[];
  favourite_backgrounds_images: ImageInterface[];

  // once the images are stored here, annoyingly we also have to store that we cached the images
  // since having [] as favourite images could be a valid state
  // but, when we favourite or unfavourite an image, the data gets changed here in the context reducer down below,
  // in order to show instantenous results. But, there could be scenario where the user favourited an image and quickly left before
  // the data was updated. since the 'fake' favourite action is synchronous, it should go through
  // but then when we go back to /my-images that image might be missing, since it wasn't added to the actual user data
  // so to prevent that scenario, we also check if the favourite_$image_cached matches the favourites given by various /user/me calls
  // if they match, we continue using cache. If they dont, then we call /$image/mine/ again to get the new data

  cached_monsters_images: boolean;
  favourite_monsters_cached: number[];
  total_monsters: number;
  cached_characters_images: boolean;
  favourite_characters_cached: number[];
  total_characters: number;
  cached_projectiles_images: boolean;
  favourite_projectiles_cached: number[];
  total_projectiles: number;
  cached_backgrounds_images: boolean;
  favourite_backgrounds_cached: number[];
  total_backgrounds: number;
}

export enum AuthTypes {
  LogIn = "LOGIN",
  LogOut = "LOGOUT",
  UpdateUserData = "UPDATE_USER_DATA",
  UpdateChosenWordList = "UPDATE_CHOSEN_WORDLIST",
  AddNewImage = "ADD_NEW_IMAGE",
  DeleteImage = "DELETE_IMAGE",
  UpdateImage = "UPDATE_IMAGE",
  UnfavouriteImage = "UNFAVOURITE_IMAGE",
  FavouriteImage = "FAVOURITE_IMAGE",
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type UserPayload = {
  [AuthTypes.LogIn]: undefined;
  [AuthTypes.LogOut]: undefined;
  [AuthTypes.UpdateUserData]: {
    id: Number;
    username: string;
    email: string;
    is_active: boolean;
    date_joined: string;
    selected_wordlist: number;
    favourite_wordlists: WordListInterface[];
    favourite_monsters: number[];
    favourite_characters: number[];
    favourite_projectiles: number[];
    favourite_backgrounds: number[];
    favourite_monsters_images: ImageInterface[];
    favourite_characters_images: ImageInterface[];
    favourite_projectiles_images: ImageInterface[];
    favourite_backgrounds_images: ImageInterface[];
    cached_monsters_images: boolean;
    favourite_monsters_cached: number[];
    total_monsters: number;
    cached_characters_images: boolean;
    favourite_characters_cached: number[];
    total_characters: number;
    cached_projectiles_images: boolean;
    favourite_projectiles_cached: number[];
    total_projectiles: number;
    cached_backgrounds_images: boolean;
    favourite_backgrounds_cached: number[];
    total_backgrounds: number;
    voted_posts: Record<string, { vote: boolean }>;
    read_posts: Record<string, { read: boolean }>;
    max_sessions_allowed: number;
    groups: number[];
    locallySelectedWordlist: number;
  };
  [AuthTypes.UpdateChosenWordList]: {
    selected_wordlist: number;
  };
  [AuthTypes.AddNewImage]: {
    image_type: UserImageAttr;
    image: ImageInterface;
  };
  [AuthTypes.UpdateImage]: {
    image_type: UserImageAttr;
    image: ImageInterface;
  };
  [AuthTypes.DeleteImage]: {
    image_type: UserImageAttr;
    id: number;
  };
  [AuthTypes.UnfavouriteImage]: {
    image_type: UserImageAttr;
    image: ImageInterface;
  };
  [AuthTypes.FavouriteImage]: {
    image_type: UserImageAttr;
    image: ImageInterface;
  };
};
export type UserImageAttr = "favourite_monsters" | "favourite_characters" | "favourite_projectiles" | "favourite_backgrounds";
export type UserImageInterfaceAttr = "favourite_monsters_images" | "favourite_projectiles_images" | "favourite_characters_images" | "favourite_backgrounds_images";
export type FavouritesCachedAttr = "favourite_monsters_cached" | "favourite_projectiles_cached" | "favourite_characters_cached" | "favourite_backgrounds_cached";
const convertToUserImageAttr = (type: UserImageAttr) => {
  return (type + "_images") as UserImageInterfaceAttr;
};
const convertToFavouriteNumAttr = (type: UserImageAttr) => {
  return (type + "_cached") as FavouritesCachedAttr;
};

export type AuthActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export const userReducer = (state: User, action: AuthActions) => {
  switch (action.type) {
    case AuthTypes.LogIn:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AuthTypes.LogOut:
      localStorage.removeItem(state.id + "_swl");
      localStorage.removeItem("rememberme");
      return {
        ...state,
        isAuthenticated: false,
      };
    case AuthTypes.UpdateUserData:
      return {
        ...state,
        ...action.payload,
      };
    case AuthTypes.UpdateChosenWordList:
      return {
        ...state,
        selected_wordlist: action.payload.selected_wordlist,
        locallySelectedWordlist: action.payload.selected_wordlist,
      };
    case AuthTypes.AddNewImage:
      action.payload.image.is_liked = true;
      let type = action.payload.image_type;
      if (state[convertToUserImageAttr(type)]) {
        let newList = Array.from(state[convertToUserImageAttr(type)]);
        newList.unshift(action.payload.image);
        return { ...state, [type]: state[type].concat([action.payload.image.id]), [convertToUserImageAttr(type)]: newList, [convertToFavouriteNumAttr(type)]: state[convertToFavouriteNumAttr(type)].concat([action.payload.image.id]) };
      } else {
        return { ...state, [type]: state[type].concat([action.payload.image.id]), [convertToUserImageAttr(type)]: [action.payload.image] };
      }
    case AuthTypes.UpdateImage:
      action.payload.image.is_liked = true;
      if (Array.from(state[convertToUserImageAttr(action.payload.image_type)])) {
        let newUpdateList = Array.from(state[convertToUserImageAttr(action.payload.image_type)]);
        if (newUpdateList.map((x) => x.id).includes(action.payload.image.id)) {
          newUpdateList.splice(newUpdateList.map((x) => x.id).indexOf(action.payload.image.id), 1);
        }
        newUpdateList.push(action.payload.image);
        return { ...state, [convertToUserImageAttr(action.payload.image_type)]: newUpdateList };
      } else {
        return { ...state, [convertToUserImageAttr(action.payload.image_type)]: [action.payload.image] };
      }
    case AuthTypes.DeleteImage:
      let newDeleteList = Array.from(state[action.payload.image_type]);
      let newImageList = Array.from(state[convertToUserImageAttr(action.payload.image_type)]);
      newDeleteList.splice(newDeleteList.indexOf(action.payload.id), 1);
      newImageList.splice(newImageList.map((x) => x.id).indexOf(action.payload.id), 1);
      return { ...state, [action.payload.image_type]: newDeleteList, [convertToUserImageAttr(action.payload.image_type)]: newImageList };
    case AuthTypes.FavouriteImage:
      action.payload.image.is_liked = true;
      if (action.payload.image.created_by.id === state.id) {
        let newFavouriteList = Array.from(state[action.payload.image_type]);
        //if its already the user's its already in the images. we just mutate the state to force an update
        if (newFavouriteList.includes(action.payload.image.id)) {
          newFavouriteList.splice(newFavouriteList.indexOf(action.payload.image.id), 1);
        }
        newFavouriteList.push(action.payload.image.id);
        return { ...state, [action.payload.image_type]: newFavouriteList };
      } else {
        if (state[convertToUserImageAttr(action.payload.image_type)]) {
          //sometimes this is undefined
          let newFavouriteAddList = Array.from(state[convertToUserImageAttr(action.payload.image_type)]);
          newFavouriteAddList.push(action.payload.image);
          return { ...state, [action.payload.image_type]: state[action.payload.image_type].concat([action.payload.image.id]), [convertToUserImageAttr(action.payload.image_type)]: newFavouriteAddList };
        } else {
          return { ...state, [action.payload.image_type]: state[action.payload.image_type].concat([action.payload.image.id]), [convertToUserImageAttr(action.payload.image_type)]: [action.payload.image] };
        }
      }
    case AuthTypes.UnfavouriteImage:
      action.payload.image.is_liked = false;
      if (action.payload.image.created_by.id === state.id) {
        let newFavouriteList = Array.from(state[action.payload.image_type]);
        //if its already the user's its already in the images. we just mutate the state to force an update
        if (newFavouriteList.includes(action.payload.image.id)) {
          newFavouriteList.splice(newFavouriteList.indexOf(action.payload.image.id), 1);
        }
        return { ...state, [action.payload.image_type]: newFavouriteList };
      } else {
        //we delete the image like above
        let newUnfavouriteIdList = Array.from(state[action.payload.image_type]);
        newUnfavouriteIdList.splice(newUnfavouriteIdList.indexOf(action.payload.image.id), 1);
        if (state[convertToUserImageAttr(action.payload.image_type)]) {
          //sometimes its undefined
          let newUnfavouriteImageList = Array.from(state[convertToUserImageAttr(action.payload.image_type)]);
          newUnfavouriteImageList.splice(newUnfavouriteImageList.map((x) => x.id).indexOf(action.payload.image.id), 1);
          return { ...state, [action.payload.image_type]: newUnfavouriteIdList, [convertToUserImageAttr(action.payload.image_type)]: newUnfavouriteImageList };
        } else {
          return { ...state, [action.payload.image_type]: newUnfavouriteIdList, [convertToUserImageAttr(action.payload.image_type)]: [action.payload.image] };
        }
      }
    default:
      return state;
  }
};

const UserContext = createContext<{
  state: User;
  dispatch: React.Dispatch<AuthActions>;
}>({
  state: {} as User,
  dispatch: () => null,
});

const UserProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(userReducer, {} as User);

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

export { UserProvider, UserContext };
