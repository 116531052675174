import axios from "axios";
import React, { useContext, useEffect } from "react";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import Footer from "../home/Footer";
import OrderBox from "./OrderBox";
import StartTrialBox from "./StartTrialBox";

export default function Pricing() {
  const { state, dispatch } = useContext(UserContext);
  useEffect(() => {
    document.title = "Pricing | whatstheword.io";
    if (!localStorage.getItem("loggedInBefore")) {
      return;
    }
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
        });
    } else {
      if (!state.used_pro_plan_trial || !state.used_premium_plan_trial) {
        window.scrollTo(0, 0);
      }
    }
  }, [state.isAuthenticated, dispatch]);

  return (
    <div className="w-full bg-stone-50 dark:bg-slate-900 min-h-full h-auto">
      {!state.used_premium_plan_trial || !state.used_pro_plan_trial ? (
        <div className="trialbox flex w-full h-fit flex-col md:flex-row gap-4 p-4">
          <StartTrialBox type="PRO" used={state.used_pro_plan_trial}></StartTrialBox>
          <StartTrialBox type="PREMIUM" used={state.used_premium_plan_trial}></StartTrialBox>
        </div>
      ) : (
        <></>
      )}
      <div className="w-full p-4">
        <OrderBox></OrderBox>
      </div>
      <Footer />
    </div>
  );
}
