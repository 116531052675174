import React from "react";

export interface BlogEntryInterface {
  id: 1;
  name: string;
  text: string;
  image: string;
  likes: number;
  dislikes: number;
  created_on: string;

  //hadnled by frontend
  isLiked?: boolean;
  isDisliked?: boolean;
}

interface props {
  data: BlogEntryInterface;
  allowLikes: boolean;
  onLike?: Function;
  onDislike?: Function;
  isLiked?: boolean;
  isDisliked?: boolean;
}

export default function BlogEntry(props: props) {
  const { data } = props;
  return (
    <div className="w-full border-l-4 border-primary-700 h-fit bg-white shadow dark:border-primary-400 dark:bg-slate-800 rounded-md flex flex-col justify-center items-center overflow-hidden">
      <div className="w-full flex items-center flex-col-reverse md:flex-row p-2 ">
        <div className="bg-white mb-5 md:mb-0 dark:bg-slate-800 rounded-md object-cover h-[281px] w-[500px] border-primary-200 dark:border-primary-1100 border-[1px] overflow-hidden flex  justify-center items-center aspect-video">
          <img width={500} height={281} className="object-cover w-full h-full bg-slate-200 dark:bg-slate-700" src={data.image} alt={data.name} />
        </div>
        <div className="md:m-0 md:ml-5 m-4 flex flex-col h-full justify-start items-center flex-1 gap-10">
          <h4 className="title font-bold text-primary-1200 text-2xl dark:text-white">{data.name}</h4>
          <p className="text-primary-1000 dark:text-primary-300 max-w-[65ch] indent-5 ">{data.text}</p>
        </div>
      </div>
      <div className="footer flex justify-between w-full">
        <div className="dark:bg-primary-400 bg-primary-700 px-2 py-1 rounded-tr-md text-white dark:text-primary-1200">Posted On: {data.created_on?.split("T")[0]}</div>
        <div className="likes flex gap-2 dark:bg-primary-400 bg-primary-700 px-2 py-1 rounded-tl-md text-white dark:text-primary-1200">
          <svg
            onClick={() => {
              if (props.onLike && props.allowLikes) {
                props.onLike();
              }
            }}
            xmlns="http://www.w3.org/2000/svg"
            className={"h-6 w-6 select-none " + (props.isLiked ? "text-emerald-500 dark:text-emerald-200 " : "") + (props.allowLikes ? "cursor-pointer  hover:text-primary-300 dark:hover:text-primary-700" : "")}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
            />
          </svg>
          <span className="select-none">{data.likes}</span>
          <svg
            onClick={() => {
              if (props.onDislike && props.allowLikes) {
                props.onDislike();
              }
            }}
            xmlns="http://www.w3.org/2000/svg"
            className={"h-6 w-6 select-none " + (props.isDisliked ? "text-amber-500 dark:text-amber-200 " : "") + (props.allowLikes ? "cursor-pointer  hover:text-primary-300 dark:hover:text-primary-700" : "")}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
            />
          </svg>
          <span className="select-none">{data.dislikes}</span>
        </div>
      </div>
    </div>
  );
}
