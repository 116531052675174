import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { BackendErrors } from "./utils/ErrorTypes";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

axios.defaults.baseURL = process.env.REACT_APP_BASE_BACKEND_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    const sessionCookie = response.headers["x-csrftoken"];
    if (sessionCookie) {
      axios.defaults.headers.common["x-csrftoken"] = sessionCookie;
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === BackendErrors.CSRF.status && BackendErrors.CSRF.messages.includes(error.response?.data?.detail)) {
      //csrf mismatch
      return axios.get("/api/users/get_csrf/").then((res) => {
        axios.defaults.headers.common["x-csrftoken"] = res.headers["x-csrftoken"];
        originalRequest.headers["x-csrftoken"] = res.headers["x-csrftoken"];
        originalRequest._retry = true;
        return axios.request(originalRequest);
      });
    } else {
      return Promise.reject(error);
    }
  }
);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  axios.interceptors.request.use(
    (request) => {
      // console.log(`Axios Request: ${request.baseURL}${request.url}`);
      // console.log(request);
      return request;
    },
    (error) => {
      // console.log(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // console.log(`Axios Request: ${response.request.responseURL}`);
      // console.log(response);
      return response;
    },
    (error) => {
      // console.log(error);
      return Promise.reject(error);
    }
  );
} else {
  // production code
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
