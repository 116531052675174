import { CreateOrderActions, CreateOrderData, OnApproveActions, OnApproveData, CreateSubscriptionActions } from "@paypal/paypal-js";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "src/utils/UserContext";

interface props {
  coupon?: string;
  product: string;
  oneTimePayment: boolean;
  onError: Function;
}

export default function PaypalButton(props: props) {
  const [loadingHidden, setLoadingHidden] = useState(false);
  const { state } = useContext(UserContext);
  const navigate = useNavigate();
  const registerOrder = useCallback(async () => {
    let data: {
      email: string;
      plan_selected: string;
      coupon_code?: string;
      payment: "SINGLE" | "SUB";
      provider: "PAYPAL";
      payment_option: "paypal";
      currency: "USD";
    } = {
      email: state.email,
      plan_selected: props.product,
      payment: props.oneTimePayment ? "SINGLE" : "SUB",
      provider: "PAYPAL",
      payment_option: "paypal",
      currency: "USD",
      coupon_code: props.coupon,
    };
    const csrf = await axios.get("/api/users/get_csrf/");
    return axios.post("/api/payments/process-payment/", data);
  }, [props, state.email]);

  // Will be triggered when starts transaction
  const createOrder = async (data: CreateOrderData, actions: CreateOrderActions) => {
    let resOrder = { data: {} };
    try {
      resOrder = await registerOrder();
    } catch (err) {
      let error = err as any;
      let all_errors = [] as string[];
      if (error && error.response && error.response.data) {
        for (let key in error.response.data) {
          let errors: string[] = [];
          if (typeof error.response.data[key] === "string") {
            errors = [error.response.data[key]];
          } else {
            errors = error.response.data[key];
          }
          all_errors = all_errors.concat(errors);
        }
      }
      if (all_errors.length === 0) {
        all_errors = [""];
      }
      props.onError(all_errors);
    }
    const orderData = resOrder.data as { price: string; id: string };
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            value: orderData.price,
          },
          // Put your invoice ID to the paypal request (it should be string), to access it later
          custom_id: orderData.id,
        },
      ],
    });
  };

  const createSubscription = async (data: Record<string, unknown>, actions: CreateSubscriptionActions) => {
    let resOrder = { data: {} };
    try {
      resOrder = await registerOrder();
    } catch (err) {
      let error = err as any;
      let all_errors = [] as string[];
      if (error && error.response && error.response.data) {
        for (let key in error.response.data) {
          let errors: string[] = [];
          if (typeof error.response.data[key] === "string") {
            errors = [error.response.data[key]];
          } else {
            errors = error.response.data[key];
          }
          all_errors = all_errors.concat(errors);
        }
      }
      if (all_errors.length === 0) {
        all_errors = [""];
      }
      props.onError(all_errors);
    }
    const orderData = resOrder.data as { plan_id: string; id: string };
    return actions.subscription.create({
      plan_id: orderData.plan_id,
      custom_id: orderData.id,
    });
  };

  // Will be triggered when user approved order
  const onApproveOrder = async (data: OnApproveData, actions: OnApproveActions) => {
    // This function captures the funds from the transaction.
    const captured = await actions?.order?.capture();
    if (captured) {
      if (captured.status === "COMPLETED") {
        navigate("/success");
      }
    }
  };
  const onApproveSubscription = async (data: OnApproveData, actions: OnApproveActions) => {
    // This function captures the funds from the transaction.
    const captured = await data.subscriptionID;
    if (captured) {
      navigate("/success");
    }
  };

  return (
    <div className="relative">
      {props.oneTimePayment ? (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || "" }}>
          <PayPalButtons
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
            className="w-[200px] h-[40px] mx-2 flex justify-center items-center"
            style={{ layout: "horizontal", label: "pay" }}
            onInit={() => setLoadingHidden(true)}
          />
        </PayPalScriptProvider>
      ) : (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || "", "vault": true, "intent": "subscription" }}>
          <PayPalButtons
            createSubscription={(data, actions) => createSubscription(data, actions)}
            onApprove={(data, actions) => onApproveSubscription(data, actions)}
            className="w-[200px] h-[40px] mx-2 flex justify-center items-center"
            style={{ layout: "horizontal", label: "subscribe" }}
            onInit={() => setLoadingHidden(true)}
          />
        </PayPalScriptProvider>
      )}
      {loadingHidden ? (
        <></>
      ) : (
        <div className="absolute w-[200px] h-[35px] mx-2 mt-1 top-0 left-0 flex justify-center items-center  rounded-md select-none">
          <p className="text-amber-700 dark:text-amber-200">loading paypal...</p>
        </div>
      )}
    </div>
  );
}
