import React, { useEffect, useState } from "react";

//colors are the same as the ones used in the models.py in the images django app
export const validColors = [
  { name: "random", opt: "ran", hexcode: "bg-transparent" },
  { name: "black", opt: "bla", hexcode: "bg-[#000000]" },
  { name: "purple", opt: "pur", hexcode: "bg-[#6800ff]" },
  { name: "blue", opt: "blu", hexcode: "bg-[#0000FF]" },
  { name: "light blue", opt: "lib", hexcode: "bg-[#3febff]" },
  { name: "teal", opt: "tea", hexcode: "bg-[#00d9ba]" },
  { name: "green", opt: "gre", hexcode: "bg-[#00AA00]" },
  { name: "light green", opt: "lig", hexcode: "bg-[#2cff00]" },
  { name: "yellow", opt: "yel", hexcode: "bg-[#FFFF00]" },
  { name: "orange", opt: "ora", hexcode: "bg-[#ff7800]" },
  { name: "red", opt: "red", hexcode: "bg-[#FF0000]" },
  { name: "pink", opt: "pin", hexcode: "bg-[#ff3fd8]" },
  { name: "white", opt: "whi", hexcode: "bg-[#FFFFFF]" },
];

interface props {
  selected?: string;
  onSelect?: Function;
  allow_edit: Boolean;
  errors?: string[];
}

export default function ColorPicker(props: props) {
  const [selected, setSelected] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    if (props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);
  const getLeftPos = () => {
    const num = document.body.clientWidth < 640 ? 1.25 : 1.5;
    return num * selected + "rem";
  };
  useEffect(() => {
    //selected will be the opt name for ease of form filling, so we need to find the index of it here, since the order is not the same
    //selected could also be the full name too without spaces

    let id = validColors.indexOf(validColors.filter((x) => x.opt === props.selected)[0]);
    if (id < 0) {
      id = validColors.indexOf(validColors.filter((x) => x.name.replace(" ", "") === props.selected?.toLowerCase())[0]);
    }
    if (!id || id < 0) {
      id = 0;
    }
    setSelected(id);
  }, [props.selected, props.allow_edit]);
  return (
    <div className={"flex w-full flex-col justify-center items-center gap-2 "}>
      <div className={"flex justify-center items-center  " + (props.allow_edit ? "flex-col gap-2" : "flex-row gap-4")}>
        <h4 className="selected capitalize text-primary-1100 dark:text-white text-center w-full">
          Character Color: <span className="text-green-900 dark:text-teal-200">{validColors[selected].name}</span>
        </h4>
        {props.allow_edit ? (
          <p className="text-primary-1100 dark:text-white text-center mt-2 text-xs mb-2">This colour is used for random game elements, like shield color or spaceship color. 'Random' will select a different one each time.</p>
        ) : (
          <></>
        )}
        <div className="flex relative w-fit justify-center">
          {validColors
            .filter((x, i) => {
              if (props.allow_edit) {
                return true;
              } else {
                return i === selected;
              }
            })
            .map((color, i) => {
              return (
                <div
                  key={i}
                  className={" h-5 w-5  sm:w-6 sm:h-6 rounded border-white border-2 " + color.hexcode + (props.allow_edit ? " cursor-pointer" : "")}
                  onClick={() => {
                    if (!props.allow_edit) return;
                    setSelected(i);
                    if (props.onSelect) {
                      props.onSelect(validColors[i].opt);
                    }
                  }}
                >
                  {/* random is a special case */}
                  {color.name === "random" ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 text-primary-1100 dark:text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          {props.allow_edit ? <div className="absolute h-5 w-5 sm:w-6 sm:h-6 border-gray-500 border-2 rounded duration-200 ease-in-out" style={{ left: getLeftPos() }}></div> : <></>}
        </div>
      </div>
      <div className="errors text-red-500 w-full flex justify-center flex-col items-center">
        {errors.map((err, i) => {
          return (
            <p key={i} className="font-thin text-center before:content-['_•_'] px-2 w-fit">
              {err.trim() === "" ? "Something went wrong. Please try again later." : err}
            </p>
          );
        })}
      </div>
    </div>
  );
}
