import React from "react";
import { makeUrlFriendly } from "../dashboard/GameLink";

interface props {
  title: string;
  children?: React.ReactNode;
}

export default function HowToContainer({ title, children }: props) {
  return (
    <div id={makeUrlFriendly(title)} className="w-full p-3 bg-stone-50 dark:bg-slate-800 shadow-gray-900 dark:shadow-gray-700 shadow-sm rounded-md">
      <h4 className="title text-primary-1100 dark:text-white text-2xl mb-5 font-bold">{title} - How To</h4>
      <div className="flex flex-col">{children}</div>
      <div
        className="dark:text-white cursor-pointer flex justify-center w-fit"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
          });
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M8 7l4-4m0 0l4 4m-4-4v18" />
        </svg>
        back to top
      </div>
    </div>
  );
}
