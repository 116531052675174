import React from "react";
import { makeUrlFriendly } from "../dashboard/GameLink";

interface props {
  title: string;
  image: string;
  children: React.ReactNode;
}

export default function GameDescription({ title, image, children }: props) {
  return (
    <div id={makeUrlFriendly(title)} className="flex flex-col w-full p-5">
      <div className=" w-full flex items-center px-5 py-5 border-l-[1px] border-t-[1px] rounded-tl-md border-primary-200">
        <h4 className="title dark:text-white text-3xl">{title}</h4>
      </div>
      <div className="header bg-stone-100 dark:bg-slate-800 rounded-md flex flex-col md:flex-row gap-2 justify-center items-center">
        <div className="w-full h-fit  border-primary-200 border-l-[1px] rounded-r-md overflow-hidden">
          <img src={image} alt={title} className="w-full" />
        </div>
        <div className="textbox flex flex-col gap-2 justify-center w-full p-3 md:p-0 items-center">{children}</div>
      </div>
      <div className="h-4 w-full border-l-[1px] border-b-[1px] rounded-bl-md mb-5 border-primary-200"></div>
    </div>
  );
}
