import React from "react";
import ImagePicker from "./ImagePicker";

export default function PublicImageDashboard() {
  return (
    <div className="flex flex-col h-full lg:grid lg:grid-cols-2 lg:grid-rows-[repeat(2,auto)] ">
      <ImagePicker isPublic={true} type="CHARACTERS"></ImagePicker>
      <ImagePicker isPublic={true} type="MONSTERS"></ImagePicker>
      <ImagePicker isPublic={true} type="BACKGROUNDS"></ImagePicker>
      <ImagePicker isPublic={true} type="PROJECTILES"></ImagePicker>
    </div>
  );
}
