import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "src/utils/UserContext";
import GalleryImage from "../images/GalleryImage";
import { ImageInterface } from "../images/ImagePicker";
import AddImageButton from "../images/AddImage";
import Button from "../basic/Button";
import BlackBgBackdrop from "./BlackBgBackdrop";
import CreateImageForm from "../images/CreateImageForm";
import LoadMoreImagesButton from "../images/LoadMoreImagesButton";

interface props {
  onClose?: Function;
  onSelect?: Function;
  selected: ImageInterface[];
}

const MAX_POWERS_ALLOWED = 5;

//a simpler version of a gallery, uses is_basic for the gallery elements. Only used for the select powers screen

export default function PowerGallery(props: props) {
  const { state, dispatch } = useContext(UserContext);
  const [showCreate, setShowCreate] = useState(false);
  const [selectedPowers, setSelectedPowers] = useState<number[]>([]);
  const [images, setImages] = useState<ImageInterface[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  useEffect(() => {
    //grab the current user's favourite powers and show them. is_liked is handled by frontend
    setSelectedPowers(props.selected.map((x) => x.id));
    axios.get("/api/images/projectiles/mine/").then((res) => {
      setImages(
        res.data.results.map((x: ImageInterface) => {
          x.is_liked = state.favourite_projectiles.includes(x.id);
          return x;
        })
      );
      setImagesLoaded(res.data.results.length);
      setHasMore(res.data.results.length < res.data.count);
    });
  }, [state.favourite_projectiles, props.selected]);

  const addSelection = (id: number) => {
    //adds or removes the ids from the list. if number reaches max, the first element is removed
    let newList = Array.from(selectedPowers);
    if (newList.includes(id)) {
      newList.splice(newList.indexOf(id), 1);
    } else {
      while (newList.length >= MAX_POWERS_ALLOWED) {
        newList.shift();
      }
      newList.push(id);
    }
    newList = Array.from(new Set(newList));
    setSelectedPowers(newList);
  };
  const closeFullscreen = () => {
    setShowCreate(false);
  };

  const loadMore = () => {
    setHasMore(false);
    axios.get(`/api/images/projectiles/?limit=50&offset=${imagesLoaded}`).then((res) => {
      setImagesLoaded(imagesLoaded + res.data.results.length);
      setHasMore(imagesLoaded + res.data.results.length < res.data.count);
      setImages(images.concat(res.data.results));
    });
  };

  return (
    <>
      <div className="h-[400px] w-[400px] p-5 m-5 bg-primary-200/90 dark:bg-primary-900/90 rounded-lg flex flex-col place-content-between gap-2">
        <div className="flex flex-wrap justify-center gap-1 overflow-y-scroll items-center overflow-hidden">
          <AddImageButton className="w-[50px] h-[50px] md:w-[100px] md:h-[100px] aspect-square" text={"Add A New Projectile"} onClick={() => setShowCreate(true)}></AddImageButton>
          {images
            .sort((a, b) => {
              if (b.likes - a.likes === 0) {
                return Date.parse(b.created_on) - Date.parse(a.created_on);
              } else {
                return b.likes - a.likes;
              }
            })
            .map((x, i) => {
              return (
                <div key={x.id} className="w-[50px] md:h-[100px] h-[50px] md:w-[100px]">
                  <GalleryImage
                    image={x}
                    contain={true}
                    type="PROJECTILES"
                    is_public={false}
                    is_basic={true}
                    onClick={() => {
                      addSelection(x.id);
                    }}
                    selected={selectedPowers.includes(x.id)}
                  ></GalleryImage>
                </div>
              );
            })}
          {hasMore ? <LoadMoreImagesButton onClick={() => loadMore()} className="w-[50px] h-[50px] md:w-[100px] md:h-[100px] aspect-square"></LoadMoreImagesButton> : <></>}
        </div>
        <div className="buttons w-full flex justify-center gap-2 bg-gray-200/90 dark:bg-primary-1000/90 p-2 rounded-lg">
          <Button
            text="Choose Powers"
            icon="upload"
            onClick={() => {
              if (props.onSelect) {
                props.onSelect(images.filter((x) => selectedPowers.includes(x.id)));
              }
            }}
          ></Button>
          <Button
            text="Cancel"
            icon="cancel-edit"
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
          ></Button>
        </div>
      </div>
      {showCreate ? (
        <BlackBgBackdrop
          onClose={() => {
            closeFullscreen();
          }}
        >
          <CreateImageForm
            type={"Projectile"}
            onClose={() => {
              closeFullscreen();
            }}
          ></CreateImageForm>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
    </>
  );
}
