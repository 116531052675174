import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "../basic/InputGroup";
import { InputType } from "src/utils/InputTypes";
import ScrollImage from "../home/ScrollImage";

interface HelpInputType extends InputType {
  helptext?: string;
}

export default function SignUp() {
  useEffect(() => {
    document.title = "Sign Up | whatstheword.io";
  }, []);

  const navigate = useNavigate();
  const [globalErrors, setGlobalErrors] = useState<string[]>([] as string[]);
  const [success, setSuccess] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const inputs: HelpInputType[] = [
    {
      value: "",
      name: "username",
      type: "text",
      placeholder: " ",
      label: "Username",
      errors: [],
      required: true,
      invalid: false,
      submitted: false,
      helptext: "A unique username, used to log in",
    },
    {
      value: "",
      name: "password",
      type: "password",
      placeholder: " ",
      label: "Password",
      errors: [],
      required: true,
      invalid: false,
      submitted: false,
    },
    {
      value: "",
      name: "passwordConfirm",
      type: "password",
      placeholder: " ",
      label: "Confirm Password",
      errors: [],
      required: true,
      invalid: false,
      submitted: false,
    },

    {
      value: "",
      name: "email",
      type: "email",
      placeholder: " ",
      label: "Email",
      errors: [],
      required: true,
      invalid: false,
      submitted: false,
      helptext: "Your email will not be shown to anyone.",
    },
    {
      value: "",
      name: "display_name",
      type: "text",
      placeholder: " ",
      label: "Display Name",
      errors: [],
      required: false,
      invalid: false,
      submitted: false,
      helptext: "Optional. This will be displayed to the public instead of your username.",
    },
  ];
  const inputObj: Record<string, HelpInputType> = inputs.reduce((o, input) => Object.assign(o, { [input.name]: input }), {});
  const [inputState, setInputState] = useState(inputObj);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(10);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const startTimer = () => {
    const interval = setInterval(() => {
      setRedirectTimer((prev) => {
        if (prev <= 0) {
          clearInterval(interval);

          return 0;
        } else {
          return prev - 1;
        }
      });
    }, 1000);
    setIntervalId(interval);
  };

  useEffect(() => {
    if (redirectTimer <= 0) {
      navigate("/login");
    }
  }, [redirectTimer]);
  useEffect(() => {
    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setGlobalErrors([]);
    //clear all previous errors
    inputs.forEach((input) => {
      setInputState((prev) => ({ ...prev, [input.name]: { ...prev[input.name], errors: [], invalid: false } }));
    });
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.required && inputState[input.name].value === "") {
        setInputState((prev) => ({ ...prev, [input.name]: { ...prev[input.name], errors: ["You must fill out this field."], invalid: true } }));
        hasErrors = true;
      }
    });
    if (inputState.password.value !== inputState.passwordConfirm.value) {
      setInputState((prev) => ({ ...prev, passwordConfirm: { ...prev.passwordConfirm, errors: ["The password doesn't match."], invalid: true } }));
      hasErrors = true;
    }

    if (hasErrors) return;

    let fd = new FormData();
    fd.set("username", inputState.username.value);
    fd.set("password", inputState.password.value);
    fd.set("email", inputState.email.value);
    fd.set("accepted_terms_and_conditions", agreeToTerms.toString());
    if (inputState.display_name.value !== "") {
      fd.set("display_name", inputState.display_name.value);
    }

    setButtonDisabled(true);
    axios
      .post("/api/users/", fd)
      .then((res) => {
        if (res.status === 201) {
          setButtonDisabled(false);
          setSuccess(true);
          startTimer();
        } else {
          setGlobalErrors(["Something went wrong. Try refreshing the page."]);

          setButtonDisabled(false);
        }
      })
      .catch((err) => {
        for (let key in err.response.data) {
          let errors: string[] = [];
          if (typeof err.response.data[key] === "string") {
            errors = [err.response.data[key]];
          } else {
            errors = err.response.data[key];
          }
          switch (key) {
            case "username":
            case "password":
            case "email":
            case "display_name":
              setInputState((prev) => ({ ...prev, [key]: { ...prev[key], errors: errors, invalid: true } }));
              break;
            default:
              setGlobalErrors(errors);
          }
        }
        setButtonDisabled(false);
      });
  };
  return (
    <div className="w-full h-auto min-h-full flex flex-col-reverse md:flex-row justify-center items-center place-content-around dark:bg-slate-900">
      <div className="mr-5 h-[80vh] w-full max-w-[50%] hidden md:block">
        <ScrollImage className="h-full w-full "></ScrollImage>
      </div>
      <div className="flex justify-center items-center w-full lg:pl-20">
        <div className="dark:bg-primary-1200 shadow-primary-500 dark:shadow-primary-1100 rounded-md shadow-sm m-5 md:w-[70%] h-fit w-max-[700px] h-max-[90%] p-10 flex justify-center flex-col gap-3 items-center">
          <h4 className="text-lg text-primary-900 dark:text-white font-bold m-0 p-0 ">Create An Account!</h4>
          <form onSubmit={(ev) => handleSubmit(ev)} className="flex flex-col">
            {inputs.map((input, i) => {
              return (
                <div key={i} className="mb-3">
                  <InputGroup
                    className="mb-2"
                    {...inputState[input.name]}
                    onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                      setInputState((prev) => ({ ...prev, [input.name]: { ...prev[input.name], errors: [], invalid: false, value: ev.target.value } }));
                      setGlobalErrors([]);
                    }}
                    value={inputState[input.name].value}
                  />
                  {input.helptext && input.helptext !== "" ? <p className="text-primary-500 dark:text-priamry-800 text-xs font-thin">• {input.helptext}</p> : <></>}
                </div>
              );
            })}
            <div className="flex justify-center items-center mb-6">
              <div className="flex items-center h-8">
                <input
                  id="agreeToTerms"
                  type="checkbox"
                  checked={agreeToTerms}
                  onChange={(ev) => {
                    setAgreeToTerms(ev.target.checked);
                  }}
                  className="w-5 h-5 border border-primary-200 rounded bg-primary-50 dark:bg-primary-1100 focus:ring-3 focus:ring-primary-300"
                />
              </div>
              <label htmlFor="agreeToTerms" className="ml-2 text-xs place-self-center font-thin text-gray-900 dark:text-primary-200">
                I agree to the{" "}
                <a href="/terms" className="text-blue dark:text-blue-200 underline">
                  Terms and Conditions
                </a>
              </label>
            </div>
            {!success ? (
              <button
                type="submit"
                disabled={buttonDisabled}
                className="text-white font-thin mt-10 disabled:bg-gray-500 disabled:text-gray-200 disabled:pointer-events-none focus:ring-1 rounded-md text-md lg:text-lg xl:text-xl w-full sm:w-auto px-5 py-1.5 text-center self-center justify-self-center bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 focus:outline-none"
              >
                Sign Up
              </button>
            ) : (
              <div className="w-full p-5 flex justify-center items-center flex-col gap-4">
                <p className="font-bold text-emerald-600 dark:text-emerald-200">Account Created!</p>
                <p className="text-emerald-600 dark:text-emerald-200"> You should get a verification email to {inputState.email.value} shortly. Please verify your email before you log in.</p>
                <p className="text-sm text-emerald-600 dark:text-emerald-200">You will be redirected to the login page in {redirectTimer} seconds.</p>
              </div>
            )}
          </form>
          {globalErrors.length ? (
            <div className="w-full flex flex-col p-5">
              {globalErrors.map((err, i) => {
                return (
                  <p className="text-red-500 dark:text-red-200 font-thin text-sm before:content-['•_']" key={i}>
                    {err.trim() === "" ? "Something went wrong. Please try again later." : err}
                  </p>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
