import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LicenseType, UserContext } from "src/utils/UserContext";
import { ReactComponent as Lock } from "../../assets/images/lock.svg";

export interface GameInterface {
  creation_time: string;
  has_template: boolean;
  internal_id: number;
  internal_name: string;
  name: string;
  uses_words: boolean;
  uses_backgrounds: boolean;
  uses_projectiles: boolean;
  uses_characters: boolean;
  uses_monsters: boolean;

  needs_pro: boolean;
  needs_premium: boolean;
}

interface GameLinkInterface extends GameInterface {
  user_plan: LicenseType;
}
export const makeUrlFriendly = (text: string = "none") => {
  return text.replaceAll(" ", "").toLocaleLowerCase();
};

export default function GameLink(props: GameLinkInterface) {
  const { state } = useContext(UserContext);

  const image_url = "/assets/images/games/" + (props.internal_name || "default") + ".webp";
  const getInfo = () => {
    let sentence = "Uses your ";
    let items = [];

    if (props.uses_backgrounds) {
      items.push("backgrounds");
    }
    if (props.uses_characters) {
      items.push("characters");
    }
    if (props.uses_monsters) {
      items.push("monsters");
    }
    if (props.uses_projectiles) {
      items.push("projectiles");
    }
    if (items.length > 2) {
      items = ["images"];
    }
    if (props.uses_words) {
      sentence += "words";
      if (items.length) {
        sentence += (items.length > 1 ? ", " + items.slice(0, 1).join(", ") : "") + " and " + items[items.length - 1];
      }
    } else if (items.length) {
      sentence += (items.length > 1 ? items.slice(0, 1).join(", ") + " and " : "") + items[items.length - 1];
    } else {
      sentence = "Is a standalone game";
    }
    return sentence + ".";
  };

  const userLacksPlan = () => {
    return (props.needs_premium && (props.user_plan === null || props.user_plan === "PRO")) || (props.needs_pro && props.user_plan === null);
  };

  const buttonText = () => {
    if (props.needs_premium && !state.used_premium_plan_trial) {
      return "Start Trial Now";
    }
    if (props.needs_pro && (!state.used_pro_plan_trial || !state.used_premium_plan_trial)) {
      return "Start Trial Now";
    }
    return "Get One Now";
  };

  return (
    <>
      <div
        id="main-section"
        className="w-full relative md:w-[400px] lg:w-[500px] overflow-hidden h-fit rounded-lg duration-300 shadow-sm shadow-primary-500 dark:shadow-primary-900 bg-slate-100 dark:bg-slate-900  outline-primary-300 dark:outline-primary-800 hover:outline hover:cursor-pointer"
      >
        <Link to={userLacksPlan() ? "/plans" : "/games/" + makeUrlFriendly(props.name)}>
          <h3 className="title text-4xl m-2 my-4 text-center dark:text-primary-200">{props.name}</h3>
        </Link>
        <Link to={userLacksPlan() ? "/plans" : "/games/" + makeUrlFriendly(props.name)}>
          <div className="relative w-full h-full">
            <img className="object-cover w-full h-[300px]" alt={props.internal_name} src={image_url}></img>
            {userLacksPlan() ? (
              <div className="absolute w-full h-full bg-black/40 top-0 flex justify-center items-center flex-col gap-4">
                <Lock className="w-20 h-20" />
                {props.needs_premium ? (
                  <h4 className="text-white font-bold">
                    You need a <span className="text-teal-300">Premium Plan</span> to play this game!
                  </h4>
                ) : (
                  <>
                    {props.needs_pro ? (
                      <h4 className="text-white font-bold">
                        You need a <span className="text-blue-300">Pro Plan</span> to play this game!
                      </h4>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <div className="px-4 py-2.5 select-none rounded-md bg-primary-600 hover:bg-primary-500 cursor-pointer duration-100 ease-in-out text-white uppercase ">{buttonText()}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Link>
        <div className="bottom-bar w-full flex p-2 place-content-between">
          <p className="text-sm select-none text-primary-400 dark:text-primary-200 font-thin">• {getInfo()}</p>
          <a
            id="how-to"
            href={"/how-to#" + makeUrlFriendly(props.name)}
            className="text-sm select-none text-primary-400 dark:text-primary-200 font-thin flex gap-2 items-center hover:outline outline-1 rounded-sm hover:text-shadow text-shadow-red"
          >
            How to play?
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </a>
        </div>
        {props.needs_premium ? <div className="absolute top-0 right-0 bg-blue-200  px-2 py-1 rounded-bl-md text-blue-900 font-mono">BETA</div> : <></>}
      </div>
    </>
  );
}
