import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useHover } from "src/utils/functions";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import { ImageInterface, ImageType, UserImageAttr } from "./ImagePicker";
import BlackBgBackdrop from "../basic/BlackBgBackdrop";
import FullScreenImageView from "./FullScreenImageView";

interface props {
  image: ImageInterface;
  contain?: Boolean | false;
  type: ImageType;
  is_public: Boolean;
  is_basic?: Boolean | false;
  onDelete?: Function;
  onClick?: Function;
  selected?: Boolean;
  forceReset?: Function;
}

export default function GalleryImage(props: props) {
  const { state, dispatch } = useContext(UserContext);
  const [imageHovered, imageHoverProps] = useHover();

  const [fakeIsLiked, setFakeIsLiked] = useState<boolean>(props.image.is_liked || false);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  useEffect(() => {
    setFakeIsLiked(props.image.is_liked || false);
  }, [props.image.is_liked]);
  const convertToUserAttr = (type: ImageType = "MONSTERS") => {
    return ("favourite_" + type.toLowerCase()) as UserImageAttr;
  };
  const handleFavouriteClick = () => {
    if (loading) return;
    if (state.isAuthenticated) {
      setLoading(true);
      if (props.image.is_liked) {
        if (state[convertToUserAttr(props.type)].length === 1) {
          return;
        }
        setFakeIsLiked(false);
        axios
          .post("/api/images/" + props.type.toLowerCase() + "/" + props.image.id + "/unfavourite/")
          .then((res) => {
            if (props.image.created_by.id !== state.id) {
              setFullScreenMode(false);
            }
            dispatch({
              type: AuthTypes.UnfavouriteImage,
              payload: {
                image_type: convertToUserAttr(props.type),
                image: props.image,
              },
            });
            setLoading(false);
          })
          .catch((err) => {});
      } else {
        setFakeIsLiked(true);
        axios
          .post("/api/images/" + props.type.toLowerCase() + "/" + props.image.id + "/favourite/")
          .then((res) => {
            dispatch({
              type: AuthTypes.FavouriteImage,
              payload: {
                image: props.image,
                image_type: convertToUserAttr(props.type),
              },
            });
            setLoading(false);
          })
          .catch((err) => {});
      }
    }
  };

  const closeFullscreen = () => {
    setFullScreenMode(false);
  };

  return (
    <>
      <div
        {...imageHoverProps}
        onClick={(ev) => {
          const target = ev.target as HTMLDivElement;
          if (target.getAttribute("id") !== "star" && target.getAttribute("id") !== "refresh" && !props.is_basic) {
            setFullScreenMode(true);
            ev.stopPropagation();
            ev.preventDefault();
          } else if (props.onClick) {
            props.onClick();
          }
        }}
        className="cursor-pointer  w-full h-full max-w-[100px] max-h-[100px] md:max-w-[150px] md:max-h-[150px] object-cover overflow-hidden relative  aspect-square border-[1px] rounded-lg hover:bg-primary-500/50 dark:hover:bg-primary-800/50 duration-100 ease-in-out border-primary-900"
      >
        <img
          onLoad={() => setImageLoading(false)}
          src={props.image.thumbnail}
          alt=""
          className={"w-full pointer-events-none h-full top-0 absolute " + (props.contain ? "object-contain " : "object-cover ") + (imageLoading ? "hidden" : "")}
        />
        <div className={"w-full h-full flex justify-center items-center top-0 absolute " + (imageLoading ? "" : "hidden")}>
          <svg className="w-[50%] h-[50%] text-primary-600 dark:text-primary-800" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z">
              <animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" />
            </path>
          </svg>
        </div>

        <div
          className={
            "nametag bg-primary-400 text-primary-1000 dark:bg-primary-1100 dark:text-primary-500 font-thin absolute bottom-0 right-0 p-1 rounded capitalize " +
            (imageHovered ? "" : "hidden ") +
            (props.is_basic ? "text-xs" : "text-xs md:text-md lg:text-base ")
          }
        >
          {props.image.name}
        </div>
        {props.is_basic ? (
          <>
            {props.selected ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 absolute top-0 left-0 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <svg
              onClick={() => {
                handleFavouriteClick();
              }}
              xmlns="http://www.w3.org/2000/svg"
              className={"h-6 w-6 md:h-10 md:w-10 absolute cursor-pointer text-yellow-200 dark:text-yellow-600 duration-100 ease-in-out " + (fakeIsLiked ? "fill-yellow-500" : "fill-transparent hover:fill-yellow-300/30")}
              viewBox="0 0 20 20"
              stroke="#000000"
              strokeWidth={1}
            >
              <path
                id="star"
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            {state.is_superuser ? (
              <svg
                id="refresh"
                onClick={() => {
                  axios.patch("/api/images/" + props.type.toLowerCase() + "/" + props.image.id + "/reload_thumbnail/");
                }}
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 absolute right-0 top-0 text-blue-500 hover:text-blue-300 hover:cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      {fullScreenMode ? (
        <BlackBgBackdrop onClose={() => [closeFullscreen()]}>
          <FullScreenImageView
            image={props.image}
            type={props.type}
            is_public={props.is_public}
            onClose={() => [closeFullscreen()]}
            onFavourite={() => {
              handleFavouriteClick();
            }}
            onDelete={() => {
              closeFullscreen();
            }}
            forceReset={() => {
              //forces the useeffect hook to trigger again and rerender all the images, usually from cache.
              if (props.forceReset) {
                props.forceReset();
              }
            }}
            fakeIsLiked={fakeIsLiked}
          ></FullScreenImageView>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
    </>
  );
}
