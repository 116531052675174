import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FrontEndErrorKey } from "src/utils/ErrorTypes";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import { GAME_DATA } from "../dashboard/gamedata";
//same as backend
//TODO add this to an env file
const AVAILABLE_GAMES = [
  "none",
  "wordsearch",
  "fighters",
  "openthesafe",
  "wordbrain",
  "hangmanbattle",
  "prepositions",
  "numberline",
  "fightersbasic",
  "timetrial",
  "spottheword",
  "alienchase",
  "alienchasebasic",
  "ufoblockade",
  "monstermash",
  "twister",
  "alienblaster",
  "fourinarow",
  "characterwheel",
  "numberwheel",
  "wordwheel",
  "spacefight",
  "oneatatime",
];

export default function GamesLoader() {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const gameFrame = useRef<HTMLIFrameElement>(null);
  const [loadedFullscreen, setloadedFullscreen] = useState(false);
  const navigate = useNavigate();
  const [cachedSrc, setCachedSrc] = useState("");
  const [cachedKey, setCachedKey] = useState({
    user_token: "",
    session_id: "",
  });
  const { state, dispatch } = useContext(UserContext);
  const [showButtons, setShowButtons] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const logout = () => {
    dispatch({ type: AuthTypes.LogOut });
    navigate("/login", {
      state: FrontEndErrorKey.max_sessions_reached,
    });
  };
  const openFullScreen = () => {
    if (gameFrame && gameFrame.current) {
      gameFrame.current.requestFullscreen().then(() => {
        if (!loadedFullscreen && gameFrame && gameFrame.current) {
          //resizing the screen makes it dumb
          setloadedFullscreen(true);
          gameFrame.current.src = cachedSrc;
        }
        if (gameFrame.current && gameFrame.current.contentWindow) {
          gameFrame.current.addEventListener("load", () => {
            if (gameFrame.current && gameFrame.current.contentWindow) {
              if (cachedKey.session_id !== "") {
                gameFrame.current.contentWindow.postMessage({ abc: cachedKey.session_id, def: cachedKey.user_token }, "*");
              } else {
                axios
                  .get("/api/games/session_key/")
                  .then((session_key) => {
                    setCachedKey(session_key.data);
                    if (gameFrame.current && gameFrame.current.contentWindow) {
                      gameFrame.current.contentWindow.postMessage({ abc: session_key.data.session_id, def: session_key.data.user_token }, "*");
                    }
                  })
                  .catch(() => {
                    logout();
                  });
              }
            }
          });
        }
      });
      window.screen.orientation.lock("landscape");
    }
  };
  useEffect(() => {
    if (gameFrame.current && gameFrame.current.contentWindow) {
      gameFrame.current.addEventListener("load", () => {
        if (gameFrame.current && gameFrame.current.contentWindow) {
          if (cachedKey.session_id !== "") {
            gameFrame.current.contentWindow.postMessage({ abc: cachedKey.session_id, def: cachedKey.user_token }, "*");
          } else {
            axios
              .get("/api/games/session_key/")
              .then((session_key) => {
                localStorage.setItem("played_game", "true");

                setCachedKey(session_key.data);
                if (gameFrame.current && gameFrame.current.contentWindow) {
                  gameFrame.current.contentWindow.postMessage({ abc: session_key.data.session_id, def: session_key.data.user_token }, "*");
                }
              })
              .catch(() => {
                logout();
              });
          }
        }
      });
    }
  }, [cachedSrc]);

  useEffect(() => {
    document.title = "Games | whatstheword.io";
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          logout();
        });
      return;
    }
    if (state.isAuthenticated && !state.has_valid_email) {
      navigate("/activate/reminder");
      return;
    }
    if (!AVAILABLE_GAMES.includes(params.game || "")) {
      navigate("/");
      return;
    }
    document.title = (GAME_DATA[AVAILABLE_GAMES.indexOf(params.game || "")] ? GAME_DATA[AVAILABLE_GAMES.indexOf(params.game || "")].name : "Games") + " | whatstheword.io";
    Promise.all([
      new Promise((resolve) => {
        axios
          .get("/api/games/session_key/")
          .then((session_key) => {
            setCachedKey(session_key.data);
            resolve(null);
          })
          .catch(() => {
            logout();
          });
      }),
      new Promise((resolve) => {
        let url = "/api/games/" + AVAILABLE_GAMES.indexOf(params.game || "") + "/template/";
        let local_list = localStorage.getItem(state.id + "_swl");
        if (local_list !== null) {
          url += "?wl=" + local_list;
        }
        axios
          .get(url)
          .then((res) => {
            if (gameFrame.current) {
              // the key must be changed of the iframe at the same time as the src, therefor the iframe is reloaded, and has the new src, meaning it doesn't trigger a new entry to history
              setCachedSrc("data:text/html;charset=utf-8," + escape(res.data));
            }
            resolve(null);
          })
          .catch(() => {
            logout();
          });
      }),
    ])
      .then(() => {
        if (window.innerHeight > window.innerWidth) {
          setShowButtons(true);
        } else {
          setLoading(false);
        }
        window.addEventListener("message", (ev) => {
          if (ev.origin === "null" && ev.data === "loadplz") {
            // console.log("game asks for load");
            if (gameFrame.current && gameFrame.current.contentWindow) {
              if (localStorage.getItem(state.username + params.game + "_savedata")) {
                gameFrame.current.contentWindow.postMessage(JSON.parse(localStorage.getItem(state.username + params.game + "_savedata") || "{}"), "*");
              }
            }
          } else if (ev.origin === "null" && ev.data.history) {
            localStorage.setItem(state.username + params.game + "_savedata", JSON.stringify(ev.data));
          }
        });
      })
      .catch(() => {
        logout();
      });
  }, [params.game, navigate, dispatch, state.isAuthenticated]);

  return (
    <div className="w-full h-full flex justify-center items-center bg-stone-50 dark:bg-slate-900">
      {loading && !showButtons ? (
        <div className="loading w-full h-full flex justify-center items-center dark:bg-slate-900 text-primary-700 dark:text-primary-300">
          <h2 className="font-bold text-xl ">LOADING...</h2>
        </div>
      ) : (
        <></>
      )}
      {showButtons && loading ? (
        <div className="flex justify-center items-center flex-col">
          <h5 className="font-thin text-primary-1100 dark:text-white">You seem to be in portrait mode.</h5>
          <button
            type="submit"
            disabled={buttonDisabled}
            onClick={() => {
              if (gameFrame && gameFrame.current) {
                gameFrame.current.src = cachedSrc;
              }
              setLoading(false);
            }}
            className="text-white uppercase font-thin mt-10 disabled:bg-gray-500 disabled:text-gray-200 disabled:pointer-events-none focus:ring-1 rounded-md text-md lg:text-lg xl:text-xl w-full sm:w-auto px-5 py-1.5 text-center self-center justify-self-center bg-primary-700 hover:bg-primary-600 focus:ring-primary-300 focus:outline-none"
          >
            use small screen
          </button>
          <button
            type="submit"
            disabled={buttonDisabled}
            onClick={() => {
              openFullScreen();
              setLoading(false);
            }}
            className="text-white uppercase font-thin mt-10 disabled:bg-gray-500 disabled:text-gray-200 disabled:pointer-events-none focus:ring-1 rounded-md text-md lg:text-lg xl:text-xl w-full sm:w-auto px-5 py-1.5 text-center self-center justify-self-center bg-primary-700 hover:bg-primary-600 focus:ring-primary-300 focus:outline-none"
          >
            play fullscreen
          </button>
        </div>
      ) : (
        <></>
      )}
      <iframe
        key={cachedSrc.slice(0, 10)}
        src={cachedSrc}
        allow-same-origin={"true"}
        allow-storage-access-by-user-activation={"true"}
        allowFullScreen={true}
        allow="fullscreen"
        className={loading ? "hidden" : "w-full h-full"}
        ref={gameFrame}
        title={params.game}
      ></iframe>
      {!showButtons && !loading ? (
        <button
          className="fixed bottom-0 right-0 px-5 py-2.5 rounded-lg bg-stone-50 dark:bg-slate-900 text-teal-700 dark:text-teal-300"
          onClick={() => {
            openFullScreen();
          }}
        >
          Go Fullscreen
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
