import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthTypes, User, UserContext } from "src/utils/UserContext";
import CodeInput from "../basic/CodeInput";
import { InputGroup } from "../basic/InputGroup";

enum pageStatus {
  CONFIRM_RESET,
  EMAIL_SENT_NEED_CODE,
  PASSWORD_INPUT,
  SOMETHING_WENT_WRONG,
  ALL_SET,
}
enum codeStatus {
  NOTHING,
  INVALID,
  LOADING,
  VALID,
}

export default function ChangePassword() {
  const { state, dispatch } = useContext(UserContext);
  const [status, setStatus] = useState(pageStatus.CONFIRM_RESET);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [currentCodeStatus, setCurrentCodeStatus] = useState(codeStatus.NOTHING);
  const [validatingCode, setValidatingCode] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState<{ errors: string[]; invalid: boolean }>({
    errors: [],
    invalid: false,
  });
  const [passwordErrors, setPasswordErrors] = useState<{
    passwordErrors: string[];
    passwordInvalid: boolean;
    confirmErrors: string[];
    confirmInvalid: boolean;
  }>({
    passwordErrors: [],
    passwordInvalid: false,
    confirmErrors: [],
    confirmInvalid: false,
  });
  const [passwordValues, setPasswordValues] = useState<{
    password: string;
    confirm: string;
  }>({ password: "", confirm: "" });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sendEmail = () => {
    let data = {
      name: "",
      reason: "password",
    };
    if (state.isAuthenticated) {
      data.name = state.username as string;
      setEmail(state.username as string);
    } else {
      if (!email || email === "") {
        setEmailErrors({ errors: ["Please fill out this field."], invalid: true });
        return;
      } else {
        data.name = email;
      }
    }
    setStatus(pageStatus.EMAIL_SENT_NEED_CODE);
    axios
      .post("/api/get_token/", data)
      .then((res) => {})
      .catch((err) => {
        setStatus(pageStatus.SOMETHING_WENT_WRONG);
      });
  };

  const changePassword = () => {
    setButtonDisabled(true);
    setPasswordErrors({ passwordErrors: [], passwordInvalid: false, confirmErrors: [], confirmInvalid: false });
    let hasErrors = false;
    if (passwordValues.confirm !== passwordValues.password) {
      setPasswordErrors((prev) => ({ ...prev, confirmErrors: ["The passwords dont match."], passwordInvalid: true, confirmInvalid: true }));
      hasErrors = true;
    }
    if (passwordValues.password === "") {
      setPasswordErrors((prev) => ({ ...prev, passwordErrors: ["Please type in a password."], passwordInvalid: true }));
      hasErrors = true;
    }

    if (passwordValues.confirm === "") {
      setPasswordErrors((prev) => ({ ...prev, confirmErrors: ["Please type in the password again."], passwordInvalid: true }));
      hasErrors = true;
    }

    if (hasErrors) {
      setButtonDisabled(false);
      return;
    }

    axios
      .post("/api/users/reset_password/", {
        name: email,
        code: confirmationCode,
        password: passwordValues.password,
        reason: "password",
      })
      .then((res) => {
        setStatus(pageStatus.ALL_SET);
        dispatch({ type: AuthTypes.LogOut });
        dispatch({ type: AuthTypes.UpdateUserData, payload: {} as User });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          let errors: string[] = [];
          for (let key in err.response.data) {
            let errors: string[] = [];
            if (typeof err.response.data[key] === "string") {
              errors = [err.response.data[key]];
            } else if (err.response.data[key] instanceof Array) {
              errors = err.response.data[key];
            } else if (typeof err.response.data[key] === "object" && !(err.response.data[key] instanceof Array)) {
              //has a special case, will tell which one is wrong
              //should add this later
              errors = err.response.data[key][Object.keys(err.response.data[key])[0]];
            } else {
              errors = err.response.data[key];
            }
          }
          setPasswordErrors((prev) => ({ ...prev, passwordErrors: errors, passwordInvalid: true, confirmInvalid: true }));
        }
        setButtonDisabled(false);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem("loggedInBefore")) {
      return;
    }
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          return;
        });
    }
  }, []);

  useEffect(() => {
    if (validatingCode) {
      return;
    }
    if (confirmationCode.replace(" ", "").length === 6) {
      setCurrentCodeStatus(codeStatus.LOADING);
      setValidatingCode(true);
      axios
        .post("/api/validate_token/", {
          code: confirmationCode,
          name: email,
          reason: "password",
        })
        .then((res) => {
          setCurrentCodeStatus(codeStatus.VALID);
          setTimeout(() => {
            setStatus(pageStatus.PASSWORD_INPUT);
          }, 1000);
        })
        .catch((err) => {
          setCurrentCodeStatus(codeStatus.INVALID);
          setValidatingCode(false);
        });
    } else {
      setCurrentCodeStatus(codeStatus.NOTHING);
    }
  }, [confirmationCode]);

  const getCodeIcon = () => {
    switch (currentCodeStatus) {
      case codeStatus.NOTHING:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
      case codeStatus.LOADING:
        return (
          <svg className="w-6 h-6 text-primary-600 dark:text-primary-800" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z">
              <animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" />
            </path>
          </svg>
        );
      case codeStatus.VALID:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-emerald-700 dark:text-emerald-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
      case codeStatus.INVALID:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-600 dark:text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
    }
  };

  const getItemsToShow = () => {
    switch (status) {
      case pageStatus.CONFIRM_RESET:
        return (
          <>
            <h4 className="text-primary-900 text-center dark:text-primary-200 font-bold text-lg mb-3">Forgot your password or just want to change it?</h4>
            <p className="text-primary-900 text-center dark:text-primary-200">
              If you came here by mistake, just head on back. But if you'd like to change your password, click the button below to send an email to receive a confirmation code.
            </p>
            <form className="m-5 w-full flex justify-center items-center flex-col">
              {!state.isAuthenticated ? (
                <>
                  <p className="text-sm text-primary-900 text-center dark:text-primary-200">Please enter your username or email below.</p>
                  <InputGroup
                    type="text"
                    name="username"
                    label="Email or Username"
                    submitted={false}
                    required={true}
                    placeholder=" "
                    value={email}
                    onChange={(ev) => {
                      setEmailErrors({ errors: [], invalid: false });
                      setEmail(ev.target.value);
                    }}
                    {...emailErrors}
                  ></InputGroup>
                </>
              ) : (
                <>
                  <p className="text-sm text-primary-900 text-center dark:text-primary-200">You are currently logged in as {state.display_name || state.username}</p>
                </>
              )}
              <button
                onClick={(ev) => {
                  ev.preventDefault();
                  sendEmail();
                }}
                className={
                  "text-white flex gap-2 justify-center items-center font-thin mt-10  focus:ring-4  rounded-md text-md lg:text-lg xl:text-xl w-full sm:w-auto px-5 py-1.5 text-center self-center justify-self-center " +
                  (buttonDisabled ? "bg-gray-500 text-gray-200 pointer-events-none" : "bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 focus:outline-none")
                }
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                Send Confirmation Email
              </button>
            </form>
          </>
        );
      case pageStatus.EMAIL_SENT_NEED_CODE:
        return (
          <>
            <h4 className="text-primary-900 text-center dark:text-primary-200 font-bold text-lg mb-3">Email Sent!</h4>
            <p className="text-primary-900 text-center dark:text-primary-200">If you changed your mind, ignore the email. If not, enter the code we sent you below.</p>
            <div className="flex justify-center items-center">
              <>
                <CodeInput onChange={setConfirmationCode}></CodeInput>
                {getCodeIcon()}
              </>
            </div>
          </>
        );
      case pageStatus.SOMETHING_WENT_WRONG:
        return (
          <>
            <h4 className="text-primary-900 text-center dark:text-primary-200 font-bold text-lg mb-3">Oh no!</h4>
            <p className="text-primary-900 text-center dark:text-primary-200">There was an issue sending the request. Try refreshing the page.</p>
          </>
        );
      case pageStatus.PASSWORD_INPUT:
        return (
          <>
            <h4 className="text-primary-900 text-center dark:text-primary-200 font-bold text-lg mb-3">Type a New Password!</h4>
            <p className="text-primary-900 text-center dark:text-primary-200">Give yourself a new password. Click the submit button to change it.</p>
            <form className="m-5 w-full flex justify-center items-center flex-col">
              <InputGroup
                className="m-2 mb-2 max-w-[400px]"
                type="password"
                name="password"
                label="Password"
                submitted={false}
                required={true}
                placeholder=" "
                value={passwordValues.password}
                errors={passwordErrors.passwordErrors}
                invalid={passwordErrors.passwordInvalid}
                onChange={(ev) => {
                  setPasswordErrors({ passwordErrors: [], passwordInvalid: false, confirmErrors: [], confirmInvalid: false });
                  setPasswordValues((prev) => ({ ...prev, password: ev.target.value }));
                }}
              ></InputGroup>
              <InputGroup
                className="m-2 mb-2 max-w-[400px]"
                type="password"
                name="confirmpassword"
                label="Confirm Password"
                submitted={false}
                required={true}
                placeholder=" "
                value={passwordValues.confirm}
                errors={passwordErrors.confirmErrors}
                invalid={passwordErrors.confirmInvalid}
                onChange={(ev) => {
                  setPasswordErrors({ passwordErrors: [], passwordInvalid: false, confirmErrors: [], confirmInvalid: false });
                  setPasswordValues((prev) => ({ ...prev, confirm: ev.target.value }));
                }}
              ></InputGroup>
              <button
                onClick={(ev) => {
                  ev.preventDefault();
                  changePassword();
                }}
                className={
                  "text-white font-thin mt-10  focus:ring-4  rounded-md text-md lg:text-lg xl:text-xl w-full sm:w-auto px-5 py-1.5 text-center self-center justify-self-center " +
                  (buttonDisabled ? "bg-gray-500 text-gray-200 pointer-events-none" : "bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 focus:outline-none")
                }
              >
                Submit
              </button>
            </form>
          </>
        );
      case pageStatus.ALL_SET:
        return (
          <>
            <h4 className="text-primary-900 text-center dark:text-primary-200 font-bold text-lg mb-3">You're all set!</h4>
            <p className="text-primary-900 text-center dark:text-primary-200">Please log in with your new password!</p>
          </>
        );
    }
  };

  return (
    <div className="w-full h-full bg-slate-50 dark:bg-slate-900 flex justify-center items-center">
      <div className="rounded-lg bg-slate-200 dark:bg-slate-700 p-5 m-5 max-w-[500px] flex flex-col justify-center items-center gap-4">{getItemsToShow()}</div>
    </div>
  );
}
