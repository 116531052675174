import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";

interface props {
  type: "PRO" | "PREMIUM";
  used: boolean;
}

enum processState {
  ClickMe,
  Processing,
  Activating,
  Success,
}

export default function StartTrialBox(props: props) {
  const [errors, setErrors] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [currentState, setCurrentState] = useState(processState.ClickMe);
  const navigate = useNavigate();
  const getText = () => {
    switch (currentState) {
      case processState.ClickMe:
        return (
          <>
            <h4 className={"font-medium text-xl lg:text-3xl text-center dark:text-white " + (props.used ? "opacity-10" : "")}>
              Start a <span className={props.type === "PRO" ? "text-blue-500 dark:text-blue-300" : "text-teal-500 dark:text-teal-300"}>{props.type.toLowerCase()}</span> trial now!
            </h4>
          </>
        );
      case processState.Processing:
        return <h4 className={"font-medium text-xl lg:text-3xl text-center dark:text-white " + (props.used ? "opacity-10" : "")}>Processing...</h4>;
      case processState.Activating:
        return <h4 className={"font-medium text-xl lg:text-3xl text-center dark:text-white " + (props.used ? "opacity-10" : "")}>Activating...</h4>;
      case processState.Success:
        return <h4 className={"font-medium text-xl lg:text-3xl text-center text-emerald-700 dark:text-emerald-300 " + (props.used ? "opacity-10" : "")}>Success!</h4>;
    }
  };

  const startTrial = () => {
    setErrors([]);
    if (!state.isAuthenticated) {
      navigate("/login");
    }
    if (currentState === processState.Success) {
      navigate("/");
      return;
    }
    setButtonDisabled(true);

    if (state.has_active_plan) {
      if (!window.confirm("You currently have an active plan. Would you like to pause it and use this trial for 7 days? Your original plan will resume automatically once the trial expires. (Trial plans won't resume)")) {
        setButtonDisabled(false);
        return;
      }
    }

    axios.get("/api/users/get_csrf/").then(() => {
      setCurrentState(processState.Processing);
      axios
        .post("/api/payments/start-trial/", { type: props.type })
        .then((res) => {
          setCurrentState(processState.Activating);
          if (res.status === 201) {
            axios.get("/api/users/me/").then((userdatares) => {
              setButtonDisabled(false);

              setCurrentState(processState.Success);
              dispatch({ type: AuthTypes.UpdateUserData, payload: userdatares.data });
              navigate("/");
            });
          }
        })
        .catch((err) => {
          setButtonDisabled(false);

          setCurrentState(processState.ClickMe);
          if (err && err.response && err.response.data) {
            for (let key in err.response.data) {
              let errors: string[] = [];
              if (typeof err.response.data[key] === "string") {
                errors = [err.response.data[key]];
              } else {
                errors = err.response.data[key];
              }
              setErrors(errors);
            }
          }
        });
    });
  };

  return (
    <div className={"shadow-sm shadow-primary-500 dark:shadow-primary-900 rounded-lg flex-1 flex flex-row overflow-hidden relative " + (props.used ? "bg-grey-200/50 dark:bg-gray-600/40" : "bg-white dark:bg-slate-700 ")}>
      {props.used ? <div className="bg-teal-900 opacity-90 z-10 absolute top-[3ch] right-[-3ch] rotate-45 w-[15ch] text-center h-fit text-white font-medium text-sm">Used</div> : <></>}
      <div className="flex flex-1 flex-col justify-center">
        <div className="title-box w-full flex-col flex p-4 justify-center items-center mt-3">{getText()}</div>
        <div className="button-container flex justify-center items-end pb-5 px-3 flex-grow">
          <button
            onClick={() => {
              startTrial();
            }}
            className={
              " p-3 rounded-lg px-8 lg:text-xl font-medium text-white  duration-100 ease-in-out " +
              (buttonDisabled ? "bg-gray pointer-events-none " : " bg-primary-700 hover:bg-primary-500 ") +
              (props.used ? " opacity-10 pointer-events-none" : "")
            }
          >
            {currentState === processState.Success ? <>PLAY GAMES</> : <>START {props.type} TRIAL</>}
          </button>
        </div>

        <div className="flex flex-col justify-center w-full pl-5">
          {errors.map((err, i) => {
            return (
              <p key={i} className="text-red-500 dark:text-red-200 text-sm font-thin mt-1 before:content-['•_'] mb-4">
                {err.trim() === "" ? "Something went wrong. Please try again later." : err}
              </p>
            );
          })}
        </div>
      </div>
      <div className={"flex z-1 flex-0 border-l-[1px] flex-col max-w-[40%] border-gray-300 dark:border-gray-600 p-5 " + (props.used ? "opacity-30" : "")}>
        <p className="text-primary-1200 dark:text-primary-200 pl-2 text-xs">• try before you buy!</p>
        <p className="text-primary-1200 dark:text-primary-200 pl-2 text-xs">• 7-day free trial</p>
        <p className="text-primary-1200 dark:text-primary-200 pl-2 text-xs">• no credit card required</p>
        <p className="text-primary-1200 dark:text-primary-200 pl-2 text-xs">• If you have an active plan, it will be paused, and resumed when the trial expires.</p>
        <p className="text-primary-1200 dark:text-primary-200 pl-2 text-xs">• get all the features of the full plan</p>
      </div>
    </div>
  );
}
