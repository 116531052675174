type AppProps = {
  className?: string;
};

export default function ScrollImage(props: AppProps) {
  return (
    <div className={props.className + " overflow-hidden clip-path-1 relative lg:rounded-[10rem] m-6"}>
      <div className="bg-scroll-image bg-contain w-[10833px] h-full animate-slide bg-repeat-x"></div>
      <div className="absolute bg-black/60  w-full h-full left-0 top-0 backdrop-blur-sm flex flex-col items-center justify-center text-center">
        <h3 className="text-white font-thin text-4xl mt-[-4rem]">SO MANY GAMES</h3>
        <h3 className="text-white font-thin text-3xl mt-5">Tons of Fun!</h3>
      </div>
    </div>
  );
}
