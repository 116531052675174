import React, { createRef, useRef } from "react";

interface props {
  onChange: Function;
}

export default function CodeInput(props: props) {
  const inputs = [0, 1, 2, 3, 4, 5];
  const inputsRef = useRef(inputs.map(() => createRef<HTMLInputElement>()));

  const updateCode = () => {
    let newCode = "";
    if (inputsRef.current) {
      inputsRef.current.forEach((ref) => {
        if (ref.current) {
          newCode += ref.current.value || " ";
        }
      });
    }
    props.onChange(newCode);
  };
  return (
    <div>
      {inputs.map((id) => {
        return (
          <input
            key={id}
            ref={inputsRef.current[id]}
            className="w-[2ch] mx-1 bg-transparent border-b-2 border-primary-900 dark:border-primary-200 focus:ring-0 focus:outline-none text-primary-1100 dark:text-primary-200 text-lg text-center"
            type="text"
            maxLength={1}
            onPaste={(ev) => {
              ev.preventDefault();
              let codes = ev.clipboardData.getData("text").split("");
              if (inputsRef.current) {
                inputsRef.current.forEach((ref, i) => {
                  if (ref.current) {
                    ref.current.value = codes[i] || "";
                  }
                });
              }
              updateCode();
            }}
            onChange={(ev) => {
              const event = ev.nativeEvent as InputEvent;
              if (["deleteContentForward", "deleteContentBackward", "deleteContent"].includes(event.inputType)) {
                if (inputsRef && inputsRef.current[id - 1].current && inputsRef!.current[id]!.current!.value === "" && inputsRef.current[id - 1].current?.focus) {
                  inputsRef!.current[id - 1]!.current!.focus();
                }
              } else {
                if (inputsRef && inputsRef.current[id + 1] && inputsRef.current[id + 1].current && inputsRef.current[id + 1].current?.focus) {
                  inputsRef!.current[id + 1]!.current!.focus();
                }
              }
              updateCode();
            }}
          />
        );
      })}
    </div>
  );
}
