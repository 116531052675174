import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer md:min-h-[16rem] pb-10 sm:pb-2 bg-slate-100 dark:bg-slate-700 w-full flex flex-col md:flex-row p-2 items-stretch gap-2">
      <div className="rounded-lg w-full flex-1 bg-slate-50 dark:bg-slate-600 grid text-center flex-col p-2 mb-5 md:mb-0">
        <h4 className="font-thin text-2xl text-center align-self-start m-0  dark:text-white mb-3">Contact Us</h4>
        <p className="font-thin text-primary-1200 dark:text-primary-300 justify-self-center p-2">
          Got questions? Have concerns? Email us at{" "}
          <a href="mailto:staff@whatstheword.io" className="font-medium text-blue-600 dark:text-blue-300 underline">
            staff@whatstheword.io
          </a>
          ! Or fill out{" "}
          <Link to="/contact-us" className="font-medium text-blue-600 dark:text-blue-300 underline">
            this contact form
          </Link>
          . We will get back to you as soon as we can!
        </p>
      </div>
      <div className="rounded-lg w-full flex-1 bg-slate-50 dark:bg-slate-600 grid text-center flex-col p-2 mb-5 md:mb-0">
        <h4 className="font-thin text-2xl text-center align-self-start mb-3 dark:text-white ">About Us</h4>
        <p className="font-thin text-primary-1200 dark:text-primary-300 justify-self-center p-2">
          We are a team of ESL teachers who specialize in teaching kindergarten and primary school children. These are the games we have been using for several years to have a fun class that gets new students every month and always has
          positive feedback.
        </p>
      </div>
      <div className="rounded-lg w-full flex-1 bg-slate-50 dark:bg-slate-600 grid text-center flex-col p-2 mb-5 md:mb-0">
        <h4 className="font-thin text-2xl text-center align-self-start mb-3 dark:text-white ">Legal Stuff</h4>
        <p className="font-thin text-primary-1200 dark:text-primary-300 justify-self-center p-2">
          You can find a copy of our Terms and Conditions{" "}
          <Link to="/terms" className="font-medium text-blue-600 dark:text-blue-300  underline">
            here
          </Link>
          .
        </p>
        <p className="text-primary-700 dark:text-primary-300 font-thin text-center">whatstheword.io © 2023</p>
      </div>
    </footer>
  );
}
