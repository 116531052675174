import axios from "axios";
import { useContext, useState } from "react";
import { InputType } from "src/utils/InputTypes";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import Button from "../basic/Button";
import ColorPicker from "../basic/ColorPicker";
import { InputGroup } from "../basic/InputGroup";
import PowerSelector from "../basic/PowerSelector";
import ImageDropper from "./ImageDropper";
import { ImageInterface, UserImageAttr } from "./ImagePicker";
import TagInput from "./TagInput";

export type styledImageType = "Monster" | "Character" | "Projectile" | "Background";
interface props {
  type: styledImageType;
  onClose: Function;
}

export default function CreateImageForm(props: props) {
  const { dispatch } = useContext(UserContext);
  const [nameInput, setNameInput] = useState<InputType>({ value: "", placeholder: " ", errors: [], invalid: false, label: props.type + " Name", type: "text", required: true, name: "character name", submitted: false });
  const [selectedPowers, setSelectedPowers] = useState<ImageInterface[]>([]);
  const [color, setColor] = useState("ran"); //ran is random
  const [isChecked, setIsChecked] = useState(false);
  const [image, setImage] = useState<Blob | null>(null);
  const [tags, setTags] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{
    image: string[];
    color: string[];
    powers: string[];
  }>({
    image: [],
    color: [],
    powers: [],
  });

  const convertToUserAttr = (type: styledImageType) => {
    return ("favourite_" + type.toLowerCase() + "s") as UserImageAttr;
  };
  const handleSubmit = () => {
    setErrors({
      image: [],
      color: [],
      powers: [],
    });
    setNameInput((prev) => ({ ...prev, errors: [], invalid: false }));
    if (nameInput.value === "") {
      setNameInput((prev) => ({ ...prev, errors: ["You must include a name"], invalid: true }));
      return;
    }
    if (!nameInput.value?.match(/^[a-zA-Z0-9\s]+$/)) {
      setNameInput((prev) => ({ ...prev, errors: ["The name should only contain lettters, numbers, or spaces"], invalid: true }));
      return;
    }
    if (image === null) {
      setErrors((prev) => ({ ...prev, image: ["You need to include an image."] }));
    }
    const file = new File([image!], nameInput.value?.toLowerCase().replace(" ", "_") + ".png", {
      type: "image/png",
    });
    let fd = new FormData();
    fd.set("name", nameInput.value);
    fd.set("image", file);
    if (props.type === "Character" || props.type === "Monster") {
      fd.set("power", selectedPowers.map((x) => x.id).toString());
    }
    if (props.type === "Character") {
      fd.set("color", color);
    }
    if (tags.length) {
      fd.set("tags", tags.map((x) => x.toLowerCase()).toString());
    }
    fd.set("is_private", (!isChecked).toString());
    setLoading(true);
    axios
      .post("/api/images/" + props.type.toLowerCase() + "s/", fd)
      .then((res: { data: ImageInterface }) => {
        dispatch({
          type: AuthTypes.AddNewImage,
          payload: {
            image_type: convertToUserAttr(props.type),
            image: res.data,
          },
        });
        setLoading(false);
        props.onClose(true);
      })
      .catch((err) => {
        setLoading(false);
        if (!err.response) {
          console.error(err);
          return;
        }

        for (let key in err.response.data) {
          let errors: string[] = [];
          if (typeof err.response.data[key] === "string") {
            errors = [err.response.data[key]];
          } else if (err.response.data[key] instanceof Array) {
            errors = err.response.data[key];
          } else if (typeof err.response.data[key] === "object" && !(err.response.data[key] instanceof Array)) {
            //has a special case, will tell which one is wrong
            //should add this later
            errors = err.response.data[key][Object.keys(err.response.data[key])[0]];
          } else {
            errors = err.response.data[key];
          }

          switch (key) {
            case "image":
              setErrors((prev) => ({ ...prev, image: errors }));
              break;
            case "name":
              setNameInput((prev) => ({ ...prev, errors: errors }));
              break;
            case "color":
              setErrors((prev) => ({ ...prev, color: errors }));
              break;
            case "power":
              setErrors((prev) => ({ ...prev, powers: errors }));
              break;
            case "tags":
              setErrors((prev) => ({ ...prev, powers: errors.map((x) => "Tags: " + x) }));
              break;
            default:
              setErrors((prev) => ({ ...prev, image: errors }));
          }
        }
      });
  };

  return (
    <div
      className={
        " max-h-[95vh] w-[400px] lg:overflow-auto overflow-y-scroll overscroll-contain overscroll-y-contain bg-primary-200/90 dark:bg-primary-1000/90 rounded-lg flex flex-col p-4 gap-2 m-2 " +
        (props.type === "Background" || props.type === "Projectile" ? "" : "lg:w-[700px]")
      }
    >
      <div className={"lg:grid gap-8 " + (props.type === "Background" || props.type === "Projectile" ? "lg:grid-cols-1" : "lg:grid-cols-2")}>
        <div className="flex w-full items-center justify-center flex-col gap-5">
          <h4 className="text-primary-900 font-bold text-lg dark:text-white text-center">Create A New {props.type}</h4>
          <div className="px-5 pb-[1px] rounded dark:bg-primary-1000/90 bg-white/70 ">
            <InputGroup
              {...nameInput}
              onChange={(ev) => {
                setNameInput((prev) => ({ ...prev, value: ev.target.value, errors: [], invalid: false }));
              }}
            ></InputGroup>
          </div>
          <ImageDropper
            type={props.type}
            onFileInput={(file: Blob) => {
              setImage(file);
            }}
            errors={errors.image}
          ></ImageDropper>
        </div>
        <div className="flex flex-col justify-around">
          <div className="mb-4">
            {props.type === "Character" ? (
              <ColorPicker
                errors={errors.color}
                allow_edit={true}
                onSelect={(color: string) => {
                  setColor(color);
                  setErrors((prev) => ({ ...prev, color: [] }));
                }}
              ></ColorPicker>
            ) : (
              <></>
            )}
          </div>
          <div>
            {props.type === "Character" || props.type === "Monster" ? (
              <PowerSelector
                errors={errors.powers}
                powers={selectedPowers}
                is_public={false}
                editMode={true}
                onSelect={(items: ImageInterface[]) => {
                  setErrors((prev) => ({ ...prev, powers: [] }));
                  setSelectedPowers(items);
                }}
              ></PowerSelector>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center md:justify-around gap-2 justify-center  p-2 rounded-lg bg-white/70 dark:bg-primary-1000/90">
        <div className="flex-1">
          <TagInput
            value={tags}
            onChange={(list: string[]) => {
              setTags(list);
            }}
          ></TagInput>
        </div>
        <div className="flex flex-col justify-center flex-1 items-center gap-1">
          <div className="flex  justify-center items-center h-8">
            {/* id random is given cause of strange issue with this element not re-rendering. //TODO */}
            <input
              id="isPublicCheckbox"
              key={Math.random()}
              name="isPublic"
              checked={isChecked}
              type="checkbox"
              onChange={(ev) => {
                setIsChecked(ev.target.checked);
              }}
              className="w-6 h-6 border border-gray-300 rounded bg-white/70 focus:ring-3 focus:ring-primary-300"
            />
            <label htmlFor="isPublicCheckbox" className="ml-2 text-sm lg:text-md xl:text-lg font-medium text-primary-900 dark:text-primary-100 select-none">
              Publish to the public
            </label>
          </div>
          <div className="flex w-fit justify-center gap-2 items-center">
            <Button
              text="Create"
              icon="upload"
              disabled={loading}
              onClick={() => {
                handleSubmit();
              }}
            ></Button>
            <Button
              text="Cancel"
              icon="cancel-edit"
              onClick={() => {
                setLoading(false);
                props.onClose();
              }}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}
