import ScrollImage from "./ScrollImage";
import infoImage from "src/assets/images/promos/3 step plan.webp";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Footer from "./Footer";

export default function Home() {
  useEffect(() => {
    document.title = "What's The Word?";
  }, []);
  return (
    <>
      <div className="w-full h-auto flex flex-col bg-slate-50 dark:bg-slate-900">
        <div className="spacer flex flex-col min-h-screen transparent ">
          <div className="h-fit flex flex-col mt-3 items-center lg:justify-center lg:items-start">
            <div className="calling flex flex-col text-center lg:text-left lg:ml-[8rem] mt-6 lg:mt-[4vh]">
              <h1 className="text-primary-700 dark:text-primary-300 text-4xl lg:text-5xl xl:text-6xl font-bold md:pb-2">Class Getting Stale?</h1>
              <h2 className="text-primary-1100 dark:text-primary-100 text-3xl xl:text-5xl font-bold">We Have You Covered.</h2>
            </div>
          </div>
          <div className=" flex flex-col-reverse lg:flex-row justify-center items-center flex-grow">
            <article className="info-box md:max-w-[600px] xl:max-w-none xl:w-[1000px] max-h-[50vh] lg:h-[400px]  bg-primary-100 dark:bg-primary-900 rounded-xl lg:ml-[8rem]">
              <ul className="marker:text-primary-900 dark:marker:text-primary-300 text-md text-primary-1200 h-full dark:text-primary-300 list-inside p-3 flex flex-col justify-around list-disc lg:text-lg xl:text-xl">
                <li className="mb-2 lg:mb-3">20+ ESL Games - Unique, Flexible and Infinitely replayable!</li>
                <li className="mb-2 lg:mb-3">Customize your games with your own word lists and images!</li>
                <li className="mb-2 lg:mb-3">No installation, no downloads! Just go to this site and play!</li>
                <li className="">Big class, small class, have a fun class ready in seconds!</li>
              </ul>
            </article>
            <ScrollImage className="w-full h-[300px] lg:h-[50vh] lg:min-h-full m-0  xl:mt-[-7rem]"></ScrollImage>
          </div>
        </div>
        <section className="second-area flex flex-col md:flex-row w-full bg-transparent">
          <div className="left  md:w-[50%] flex md:p-5 justify-center items-center object-contain">
            <img src={infoImage} alt="" className="w-full" />
          </div>
          <div className="info-box md:w-[50%]  h-full flex justify-center items-center">
            <article className="bg-primary-300 dark:bg-primary-800 rounded-lg w-full m-3 p-3 max-w-[500px] lg:max-w-[700px] h-fit">
              <h2 className="text-lg font-bold lg:text-2xl xl:text-4xl text-center text-bolder text-primary-1100 dark:text-primary-100">Create a fun class in just 1 minute!</h2>
              <ul
                className="marker:text-primary-900 dark:marker:text-primary-300  w-auto
              text-md    text-primary-1200 dark:text-primary-300 list-inside list-disc p-3"
              >
                <li className="mb-5">Type on your vocab words, or choose among hundreds of vocab lists created by other teachers</li>
                <li className="mb-5">Teach the words to your kids</li>
                <li className="mb-5">Pick any game, have the kids practice those words while having fun! </li>
                <li className="mb-5">No need to download apps, install files, all you need is a browser and an internet connection! </li>
                <li className="">Works on any device with a modern browser!</li>
              </ul>
            </article>
          </div>
        </section>
        <div className="third-area flex flex-col lg:flex-row w-full bg-primary-200 dark:bg-primary-1000 mt-[6rem] place-content-around py-10">
          <div className="card rounded-lg shadow-md m-5 mt-[-5rem] bg-stone-50 dark:bg-slate-800  lg:mt-[-5rem] p-6 lg:max-w-[30%]">
            <h3 className="text-4xl text-center text-primary-900 dark:text-primary-100 text-weight-500">FLEXIBLE</h3>
            <ul
              className="marker:text-primary-900 dark:marker:text-primary-300 w-auto
              text-md   text-primary-1200 dark:text-primary-300 list-inside list-disc p-5"
            >
              <li className="mb-3">create your own rules!</li>
              <li className="mb-3">use as review, warmup, final game, or any time you like!</li>
              <li className="">use for kindergarten kids, primary, middle school, just adjust how you play!</li>
            </ul>
          </div>
          <div className="card rounded-lg shadow-md m-5 bg-stone-50 dark:bg-slate-800 lg:mt-[-5rem] p-6 lg:max-w-[30%]">
            <h3 className="text-4xl text-center text-primary-900 dark:text-primary-100 text-weight-500">CUSTOMIZABLE</h3>
            <ul
              className="marker:text-primary-900 dark:marker:text-primary-300 w-auto
              text-md   text-primary-1200 dark:text-primary-300 list-inside list-disc p-5"
            >
              <li className="mb-3">use your own words and vocab</li>
              <li className="mb-3">upload images of yourself, students, monsters, their favourite characters, etc.</li>
              <li className="">create hilarious combinations of characters and powers to have a fun class</li>
            </ul>
          </div>
          <div className="card rounded-lg shadow-md m-5 bg-stone-50 dark:bg-slate-800 lg:mt-[-5rem] p-6 lg:max-w-[30%]">
            <h3 className="text-4xl text-center text-primary-900 dark:text-primary-100 text-weight-500">REPLAYABLE</h3>
            <ul
              className="marker:text-primary-900 dark:marker:text-primary-300 w-auto
              text-md   text-primary-1200 dark:text-primary-300 list-inside list-disc p-5"
            >
              <li className="mb-3">randomized games mean infinite possibilities!</li>

              <li className="mb-3">kid-tested fun, they will beg to play over and over again</li>

              <li className="">choose among hundreds of uploaded images to make the games more relatable for the kids!</li>
            </ul>
          </div>
        </div>
        <div className="divider bg-tranparent flex justify-center items-center p-5">
          <h4 className="text-4xl font-thin text-center dark:text-white">IMPROVE YOUR CLASS TODAY!</h4>
        </div>
        <div className="plans-section bg-primary-100 dark:bg-primary-900 relative flex flex-col md:flex-row flex-wrap justify-center items-center md:items-stretch">
          <div className="bg-white dark:bg-slate-700 shadow-md rounded-lg max-w-[380px] basis-0 flex-1 m-3 flex flex-col overflow-hidden relative min-h-[550px]">
            <div className="title-box w-full p-5 flex gap-4 justify-start items-center">
              <h4 className="font-bold text-2xl dark:text-white">Basic</h4>
            </div>
            <div className="price-box  text-green-600 dark:text-teal-400 m-3">
              <h2 className="text-thin text-center text-5xl my-4">FREE</h2>
            </div>
            <div className="info-box w-full p-5">
              <p className="text-md leading-5 text-primary-1000 dark:text-primary-100">Basic plan that allows you to try out the system, see if your kids like it</p>

              <ul
                className="marker:text-primary-900 dark:marker:text-primary-300 w-auto
              text-md   text-primary-1200 dark:text-primary-300 list-inside list-disc p-5"
              >
                <li className="mb-2">access to 3 limited games</li>
                <li className="mb-2">choose among hundreds of user-submitted words and images</li>
                <li className="mb-2">create your own word lists</li>
                <li className="">upgrade to Pro tier any time</li>
              </ul>
            </div>
            <div className="button-container flex justify-center items-end pb-5 flex-grow">
              {process.env.REACT_APP_COMING_SOON === "true" ? (
                <button className="bg-gray-400 dark:bg-gray-800 p-3 rounded-lg px-8 text-xl font-bold text-gray-200 dark:text-gray-600 pointer-events-none">COMING SOON</button>
              ) : (
                <button className="bg-primary-700 p-3 rounded-lg px-8 text-xl font-bold text-white">
                  <Link to="/signup">TRY IT NOW</Link>
                </button>
              )}
            </div>
          </div>
          <main className="bg-white dark:bg-slate-700 shadow-md rounded-lg max-w-[380px] basis-0 flex-1 m-3 flex flex-col overflow-hidden relative min-h-[550px]">
            <div className="title-box w-full p-5 flex gap-4 justify-start items-center">
              <h4 className="font-bold text-2xl dark:text-white">Pro</h4>
              <p className="rounded-lg bg-[#16C6DE] w-fit h-fit p-1 text-white text-xs whitespace-nowrap">+ FREE TRIAL</p>
            </div>
            <div className="bg-orange-500 absolute top-[3ch] right-[-3ch] rotate-45 w-[15ch] text-center h-fit text-white font-bold text-sm">Best Value</div>

            <div className="price-box  text-green-600 dark:text-teal-400 flex justify-center flex-col gap-5 m-2 items-center">
              <h2 className="text-emerald-500 dark:text-teal-400 text-5xl">
                <span className="text-xl align-text-top mr-[-0.8rem]">$</span> 5.99
              </h2>
              <p className="text-xs text-gray-400 dark:text-gray-400 text-center">per month</p>
            </div>
            <div className="info-box w-full p-5">
              <p className="text-md leading-5 text-primary-1000 dark:text-primary-100">Includes everything in the basic plan, but adds:</p>

              <ul
                className="marker:text-primary-900 dark:marker:text-primary-300 w-auto
              text-md  text-primary-1200 dark:text-primary-300 list-inside list-disc p-5"
              >
                <li className="mb-2">access to all released games</li>
                <li className="mb-2">access to new games as they get released</li>
                <li className="mb-2">upload your own images</li>
                <li className="mb-2">share your wordlists with others</li>
              </ul>
            </div>
            <div className="button-container flex justify-center items-end pb-5 flex-grow">
              {process.env.REACT_APP_COMING_SOON === "true" ? (
                <button className="bg-gray-400 dark:bg-gray-800 p-3 rounded-lg px-8 text-xl font-bold text-gray-200 dark:text-gray-600 pointer-events-none">COMING SOON</button>
              ) : (
                <button className="bg-primary-700 p-3 rounded-lg px-8 text-xl font-bold text-white">
                  <Link to="/signup">START TRIAL</Link>
                </button>
              )}
            </div>
          </main>
          <div className="bg-white dark:bg-slate-700 shadow-md rounded-lg max-w-[380px] basis-0 flex-1 m-3 flex flex-col overflow-hidden relative min-h-[550px]">
            <div className="title-box w-full p-5 flex gap-4 justify-start items-center">
              <h4 className="font-bold text-2xl dark:text-white">Premium</h4>
              <p className="rounded-lg bg-[#16C6DE] w-fit h-fit p-1 text-white text-xs whitespace-nowrap">+ FREE TRIAL</p>
            </div>
            <div className="price-box  text-green-600 dark:text-teal-400 flex justify-center flex-col gap-5 m-2 items-center">
              <h2 className="text-emerald-500 dark:text-teal-400 text-5xl">
                <span className="text-xl align-text-top mr-[-0.8rem]">$</span> 9.99
              </h2>
              <p className="text-xs text-gray-400 dark:text-gray-400 text-center">per month</p>
            </div>
            <div className="info-box w-full p-5">
              <p className="text-md leading-5 text-primary-1000 dark:text-primary-100">Includes everything in the Pro tier, but adds:</p>

              <ul
                className="marker:text-primary-900 dark:marker:text-primary-300 w-auto
              text-md  text-primary-1200 dark:text-primary-300 list-inside list-disc p-5"
              >
                <li className="mb-2">log in using 3 devices at the same time</li>
                <li className="mb-2">early access to pre-release games still in beta, before they are released</li>
                <li className="">early access to new site features before they are released</li>
              </ul>
            </div>
            <div className="button-container flex justify-center items-end pb-5 flex-grow">
              {process.env.REACT_APP_COMING_SOON === "true" ? (
                <button className="bg-gray-400 dark:bg-gray-800 p-3 rounded-lg px-8 text-xl font-bold text-gray-200 dark:text-gray-600 pointer-events-none">COMING SOON</button>
              ) : (
                <button className="bg-primary-700 p-3 rounded-lg px-8 text-xl font-bold text-white">
                  <Link to="/signup">START TRIAL</Link>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="divider bg-tranparent flex justify-center items-center p-5">
          <h4 className="text-4xl font-thin text-center dark:text-white">GET STARTED NOW</h4>
        </div>
        <Footer />
      </div>
    </>
  );
}
