import { useContext, useState } from "react";
import { UserContext } from "src/utils/UserContext";
import AddImageButton from "./AddImage";
import BlackBgBackdrop from "../basic/BlackBgBackdrop";
import CreateImageForm from "./CreateImageForm";
import GalleryImage from "./GalleryImage";
import { ImageInterface, ImageType } from "./ImagePicker";
import LoadMoreImagesButton from "./LoadMoreImagesButton";
interface props {
  images: ImageInterface[];
  contain?: boolean | false;
  type: ImageType;
  is_public: Boolean;
  loading: boolean;
  hasMore?: boolean;
  loadMore?: Function;
  forceReset?: Function;
}

export default function Gallery(props: props) {
  const { state, dispatch } = useContext(UserContext);
  const getText = (type: ImageType) => {
    switch (type) {
      case "BACKGROUNDS":
        return "Background";
      case "CHARACTERS":
        return "Character";
      case "MONSTERS":
        return "Monster";
      case "PROJECTILES":
        return "Projectile";
    }
  };
  const [showCreate, setShowCreate] = useState(false);
  const closeFullscreen = () => {
    setShowCreate(false);
  };
  return (
    <>
      <div className=" flex flex-wrap justify-center gap-1 object-contain p-5">
        {props.loading ? (
          <svg className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] text-primary-600 dark:text-primary-800" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z">
              <animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" />
            </path>
          </svg>
        ) : (
          <>
            {props.is_public || state.current_plan_type === null ? (
              <></>
            ) : (
              <AddImageButton className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] aspect-square" text={"Add A New " + getText(props.type)} onClick={() => setShowCreate(true)}></AddImageButton>
            )}
            {props.images && props.images.length ? (
              <>
                {props.images.map((x) => {
                  return (
                    <div key={x.id} className="h-[100px] md:h-[150px]">
                      <GalleryImage
                        forceReset={() => {
                          if (props.forceReset) {
                            props.forceReset();
                          }
                        }}
                        image={x}
                        contain={props.contain}
                        type={props.type}
                        is_public={props.is_public}
                      ></GalleryImage>
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            {props.hasMore ? (
              <LoadMoreImagesButton
                onClick={() => {
                  if (props.loadMore) {
                    props.loadMore();
                  }
                }}
                className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] aspect-square"
              ></LoadMoreImagesButton>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      {showCreate ? (
        <BlackBgBackdrop onClose={() => [closeFullscreen()]}>
          <CreateImageForm
            type={getText(props.type)}
            onClose={(hasChanges: boolean) => {
              if (hasChanges && props.forceReset) {
                props.forceReset();
              }
              closeFullscreen();
            }}
          ></CreateImageForm>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
    </>
  );
}
