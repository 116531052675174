import { MouseEventHandler } from "react";

interface props {
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

export default function OnlineButton(props: props) {
  return (
    <div
      onClick={props.onClick}
      className="flex relative items-center justify-center p-[2px] text-md font-bold text-sky-600 dark:text-white rounded-lg group bg-gradient-to-br 
          from-sky-300 to-teal-600 focus:from-sky-300 focus:to-teal-600 hover:text-white cursor-pointer select-none
          shadow-[0_0_5px_1px] shadow-blue-400 hover:shadow-teal-300"
    >
      <span className="group px-5 py-2.5 transition-all ease-in duration-75 bg-slate-300 dark:bg-slate-700 rounded-md group-hover:bg-opacity-0 group-focus:bg-opacity-0 group-active:bg-opacity-0 flex gap-3">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
        </svg>
        CHECK ONLINE
      </span>
    </div>
  );
}
