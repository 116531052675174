import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function CancellationPolicyPage() {
  useEffect(() => {
    document.title = "Cancellation Policy | whatstheword.io";
  }, []);
  return (
    <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900 p-5">
      <div className="w-full p-5 bg-stone-50 dark:bg-slate-900 shadow-primary-1100 dark:shadow-primary-700 shadow-sm rounded-md flex justify-center text-center flex-col items-center gap-5">
        <h3 className="title text-primary-1100 dark:text-white font-medium text-xl">How do I cancel my subscription?</h3>
        <p className="dark:text-white max-w-[65ch]">
          We too dislike the tedious process of cancelling a subscription. So our policy is simple. Click the "cancel" button to cancel. Thats all! Although, we would like to hear from you on why you want to cancel! Make sure to let us know
          at{" "}
          <a className="text-blue-600 dark:text-blue-200 underline" href="mailto:feedback@whatstheword.io">
            feedback@whatstheword.io
          </a>
        </p>
        <p className="dark:text-white max-w-[65ch]">
          Please go to{" "}
          <Link className="text-blue-600 dark:text-blue-200 underline" to="/profile">
            your profile
          </Link>
          , click your active license, and click the red cancel button. It's that easy!
        </p>
        <p className="dark:text-white max-w-[65ch]">
          Any extra billing questions or concerns, please email us at{" "}
          <a className="text-blue-600 dark:text-blue-200 underline" href="mailto:billing@whatstheword.io">
            billing@whatstheword.io
          </a>
        </p>
      </div>
    </div>
  );
}
