import axios from "axios";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import { InputGroup } from "../basic/InputGroup";
import { WordListInterface } from "./WordList";

export interface ListInput {
  list: string[];
  listAsString: string | undefined;
  invalid: boolean;
  submitted: boolean;
  errors: string[];
}
export interface ListExtras {
  theme: string;
  is_public: boolean;
  errors: string[];
  submitted: boolean;
  invalid: boolean;
}

export function wordlistToString(list: string[]): string {
  return list.join(", ");
}

export const PUNCTUATION = [" ", ",", ".", "，", "。", "׳", "،", "՝", "᠈", "᠂", "፣", "⸴", "⹁", "﹐", "､", "、"];

export default function WordListChooser(props: { lists: WordListInterface[]; chosen: number }) {
  const { state, dispatch } = useContext(UserContext);
  const initalListState = { listAsString: "", list: [], errors: [], submitted: false, invalid: false };
  const [newListState, setNewListState] = useState<ListInput>(initalListState);
  const [newListExtras, setNewListExtras] = useState<ListExtras>({ ...({} as ListExtras), theme: "", is_public: false, errors: [], submitted: false, invalid: false });
  const [createToggle, setCreateToggle] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const handleWordlistClick = (listId: string, saveToServerOverride = false) => {
    if (state.max_sessions_allowed > 1 && !state.always_save_wordlist_across_devices && !saveToServerOverride) {
      //if more than one person is using this account, we dont want all sessions to now use the selected words
      localStorage.setItem(state.id + "_swl", listId);
      dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, locallySelectedWordlist: parseInt(listId) } });
      return;
    }

    axios
      .post(`/api/wordlists/${listId}/select/`)
      .then((res) => {
        // dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, favourite_wordlists: state.favourite_wordlists.concat([list]) } });
        dispatch({ type: AuthTypes.UpdateChosenWordList, payload: { selected_wordlist: parseInt(listId) } });
      })
      .catch((err) => {});
  };
  const handleNewWordListType = (ev: ChangeEvent<HTMLInputElement>) => {
    setNewListState((prevState) => ({ ...prevState, listAsString: ev.target.value, errors: [], invalid: false }));
    let currentWord = "";
    let allWords: string[] = [];
    let waitingForClose = "";
    for (let letter of ev.target.value.split("")) {
      if (PUNCTUATION.includes(letter) && waitingForClose === "") {
        if (currentWord !== "") {
          allWords.push(currentWord);
          allWords = Array.from(new Set(allWords));
          currentWord = "";
        } else {
          continue;
        }
      } else if (['"', "'", "“"].includes(letter) && waitingForClose === "") {
        waitingForClose = letter;
        if (["“"].includes(letter)) {
          waitingForClose = "”"; //chinese keyboards use these as the quotations
        }
      } else if (waitingForClose !== "") {
        if (letter === waitingForClose) {
          allWords.push(currentWord);
          allWords = Array.from(new Set(allWords)).map((x) => x.toLowerCase());
          currentWord = "";
          waitingForClose = "";
        } else {
          currentWord += letter;
        }
      } else {
        currentWord += letter;
      }
    }
    if (currentWord !== "") {
      allWords.push(currentWord);
      allWords = Array.from(new Set(allWords)).map((x) => x.toLowerCase());
      currentWord = "";
    }
    setNewListState((prevState) => ({ ...prevState, list: allWords }));
  };

  const handleSubmit = () => {
    setSubmitDisabled(true);

    if (newListState.list.length <= 0) {
      setNewListState((prevState) => ({ ...prevState, errors: ["Enter at least one word."] }));
      setSubmitDisabled(false);
      return;
    }
    if (newListExtras.theme === "") {
      setNewListExtras((prevState) => ({ ...prevState, errors: ["Please give your wordlist a theme or name."] }));
      setSubmitDisabled(false);
      return;
    }
    let fd = {
      is_private: !newListExtras.is_public,
      theme: newListExtras.theme,
      words: newListState.list.map((x) => x.toLowerCase()),
    };
    axios
      .post("/api/wordlists/", fd)
      .then((res) => {
        if (res.status === 201) {
          setNewListExtras({ theme: "", is_public: false, errors: [], invalid: false, submitted: false });
          setNewListState({ list: [], listAsString: "", errors: [], invalid: false, submitted: false });
          setCreateToggle(false);
          setSubmitDisabled(false);
          let newLists = [...state.favourite_wordlists];
          newLists.push(res.data);
          if (state.max_sessions_allowed > 1 && !state.always_save_wordlist_across_devices) {
            //if more than one person is using this account, we dont want all sessions to now use the selected words
            localStorage.setItem(state.id + "_swl", res.data.id.toString());
            dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, favourite_wordlists: newLists, locallySelectedWordlist: parseInt(res.data.id) } });
            return;
          }
          dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, favourite_wordlists: newLists, locallySelectedWordlist: parseInt(res.data.id), selected_wordlist: parseInt(res.data.id) } });
        } else {
          setSubmitDisabled(false);
        }
      })
      .catch((err) => {
        setSubmitDisabled(false);
        for (let key in err.response.data) {
          let errors: string[] = [];
          if (typeof err.response.data[key] === "string") {
            errors = [err.response.data[key]];
          } else if (typeof err.response.data[key] === "object" && !(err.response.data[key] instanceof Array)) {
            //words has a special case, will tell which one is wrong
            //should add this later
            errors = err.response.data[key][Object.keys(err.response.data[key])[0]];
          } else {
            errors = err.response.data[key];
          }
          switch (key) {
            case "words":
              setNewListState({ ...newListState, errors: errors });
              break;
            case "theme":
              setNewListExtras({ ...newListExtras, errors: errors });
              break;
            default:
              setNewListState({ ...newListState, errors: errors });
          }
        }
      });
  };

  useEffect(() => {
    const savedList = localStorage.getItem(state.id + "_swl");
    if (savedList !== null) {
      dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, locallySelectedWordlist: parseInt(savedList) } });
    } else {
      dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, locallySelectedWordlist: state.selected_wordlist } });
    }
  }, [state.isAuthenticated]);

  const showExtraSelectButton = () => {
    // If localstorage has an item that is named {user_id}_swl then it means that the user selected a wordlist
    // while having max_sessions_allowed>1 and always_save_wordlist_across_devices set to false
    return state.max_sessions_allowed > 1 && localStorage.getItem(state.id + "_swl") !== null && state.selected_wordlist !== parseInt(localStorage.getItem(state.id + "_swl") || "-1");
  };

  const selectOnAllDevices = () => {
    const savedList = localStorage.getItem(state.id + "_swl");
    if (savedList !== null) {
      handleWordlistClick(savedList, true);
    }
  };

  return (
    <div className="w-full bg-white dark:bg-slate-900 flex flex-col place-content-center gap-5 justify-center items-center">
      <div className="w-full flex h-full justify-center items-center flex-col gap-4 p-2 lg:flex-row">
        {props.lists.length ? (
          <>
            <h4 className="text-lg font-bold text-primary-900 dark:text-primary-200 lg:min-w-[150px]">Your chosen wordlist: </h4>
            <div className="flex flex-1 justify-center items-center gap-3">
              <div className="dropdown relative">
                <select
                  className="dropdown-toggle w-full rounded px-3 py-2.5 bg-primary-200 text-primary-1100 font-bolder text-xl leading-tight uppercase hover:bg-primary-400 focus:outline-none transition duration-150 ease-in-out flex items-center whitespace-nowrap dark:bg-primary-1200 dark:text-primary-100 dark:hover:bg-primary-1100"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  value={state.locallySelectedWordlist}
                  onChange={(ev) => handleWordlistClick(ev.target.value)}
                >
                  {props.lists.map((list, i) => (
                    <option
                      className="hover:cursor-pointer dropdown-item bg-white dark:bg-primary-1200 text-lg font-bold uppercase py-2 px-4 block w-full whitespace-nowrap bg-transparent text-primary-1100 dark:text-primary-100 hover:bg-primary-100 dark:hover:bg-gray-700"
                      key={i}
                      value={list.id}
                      label={wordlistToString(list.words)}
                    >
                      {wordlistToString(list.words)}
                    </option>
                  ))}
                </select>
              </div>
              {showExtraSelectButton() ? (
                <button
                  onClick={() => {
                    selectOnAllDevices();
                  }}
                  disabled={submitDisabled}
                  className="border-2 p-1 rounded flex justify-center items-center max-w-[15ch] text-xs text-primary-700 border-primary-700 hover:bg-primary-700/20 dark:border-primary-100 dark:text-primary-100 dark:hover:bg-white/20 disabled:text-gray-400 disabled:border-gray-400 disabled:pointer-events-none"
                >
                  select on all devices
                </button>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="create-list-button lg:min-w-[150px]">
          <button
            onClick={() => {
              setCreateToggle(!createToggle);
            }}
            className={
              (createToggle
                ? "border-red-900 px-6 py-2 text-xs text-red-900 hover:bg-red-400/25 dark:border-red-200 dark:text-red-200 dark:hover:bg-red-900/25"
                : "border-teal-600 px-8 py-4 text-md text-teal-600 hover:bg-teal-200/25 dark:border-teal-100 dark:text-teal-100 dark:hover:bg-white/20 ") +
              "inline-block  border-2 font-medium  leading-tight uppercase rounded transition duration-150 ease-in-out"
            }
          >
            {!createToggle ? "CREATE NEW WORDLIST" : "CANCEL CREATE"}
          </button>
        </div>
      </div>
      <div className={!createToggle ? "hidden" : "w-full flex flex-col justify-center items-center p-2 bg-white dark:bg-slate-900 rounded-md m-2"}>
        <form
          onSubmit={(ev) => {
            handleSubmit();
            ev.preventDefault();
          }}
          className="w-full"
        >
          <div className="w-full flex mb-2 rounded-md">
            <InputGroup
              name="words"
              value={newListState.listAsString}
              placeholder=" "
              errors={newListState.errors}
              label="Type Some words Here!"
              required={false}
              type="text"
              invalid={newListState.invalid}
              onChange={handleNewWordListType}
              submitted={newListState.submitted}
            />
          </div>
          <div className="m-w p-3 w-full bg-slate-50 dark:bg-primary-1200 rounded-md">
            <p className="text-primary-900 dark:text-primary-100 font-bolder text-xl leading-tight uppercase">
              Your New List ({newListState.list.length === 1 ? newListState.list.length + " word" : newListState.list.length + " words"}) : <span className="text-primary-1200 dark:text-white ">{wordlistToString(newListState.list)}</span>
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center items-center rounded-md w-full lg:flex-row lg:gap-8">
            <InputGroup
              name="theme"
              className="max-w-[400px] "
              value={newListExtras.theme}
              placeholder=" "
              errors={newListExtras.errors}
              label="Word List Theme or Name"
              required={false}
              type="text"
              invalid={newListExtras.invalid}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                setNewListExtras((prevState) => ({ ...prevState, theme: ev.target.value, errors: [] }));
              }}
              submitted={newListExtras.submitted}
            />
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center h-8">
                <input
                  id="is_public"
                  type="checkbox"
                  checked={newListExtras.is_public}
                  onChange={(ev) => {
                    setNewListExtras((prevState) => ({ ...prevState, is_public: ev.target.checked }));
                  }}
                  className="w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                />
              </div>
              <label htmlFor="is_public" className="ml-2 text-sm lg:text-md xl:text-lg font-medium text-primary-900 dark:text-primary-100">
                Publish online for others to use
              </label>
            </div>
            <button
              type="submit"
              className={
                "duration-300 p-3 m-3 h-12 rounded px-5 py-1.5 font-thin " +
                (!submitDisabled ? "bg-primary-600 text-white  dark:bg-primary-900 " : "bg-gray-300 dark:bg-gray-700 dark:text-gray-500 text-gray-100 disabled pointer-events-none ")
              }
            >
              SUMBIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
