import { Link } from "react-router-dom";
interface props {
  text: string;
  isMobile?: boolean | false;
  href: string;
}

export default function NavbarButton(props: props) {
  return (
    <>
      {!props.isMobile ? (
        <Link
          to={props.href}
          className="link font-thin px-[1.8rem] flex justify-center items-center hover:cursor-pointer text-primary-1100 dark:text-primary-100 dark:hover:text-white hover:text-primary-800 hover:bg-primary-200 dark:hover:bg-primary-1000 ease-in-out duration-300
                        "
        >
          <p className="select-none lg:text-lg md:text-sm  text-center">{props.text}</p>
        </Link>
      ) : (
        <Link to={props.href} className="w-full h-16 text-lg  flex justify-center items-center hover:cursor-pointer duration-300 dark:text-primary-100 hover:bg-primary-100 dark:hover:bg-primary-900 border-b-[1px] border-primary-800">
          <p className="font-thin text-center select-none whitespace-normal ">{props.text}</p>
        </Link>
      )}
    </>
  );
}
