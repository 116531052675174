import React from "react";
import TermsAndConditions from "./TermsAndConditions";

export default function TermsAndConditionsPage() {
  return (
    <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900">
      <div className="w-full h-full p-5 dark:text-white">
        <TermsAndConditions></TermsAndConditions>
      </div>
    </div>
  );
}
