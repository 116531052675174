import axios from "axios";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";

import { ReactComponent as Rocket } from "../../assets/images/rocket.svg";
export default function Success() {
  const { state, dispatch } = useContext(UserContext);
  useEffect(() => {
    document.title = "Success! | whatstheword.io";
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
        });
    }
  }, [state.isAuthenticated, dispatch]);
  return (
    <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900 flex lg:flex-row flex-col justify-center items-center gap-10">
      <h2 className="title font-bold text-3xl lg:hidden block text-emerald-500 dark:text-emerald-200">Success!</h2>
      <Rocket className="max-w-[80%] md:max-w-[400px]" />
      <div className="flex flex-col gap-5 justify-center items-center p-5">
        <h2 className="title font-bold text-3xl lg:block hidden text-emerald-500 dark:text-emerald-200">Success!</h2>
        <h4 className="text-primary-800 dark:text-white max-w-[65ch]">Your payment was successful! You should receive your license automatically. Just refresh the page and check your profile.</h4>
        <h4 className="text-primary-800 dark:text-white max-w-[65ch]">If you used PayPal, the payment might take anywhere from 30 seconds to an hour to go through, so please be patient.</h4>
        <h4 className="text-primary-800 text-sm dark:text-white max-w-[65ch] text-center">
          (You can always contact us at{" "}
          <a className="text-blue-500 dark:text-blue-200 underline" href="mailto:billing@whatstheword.io">
            billing@whatstheword.io
          </a>{" "}
          if you have any issues!)
        </h4>
        <h4 className="text-emerald-500 text-lg dark:text-emerald-200 font-bold max-w-[65ch]">Thank you very much for your support! Go enjoy the games, and have a fun class!</h4>
        <Link to="/">
          <div className="bg-primary-500 dark:bg-primary-1000 hover:bg-primary-400 dark:hover:bg-primary-800 duration-100 ease-in-out rounded-xl px-5 py-3 text-white">GO TO DASHBOARD</div>
        </Link>
      </div>
    </div>
  );
}
