import { UserProvider } from "./utils/UserContext";
import Body from "./components/Routes";

function App() {
  return (
    <UserProvider>
      <Body />
    </UserProvider>
  );
}

export default App;
