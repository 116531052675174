import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import Button from "../basic/Button";
import ColorPicker from "../basic/ColorPicker";
import { InputGroup, inputProps } from "../basic/InputGroup";
import PowerSelector from "../basic/PowerSelector";
import { getShownName, ImageInterface, ImageType, UserImageAttr, UserImageInterfaceAttr } from "./ImagePicker";

import { ReactComponent as Logo } from "../../assets/images/logo/logo-v1.svg";
import TagInput from "./TagInput";

interface props {
  onClose?: Function;
  is_public: Boolean;
  type: ImageType;
  image: ImageInterface;
  onFavourite: Function;
  onDelete: Function;
  onSubmit?: Function;
  forceReset?: Function;
  fakeIsLiked: Boolean;
}

export default function FullScreenImageView(props: props) {
  const { state, dispatch } = useContext(UserContext);
  const [newName, setNewName] = useState<inputProps>({ value: props.image.name, placeholder: " ", submitted: false, errors: [], onChange: () => {}, name: "Name", label: "Name", type: "text", required: false, invalid: false });
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState<{ color: string[]; power: string[] }>({ color: [], power: [] });
  const [newColor, setNewColor] = useState<string | undefined>(props.image.color);
  const [newPowers, setNewPowers] = useState<ImageInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [globalErrors, setGlobalErrors] = useState([] as string[]);
  const [newTags, setNewTags] = useState(props.image.tags);
  const convertToUserAttr = (type: ImageType = "MONSTERS") => {
    return ("favourite_" + type.toLowerCase()) as UserImageAttr;
  };

  const convertToUserImageAttr = (type: ImageType) => {
    return ("favourite_" + type.toLowerCase() + "_images") as UserImageInterfaceAttr;
  };
  const handleEdit = () => {
    if (loading) return;
    setErrors({
      color: [],
      power: [],
    });
    setGlobalErrors([]);
    setNewName((prev) => ({ ...prev, errors: [], invalid: false }));
    if (newName.value === "") {
      setNewName((prev) => ({ ...prev, errors: ["Name cannot be blank."], invalid: true }));
      return;
    }
    const fd = new FormData();
    if (newColor && newColor !== props.image.color && props.type === "CHARACTERS") {
      fd.set("color", newColor);
    }
    if (newPowers && newPowers !== props.image.power && (props.type === "CHARACTERS" || props.type === "MONSTERS")) {
      fd.set("power", newPowers.map((x) => x.id).toString());
    }
    if (newName.value && newName.value !== props.image.name) {
      fd.set("name", newName.value);
    }
    if (!newTags.every((x) => props.image.tags.includes(x) && props.image.tags.every((x) => newTags.includes(x)))) {
      fd.set("tags", newTags.map((x) => x.toLowerCase()).toString());
    }
    if (Array.from(fd.keys()).length === 0) {
      setErrors((prev) => ({ ...prev, power: ["You haven't made any changes."] }));
      return;
    }
    setLoading(true);
    axios
      .patch("/api/images/" + props.type.toLowerCase() + "/" + props.image.id + "/", fd)
      .then((res) => {
        dispatch({
          type: AuthTypes.UpdateImage,
          payload: {
            image: res.data,
            image_type: convertToUserAttr(props.type),
          },
        });
        setEditMode(false);
        setLoading(false);
        if (props.forceReset) {
          props.forceReset();
        }
      })
      .catch((err) => {
        setLoading(false);

        if (!err.response || !err.response.data) {
          setGlobalErrors((prevValues) => ({ ...prevValues, errors: ["Unable to contact the servers. Check your internet connection or try again later."] }));
        }
        for (let key in err.response.data) {
          let errors: string[] = [];
          if (typeof err.response.data[key] === "string") {
            errors = [err.response.data[key]];
          } else if (err.response.data[key] instanceof Array) {
            // do nothing
          } else if (typeof err.response.data[key] === "object" && !(err.response.data[key] instanceof Array)) {
            //has a special case, will tell which one is wrong
            //should add this later
            errors = err.response.data[key][Object.keys(err.response.data[key])[0]];
          } else {
            errors = err.response.data[key];
          }
          switch (key) {
            case "name":
              setNewName((prev) => ({ ...prev, errors: errors }));
              break;
            case "color":
              setErrors((prev) => ({ ...prev, color: errors }));
              break;
            case "power":
              setErrors((prev) => ({ ...prev, powers: errors }));
              break;
            case "tags":
              setGlobalErrors(errors.map((err) => "Tags: " + err));
              break;
            default:
              setErrors((prev) => ({ ...prev, image: errors }));
          }
        }
      });
  };
  const handleDelete = (id: number) => {
    if (loading) return;
    setGlobalErrors([]);
    if (window.confirm("Are you sure you want to delete " + props.image.name + "?")) {
      setLoading(true);
      axios
        .delete("/api/images/" + props.type.toLowerCase() + "/" + props.image.id + "/")
        .then((res) => {
          dispatch({
            type: AuthTypes.DeleteImage,
            payload: {
              image_type: convertToUserAttr(props.type),
              id: props.image.id,
            },
          });
          setLoading(false);
          if (props.onDelete) {
            props.onDelete();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (!err.response || !err.response.data) {
            setGlobalErrors(["Something went wrong, please refresh."]);
            return;
          }

          for (let key in err.response.data) {
            let errors: string[] = [];
            if (typeof err.response.data[key] === "string") {
              errors = [err.response.data[key]];
            } else {
              errors = err.response.data[key];
            }
            switch (key) {
              default:
                setGlobalErrors(errors);
            }
          }
        });
    }
  };
  const cancelEdit = () => {
    setNewName((prev) => ({ ...prev, errors: [], invalid: false, value: props.image.name }));
    setNewColor(props.image.color);
    setNewPowers(props.image.power);
    setErrors({ color: [], power: [] });
  };

  useEffect(() => {
    setNewColor(props.image.color);
    setNewPowers(props.image.power);
  }, [props.image.color, props.image.power]);

  const handleUpload = () => {
    if (loading) return;
    setLoading(true);
    setGlobalErrors([]);
    if (props.image.is_private) {
      //make public
      axios
        .patch("/api/images/" + props.type.toLowerCase() + "/" + props.image.id + "/", { is_private: false })
        .then((res) => {
          let newList = Array.from(state[convertToUserImageAttr(props.type)]);
          res.data.is_liked = props.image.is_liked;
          newList.splice(newList.indexOf(props.image), 1);
          newList.push(res.data);
          dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, [convertToUserImageAttr(props.type)]: newList } });
          setEditMode(false);
          setLoading(false);
          if (props.forceReset) {
            props.forceReset();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (!err.response) {
            console.error(err);
            return;
          }

          for (let key in err.response.data) {
            let errors: string[] = [];
            if (typeof err.response.data[key] === "string") {
              errors = [err.response.data[key]];
            } else {
              errors = err.response.data[key];
            }
            switch (key) {
              default:
                setGlobalErrors(errors);
            }
          }
        });
    } else {
      //make private
      axios
        .patch("/api/images/" + props.type.toLowerCase() + "/" + props.image.id + "/", { is_private: true })
        .then((res) => {
          let newList = Array.from(state[convertToUserImageAttr(props.type)]);
          res.data.is_liked = props.image.is_liked;
          newList.splice(newList.indexOf(props.image), 1);
          newList.push(res.data);
          dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, [convertToUserImageAttr(props.type)]: newList } });
          setEditMode(false);
          setLoading(false);
          if (props.forceReset) {
            props.forceReset();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (!err.response) {
            setGlobalErrors(["There is an issue, please refresh."]);
            return;
          }

          for (let key in err.response.data) {
            let errors: string[] = [];
            if (typeof err.response.data[key] === "string") {
              errors = [err.response.data[key]];
            } else {
              errors = err.response.data[key];
            }
            switch (key) {
              default:
                setGlobalErrors(errors);
            }
          }
        });
    }
  };

  return (
    <div
      className={
        "main-container overscroll-contain overscroll-y-contain min-w-[400px] border-[1px] flex flex-col m-2 rounded-lg relative " +
        (props.is_public ? "bg-sky-200/90 border-blue-800 dark:bg-blue-900/90 dark:border-sky-300" : "bg-primary-100/90 max-w-[500px] border-primary-900 dark:bg-primary-900/90 dark:border-white")
      }
    >
      <div className="max-h-[90vh] overflow-y-scroll lg:overflow-auto p-3 flex flex-col gap-3 items-center">
        {editMode && !props.is_public ? (
          <InputGroup
            className="max-w-[200px] bg-primary-100/50 dark:bg-slate-500/50 p-2 py-1 rounded"
            {...newName}
            onChange={(ev) => {
              setNewName((prevValues) => ({ ...prevValues, value: ev.target.value }));
            }}
          ></InputGroup>
        ) : (
          <div className={"p-2 px-5 rounded " + (props.is_public ? "bg-sky-100/50 text-sky-800 dark:bg-indigo-800/50 dark:text-white" : "bg-primary-100/50 dark:bg-slate-500/50 text-primary-1100 dark:text-white")}>
            <h4 className="title text-xl text center ">{props.image.name}</h4>
          </div>
        )}
        <div
          className={
            "max-w-[500px] flex h-fit max-h-[400px] min-w-[300px] min-h-[280px] overflow-clip object-contain justify-center items-center rounded border-[1px]  bg-white/50 dark:bg-slate-50/20 " +
            (props.is_public ? "border-blue-800 dark:border-sky-300" : "border-primary-900 dark:border-white")
          }
        >
          <img onLoad={() => setImageLoading(false)} className={"object-contain pointer-events-none h-full " + (imageLoading ? "hidden" : "")} src={props.image.image} alt={props.image.name} />
          <div className={"w-full h-full flex justify-center items-center " + (imageLoading ? "" : "hidden")}>
            <svg className="w-[50%] h-[50%] text-primary-400 dark:text-primary-200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor" d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z">
                <animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" />
              </path>
            </svg>
          </div>
        </div>
        <div className="colors w-fit flex flex-col gap-2 items-center">
          {props.type === "CHARACTERS" ? (
            <ColorPicker
              errors={errors.color}
              allow_edit={editMode}
              selected={newColor}
              onSelect={(item: string) => {
                setNewColor(item);
              }}
            ></ColorPicker>
          ) : (
            <></>
          )}
          <p className="text-primary-1100 dark:text-white text-center">
            Submitted By:
            {getShownName(props.image.created_by).id.toString() === process.env.REACT_APP_OFFICIAL_USER_ID ? (
              <span className="text-primary-900 dark:text-primary-200 pl-1 flex gap-2 justify-center items-center">
                <Logo className="h-6 w-6" /> {getShownName(props.image.created_by).name}
              </span>
            ) : (
              <span className="text-green-900 dark:text-teal-200 pl-1 flex gap-2 justify-center items-center">
                <>{getShownName(props.image.created_by).name}</>
              </span>
            )}
          </p>
          <div className="text-primary-1100 dark:text-white justify-center flex gap-3">
            <p>Downloads: </p>
            <span className={"flex items-center gap-1 text-green-900 dark:text-teal-200"}>
              {props.image.likes}{" "}
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
            </span>
          </div>
        </div>
        {props.type === "CHARACTERS" || props.type === "MONSTERS" ? (
          <PowerSelector
            errors={errors.power}
            powers={newPowers}
            is_public={!editMode}
            editMode={editMode}
            onSelect={(items: ImageInterface[]) => {
              setNewPowers(items);
            }}
          ></PowerSelector>
        ) : (
          <></>
        )}

        {editMode ? (
          <TagInput
            value={props.image.tags}
            onChange={(list: string[]) => {
              setNewTags(list);
            }}
          ></TagInput>
        ) : (
          <>
            <p className="text-primary-1100 dark:text-white text-center">Tags:</p>
            <div className="w-full flex flex-wrap justify-center items-center gap-1">
              <>
                {props.image.tags.length ? (
                  <>
                    {newTags.map((str) => {
                      return (
                        <p key={str} className="text-primary-1100 dark:text-white text-center bg-primary-200 dark:bg-primary-1200 rounded-full py-1 px-2">
                          {str}
                        </p>
                      );
                    })}
                  </>
                ) : (
                  <p className="text-primary-600 italic font thin dark:text-primary-1100 text-center">none</p>
                )}
              </>
            </div>
          </>
        )}
        {props.is_public || !(state.id === getShownName(props.image.created_by).id) ? (
          <>{/*No Buttons are shown on the public page and only the creator can upload/edit/delete*/}</>
        ) : (
          <div className="buttons flex gap-2 justify-center items-center bg-gray-100 dark:bg-primary-1000 p-1 rounded w-fit">
            {props.is_public || !(state.id === getShownName(props.image.created_by).id) ? (
              <></>
            ) : (
              <Button
                onClick={() => {
                  if (editMode) {
                    cancelEdit();
                  }
                  setEditMode(!editMode);
                }}
                text={editMode ? "Cancel Edit" : "Edit"}
                icon={editMode ? "cancel-edit" : "edit"}
              ></Button>
            )}
            {!props.is_public && getShownName(props.image.created_by).id === state.id && !editMode ? (
              <>
                {props.image.is_private ? (
                  <Button
                    onClick={() => {
                      handleUpload();
                    }}
                    disabled={loading}
                    text="Upload Online"
                    icon="upload"
                  ></Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleUpload();
                    }}
                    disabled={loading}
                    text="Remove Upload"
                    icon="remove-upload"
                  ></Button>
                )}
              </>
            ) : (
              <></>
            )}
            {getShownName(props.image.created_by).id === state.id && !editMode ? (
              <Button
                text="Delete"
                icon="delete"
                onClick={() => {
                  handleDelete(props.image.id);
                }}
                disabled={loading}
              ></Button>
            ) : (
              <></>
            )}
            {editMode ? (
              <Button
                onClick={() => {
                  handleEdit();
                }}
                text="Submit Edit"
                icon="submit"
                disabled={loading}
              ></Button>
            ) : (
              <></>
            )}
          </div>
        )}

        {/* close button */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            cancelEdit();
            setEditMode(false);
            if (props.onClose) {
              props.onClose();
            }
          }}
          className="h-10 w-10 absolute top-0 right-0 text-gray-700 hover:text-gray-500 dark:hover:text-primary-1100 dark:text-white cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        {/* favourite star */}
        <svg
          onClick={() => {
            if (props.onFavourite) {
              props.onFavourite();
            }
          }}
          xmlns="http://www.w3.org/2000/svg"
          className={
            "h-10 w-10 md:h-16 md:w-16 absolute top-[-0.75rem] left-[-0.75rem] md:top-[-1.5rem] md:left-[-1.5rem] cursor-pointer text-yellow-200 dark:text-yellow-600 duration-100 ease-in-out " +
            (props.fakeIsLiked ? "fill-yellow-500" : "fill-transparent hover:fill-yellow-300/30")
          }
          viewBox="0 0 20 20"
          stroke="#000000"
          strokeWidth={1}
        >
          <path
            id="star"
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
          />
        </svg>
      </div>
      {globalErrors.length ? (
        <div className="errors text-red-500 w-full flex justify-center flex-col items-center">
          {globalErrors.map((err, i) => {
            return (
              <p key={i} className="font-thin text-center before:content-['_•_'] px-2 w-fit">
                {err.trim() === "" ? "Something went wrong. Please try again later." : err}
              </p>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
