import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import LicenseToken from "../auth/LicenseToken";
import Home from "../home";
import GameLink, { GameInterface } from "./GameLink";
import WordListChooser from "../wordlists/WordListChooser";
import { GAME_DATA } from "./gamedata";
import Banner from "./Banner";
import { stat } from "fs";
import Footer from "../home/Footer";
export default function Dashboard() {
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [games, setGames] = useState<GameInterface[]>([]);
  useEffect(() => {
    if (!localStorage.getItem("loggedInBefore")) {
      return;
    }
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });

            document.title = "Dashboard | whatstheword.io";
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
          navigate("/login");
          return;
        });
      return;
    } else {
      document.title = "Dashboard | whatstheword.io";
      if (localStorage.getItem("played_game") && state.favourite_wordlists.length > 0) {
        if (parseInt(localStorage.getItem("bannerShown") || "0") >= 100) {
          localStorage.setItem("bannerShown", "0");
        }
        localStorage.setItem("bannerShown", (parseInt(localStorage.getItem("bannerShown") || "0") + 1).toString());
      }
      setGames(GAME_DATA.filter((x: GameInterface) => x.internal_id !== 0));
    }
  }, [state.isAuthenticated, dispatch, navigate]);

  const showBanner = () => {
    //Show no wordlists created banner until they added some lists. Then if they have words and played a game, only then show the bug report banner
    if (state.isAuthenticated && state.favourite_wordlists.length === 0) {
      //if they logged in, created an account, but didnt make a wordlist
      return <Banner>You have no wordlists created! Click the button below to add some words! The games will use the words you select.</Banner>;
    } else if (!state.used_pro_plan_trial && !state.used_premium_plan_trial && !state.has_active_plan && localStorage.getItem("played_game")) {
      // if they played a game, but did not use the trial
      return (
        <Banner>
          Hey {state.display_name || state.username}! You can play the other games by starting a trial. No payment info needed! its just one click!{" "}
          <Link className="underline text-blue-700 hover:text-blue-500" to="/plans">
            Click Here!
          </Link>
        </Banner>
      );
    } else if ((state.used_pro_plan_trial || state.used_premium_plan_trial || state.has_active_plan) && localStorage.getItem("played_game") && parseInt(localStorage.getItem("bannerShown") || "0") < 5) {
      //if they got either trial and played a game, but not if the banner was shown 5 times.
      //after 100 tries the banner gets shown 5 more times etc
      return (
        <Banner>
          Thank you for playing our games! If you manage to find any bugs or issues, please let us know using the{" "}
          <Link to="/contact-us" className="underline text-blue-700 hover:text-blue-500">
            contact us form
          </Link>{" "}
          or email us at{" "}
          <a href="mailto:bugs@whatstheword.io" className="underline text-blue-700 hover:text-blue-500">
            bugs@whatstheword.io
          </a>
          ! Thank you!
        </Banner>
      );
    }
    return <></>;
  };

  if (!localStorage.getItem("loggedInBefore") && !state.isAuthenticated) {
    return <Home></Home>;
  } else if (!state.isAuthenticated) {
    return (
      <div className="loading w-full h-full flex justify-center items-center dark:bg-slate-900">
        <h2 className="font-bold text-xl text-primary-700 dark:text-primary-300">LOADING...</h2>
      </div>
    );
  }
  return (
    <div className="bg-slate-50 dark:bg-slate-800 w-full h-full">
      <div className="h-20 flex relative justify-center items-center bg-slate-200 dark:bg-slate-700">
        <h3 className="text-lg text-primary-500 dark:text-primary-100 ">Welcome {state.display_name || state.username}!</h3>
        <div className="h-full w-fit absolute right-0">
          <LicenseToken type={state.current_plan_type}></LicenseToken>
        </div>
      </div>
      {showBanner()}
      <WordListChooser
        lists={state.favourite_wordlists
          .map((x) => {
            x.words.sort();
            return x;
          })
          .sort((a, b) => a.id - b.id)}
        chosen={state.selected_wordlist}
      />
      <div className="games-container flex flex-wrap gap-4 p-6 w-full justify-center bg-slate-50 dark:bg-slate-800 ">
        {games
          .filter((x) => x.has_template)
          .sort((a, b) => {
            let a_score = (a.needs_premium ? 2 : 0) + (a.needs_pro ? 1 : -1);
            let b_score = (b.needs_premium ? 2 : 0) + (b.needs_pro ? 1 : -1);
            return a_score - b_score;
          })
          .map((game, i) => (
            <GameLink user_plan={state.current_plan_type} key={i} {...game} />
          ))}
      </div>
      <Footer />
    </div>
  );
}
