import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import OnlineButton from "../basic/OnlineButton";
import WordListChooser, { wordlistToString } from "./WordListChooser";
import WordListItem, { WordListInterface } from "./WordList";
import WordListEditor from "./WordListEditor";
import SearchInput from "../basic/SearchInput";

export default function WordListManager() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(UserContext);
  const [hasMore, setHasMore] = useState(false);
  const [loadedWordlists, setLoadedWordlists] = useState(0);
  const [searchQuerySet, setSearchQuerySet] = useState([] as WordListInterface[]);
  useEffect(() => {
    document.title = "My Wordlists | whatstheword.io";
    setLoading(true);
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
          navigate("/login");
          return;
        });
      return;
    }
    if (state.isAuthenticated && !state.has_valid_email) {
      navigate("/activate/reminder");
      return;
    }
    if (state.favourite_wordlists.length > 50) {
      setHasMore(true);
      setLoadedWordlists(50);
    } else {
      setLoadedWordlists(state.favourite_wordlists.length);
    }

    setLoading(false);
  }, [state.isAuthenticated, dispatch, navigate]);

  const handleDelete = (id: number, list: string[], from_public = false) => {
    if (window.confirm("Are you sure you want to delete this wordlist:\n " + wordlistToString(list))) {
      axios.delete(`/api/wordlists/${id}/`).then((res) => {
        dispatch({
          type: AuthTypes.UpdateUserData,
          payload: { ...state, favourite_wordlists: state.favourite_wordlists.filter((x) => x.id !== id) },
        });
      });
    }
  };

  const handlePublish = (id: number) => {
    axios.post(`/api/wordlists/${id}/publish/`).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: AuthTypes.UpdateUserData,
          payload: {
            ...state,
            favourite_wordlists: state.favourite_wordlists.map((x) => {
              if (x.id === id) {
                x.has_been_published = true;
              }
              return x;
            }),
          },
        });
      }
    });
  };
  const [editMode, setEditMode] = useState(false);
  const [wordlistToEdit, setWordlistToEdit] = useState({} as WordListInterface);
  const handleEdit = (list: WordListInterface) => {
    setEditMode(true);
    setWordlistToEdit(list);
  };

  const [resetWords, setResetWords] = useState(false);
  const [data, setData] = useState([] as WordListInterface[]);

  const loadMore = () => {
    if (loadedWordlists + 50 > (currentlySearching ? searchQuerySet.length : state.favourite_wordlists.length)) {
      setHasMore(false);
    }
    setLoadedWordlists(loadedWordlists + 50);
  };

  useEffect(() => {
    if (state.isAuthenticated) {
      setData(state.favourite_wordlists);
      setLoadedWordlists(state.favourite_wordlists.length);
    }
  }, [state.favourite_wordlists, resetWords, state.isAuthenticated]);

  const [loading, setLoading] = useState(false);
  const [currentlySearching, setCurrentlySearching] = useState(false);
  const startSearch = (term: string) => {
    // we dont actually need to search online of this. since its all text data, we receive all of it in one go anyway
    let terms = term.split(" ");
    setCurrentlySearching(true);
    let querySet = state.favourite_wordlists.filter((x) => {
      return terms.some((term) => {
        return x.words.some((word) => word.match(new RegExp(term, "gi"))) || x.theme.match(new RegExp(term, "gi"));
      });
    });
    setSearchQuerySet(querySet);
    setHasMore(querySet.length > 50);
    setLoadedWordlists(50);
  };

  const endSearch = () => {
    setLoading(false);
    setCurrentlySearching(false);
    setResetWords(!resetWords);
    setSearchQuerySet([]);
  };
  const handleWordlistSelect = (list: WordListInterface) => {
    if (state.max_sessions_allowed > 1 && !state.always_save_wordlist_across_devices) {
      //if more than one person is using this account, we dont want all sessions to now use the selected words
      localStorage.setItem(state.id + "_swl", list.id.toString());
      dispatch({ type: AuthTypes.UpdateUserData, payload: { ...state, locallySelectedWordlist: list.id } });
      return;
    }

    axios
      .post(`/api/wordlists/${list.id}/select/`)
      .then((res) => {
        dispatch({ type: AuthTypes.UpdateChosenWordList, payload: { selected_wordlist: list.id } });
      })
      .catch((err) => {});
  };

  return (
    <div className="w-full bg-stone-50 dark:bg-slate-900 h-full">
      <div className="min-h-[5rem] gap-4 p-3 flex flex-col md:flex-row relative justify-center items-center bg-slate-200 dark:bg-slate-700">
        <p className="text-lg text-primary-500 dark:text-primary-100 font-bold">Manage Your Wordlists Here!</p>
        <div className="h-full w-fit relative md:absolute right-0 pr-4 flex justify-center items-center">
          <OnlineButton
            onClick={() => {
              navigate("/public-wordlists");
            }}
          ></OnlineButton>
        </div>
      </div>

      <div className="wordlists w-full flex flex-col justify-center items-center bg-stone-50 dark:bg-slate-900">
        <div className="w-full pt-5 bg-stone-50 dark:bg-slate-900 h-full">
          {data.length ? (
            <div className="h-8 w-full bg-grey-100 dark:bg-slate-900">
              <h5 className="text-center text-xs md:text-sm lg:text-base font-thin text-primary-800 dark:text-primary-200">This is your current wordlist used in the games you play:</h5>
            </div>
          ) : (
            <></>
          )}

          <WordListChooser key="wordlist_in_dashboard" lists={state.favourite_wordlists || []} chosen={state.selected_wordlist || 0} />
        </div>
        <div className="user-wordlists w-full gap-2 p-5 flex justify-center items-center flex-col">
          <div className="header w-full bg-gray-200 dark:bg-gray-800 flex flex-col md:flex-row justify-around items-center rounded p-2 pt-3 gap-5">
            <div className="flex justify-center items-center gap-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 text-primary-1200 dark:text-black-900 bg-primary-200 dark:bg-blue-100 rounded-full h-fit p-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h4 className="font-medium self text-lg md:text-xl lg:text-2xl text-primary-1200 dark:text-white">Your Wordlists</h4>
            </div>
            <div className="flex-1 w-full md:w-auto max-w-[500px]">
              <SearchInput
                label="Search Your Wordlists"
                onClear={() => {
                  endSearch();
                }}
                onSubmit={(term: string) => {
                  startSearch(term);
                }}
                searching={currentlySearching}
              ></SearchInput>
            </div>
          </div>
          {loading ? (
            <svg className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] text-primary-600 dark:text-primary-800" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor" d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z">
                <animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" />
              </path>
            </svg>
          ) : (
            <>
              {(currentlySearching ? searchQuerySet : data)
                ?.slice(0, loadedWordlists)
                .sort((a, b) => a.likes - b.likes)
                .map((list) => {
                  list.words.sort();
                  return (
                    <WordListItem
                      is_public={false}
                      list={list}
                      key={list.id}
                      listAsString={wordlistToString(list.words)}
                      onEdit={() => {
                        handleEdit(list);
                      }}
                      onDelete={() => {
                        handleDelete(list.id, list.words);
                      }}
                      onPublish={() => {
                        if (!list.has_been_published) {
                          handlePublish(list.id);
                        }
                      }}
                      onSelect={() => {
                        handleWordlistSelect(list);
                      }}
                    ></WordListItem>
                  );
                })}
              {data.length === 0 ? (
                <div className="w-full h-16 flex justify-center items-center  border-b-[1px] mt-1 pt-2 border-primary-500 dark:border-primary-200">
                  <h4 className="text-thin text-center text-primary-500/50">{currentlySearching ? "Nothing found." : "You have no wordlists. Create one above!"}</h4>
                </div>
              ) : (
                <></>
              )}
              {hasMore ? (
                <button
                  onClick={() => {
                    loadMore();
                  }}
                  className={
                    "border-primary-600 hover:bg-primary-200/50 px-8 py-4 text-md text-primary-600  dark:border-primary-100 dark:text-primary-100 dark:hover:bg-white/25 inline-block  border-2 font-medium  leading-tight uppercase rounded transition duration-150 ease-in-out"
                  }
                >
                  LOAD MORE
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>

      {editMode ? <WordListEditor list={wordlistToEdit} onClose={() => setEditMode(false)}></WordListEditor> : <></>}
    </div>
  );
}
