import { useNavigate } from "react-router-dom";
import { LicenseType } from "src/utils/UserContext";
import { calculateDaysRemaining } from "../orders/PlanView";

export interface LicenseObject {
  activate_this_plan_next: boolean;
  date_purchased: string;
  duration: number;
  id: string;
  is_active: true;
  is_expired: false;
  is_trial: false;
  is_volume: false;
  license_type: string;
  number_of_devices: number;
  time_activated: string;
  user_username: string;
  renew_time: string;
  will_auto_renew: boolean;
}

interface props {
  type: LicenseType;
  username: String;
  expiry_timestamp?: string;
  full_info?: LicenseObject;
  isSmall?: boolean;
}

export default function LicenseBadge(props: props) {
  const navigate = useNavigate();

  const getVerboseTypeName = (type: LicenseType) => {
    switch (type) {
      case "PRE":
        return "Premium";
      case "PRO":
        return "Pro";
      case "VIP":
        return "VIP";
      default:
        return "Basic";
    }
  };
  const getLicenseStyling = (type: LicenseType) => {
    switch (type) {
      case "PRE":
        return "from-emerald-400 to-green-300 border-green-800 text-green-800 shadow-green-200";
      case "PRO":
        return "from-blue-400 to-sky-300 border-blue-600 text-blue-600 shadow-blue-200";
      case "VIP":
        return "from-yellow-300  to-amber-200 border-yellow-700 text-yellow-700 shadow-orange-100";
      default:
        return "from-neutral-400 to-gray-300 border-gray-600 text-gray-600 shadow-white";
    }
  };
  return (
    <div
      onMouseEnter={() => {
        // setSmallOverride(false);
      }}
      onMouseLeave={() => {
        // setSmallOverride(true);
      }}
      onClick={() => {
        if (props.full_info) {
          navigate("/plan/" + props.full_info.id, {
            state: props.full_info,
          });
        } else {
          navigate("/plans");
        }
      }}
      className={
        "relative duration-100 cursor-pointer select-none overflow-hidden border-[1px] shadow-inner  rounded-xl flex flex-col justify-center items-center bg-gradient-to-t " +
        getLicenseStyling(props.type) +
        (props.full_info && props.full_info.is_expired ? " opacity-50 " : "") +
        (props.isSmall ? " h-[100px] w-[150px]" : " h-[180px] w-[260px] ")
      }
    >
      <h3 className={"plan-name  font-bold text-center " + (props.isSmall ? "text-lg " : "text-2xl ")}>
        {getVerboseTypeName(props.type)} {props.full_info && props.full_info.is_trial ? "Trial" : "Plan"}
      </h3>
      <p className={"font-bold text-center" + (props.isSmall ? " hidden" : "")}>Licensed to: {props.username}</p>
      {props.full_info ? (
        <>
          {props.full_info.is_active ? (
            <>
              <div className={"absolute top-0 left-0 bg-white opacity-80 " + (props.isSmall ? " m-1 p-[1px] px-1 text-xs rounded-md " : "m-2 text-sm px-1 py-0.5 rounded-full ")}>Active</div>
            </>
          ) : (
            <></>
          )}
          <p className="font-thin">
            {props.isSmall || props.full_info.will_auto_renew ? "" : "Time Left:"} {props.full_info.will_auto_renew ? "" : calculateDaysRemaining(props.full_info) + " days"}
          </p>

          {props.full_info.is_expired ? (
            <>
              <div className={"absolute top-0 right-0 rounded-bl-lg bg-gray-500  opacity-50 " + (props.isSmall ? " p-[1px] px-1 text-xs" : " p-2 py-1.5 text-sm")}>
                <p className="text-gray-200 ">Expired</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {!props.full_info.is_active && !props.full_info.is_expired ? (
            <>
              <div className={"absolute top-0 left-0 bg-white opacity-80 " + (props.isSmall ? " m-1 p-[1px] px-1 text-xs rounded-md " : "m-2 text-sm px-1 py-0.5 rounded-full ")}>Inactive</div>
            </>
          ) : (
            <></>
          )}
          {props.full_info.activate_this_plan_next && !props.isSmall ? <div className="rounded-full px-1 py-0.5 text-xs absolute top-0 right-0 m-2 opacity-80">Will Be Activated Next</div> : <></>}
          <p className={"absolute bottom-0  m-1 " + (props.isSmall ? "text-[0.4rem]" : "text-[0.5rem]")}>
            {props.isSmall ? "" : "License key:"} {props.full_info.id}
          </p>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
