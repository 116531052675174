import { Link } from "react-router-dom";
import { LicenseType } from "src/utils/UserContext";

interface props {
  type: LicenseType;
}

export default function LicenseToken(props: props) {
  const getVerboseTypeName = (type: LicenseType) => {
    switch (type) {
      case "PRE":
        return "Premium";
      case "PRO":
        return "Pro";
      case "VIP":
        return "VIP";
      default:
        return "Basic";
    }
  };
  const getLicenseStyling = (type: LicenseType) => {
    switch (type) {
      case "PRE":
        return "fill-green-300 stroke-green-800 text-green-800";
      case "PRO":
        return "fill-blue-300 stroke-blue-600 text-blue-600";
      case "VIP":
        return "fill-yellow-300 stroke-yellow-700 text-yellow-700";
      default:
        return "fill-neutral-300 stroke-gray-600 text-gray-600";
    }
  };

  return (
    <Link to="/profile" className={"h-full w-[80px] flex justify-center relative items-center cursor-pointer " + getLicenseStyling(props.type)}>
      <svg viewBox="0 2 10 6" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="filter1">
            <feGaussianBlur in="SourceAlpha" stdDeviation=".15" result="blur" />
            <feOffset in="blur" dx="0.1" dy="0.1" result="offsetBlur" />
            <feFlood floodColor="#555555" floodOpacity="0.5" result="offsetColor" />
            <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetBlur" />
          </filter>
        </defs>
        <g>
          <path
            filter="url(#filter1)"
            clipPathUnits="objectBoundingBox"
            strokeWidth="0.3"
            d="m0.36615,4.49564c-0.19835,0.21295 -0.19922,0.53936 -0.00144,0.7535l1.97009,2.14082c0.10719,0.11687 0.2599,0.18355 0.41996,0.184l5.91431,0.01648c0.15082,0 0.29558,-0.05821 0.40248,-0.16276c0.10705,-0.10469 0.16729,-0.24681 0.16773,-0.39532l0.01156,-4.27065c0,-0.15459 -0.06284,-0.29463 -0.16541,-0.39621c-0.10272,-0.10143 -0.24473,-0.16454 -0.40176,-0.16499l-5.91417,-0.01648c-0.16007,0 -0.31291,0.06549 -0.42112,0.18162l-1.98222,2.12998z"
          />
          <path
            clipPathUnits="objectBoundingBox"
            strokeWidth="0.3"
            d="m0.36615,4.49564c-0.19835,0.21295 -0.19922,0.53936 -0.00144,0.7535l1.97009,2.14082c0.10719,0.11687 0.2599,0.18355 0.41996,0.184l5.91431,0.01648c0.15082,0 0.29558,-0.05821 0.40248,-0.16276c0.10705,-0.10469 0.16729,-0.24681 0.16773,-0.39532l0.01156,-4.27065c0,-0.15459 -0.06284,-0.29463 -0.16541,-0.39621c-0.10272,-0.10143 -0.24473,-0.16454 -0.40176,-0.16499l-5.91417,-0.01648c-0.16007,0 -0.31291,0.06549 -0.42112,0.18162l-1.98222,2.12998z"
          />
        </g>
      </svg>
      <div className="absolute h-full w-[80px] flex justify-center items-center">
        <p className={"font-bold italic text-center align-middle m-0 opacity-60 p-1 " + ([LicenseType.PRO, null].includes(props.type) ? "text-sm" : "text-xs")}>{getVerboseTypeName(props.type)} Plan</p>
      </div>
    </Link>
  );
}
