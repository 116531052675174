import axios from "axios";
import React, { useState } from "react";
import { InputGroup } from "../basic/InputGroup";

export default function ContactFormPage() {
  const [subject, setSubject] = useState("");
  const [subjectErrors, setSubjectErrors] = useState([] as string[]);
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState([] as string[]);
  const [text, setText] = useState("");
  const [globalErrors, setGlobalErrors] = useState([] as string[]);
  const [success, setSuccess] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setGlobalErrors([] as string[]);
    let hasErrors = false;
    if (!email) {
      setEmailErrors(["Please give us a way to contact you."]);
      hasErrors = true;
    }
    if (!text) {
      setGlobalErrors(["Please write something in the message area."]);
      hasErrors = true;
    }
    if (!subject) {
      setSubjectErrors(["Please give us the topic so we can direct it to the right place."]);
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }
    setButtonDisabled(true);
    let fd = new FormData();
    fd.set("email", email);
    fd.set("subject", subject);
    fd.set("body", text);
    axios
      .post("/api/message/", fd)
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        for (let key in err.response.data) {
          let errors: string[] = [];
          if (typeof err.response.data[key] === "string") {
            errors = [err.response.data[key]];
          } else {
            errors = err.response.data[key];
          }
          switch (key) {
            case "email":
            case "body":
            case "subject":
              break;
            default:
              setGlobalErrors(errors);
          }
        }
        setButtonDisabled(false);
      });
  };

  return (
    <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900 flex justify-center items-center">
      <form onSubmit={handleSubmit} className="rounded-md shadow-sm dark:bg-primary-1200 shadow-primary-500 dark:shadow-primary-1100 p-5 m-3 flex flex-col gap-4 max-w-[450px]">
        <h4 className="text-lg font-bold text-center text-primary-1000 dark:text-primary-300">Fill out this form to send us a message!</h4>
        <InputGroup
          name="subject"
          type="text"
          value={subject}
          placeholder=" "
          errors={subjectErrors}
          invalid={subjectErrors.length !== 0}
          label="Subject"
          required={true}
          submitted={false}
          onChange={(ev) => setSubject(ev.target.value)}
        ></InputGroup>
        <div>
          <textarea
            id="message"
            cols={30}
            rows={7}
            draggable={false}
            style={{ outline: "none" }}
            value={text}
            onChange={(ev) => {
              setText(ev.target.value);
            }}
            className="border-transparent focus:ring-[1px] p-2.5 w-full text-sm text-slate-900 bg-slate-200 rounded-md outline-primary-500 ring-primary-500 dark:bg-primary-1100 dark:placeholder-primary-600 dark:text-white dark:ring-primary-500 "
            placeholder="Tell us something!"
          ></textarea>
        </div>
        <InputGroup
          name="email"
          type="text"
          value={email}
          placeholder=" "
          errors={emailErrors}
          invalid={emailErrors.length !== 0}
          label="Your email or how to contact you"
          required={true}
          submitted={false}
          onChange={(ev) => setEmail(ev.target.value)}
        ></InputGroup>
        {!success ? (
          <button
            type="submit"
            disabled={buttonDisabled}
            className="text-white font-thin mt-10 disabled:bg-gray-500 disabled:text-gray-200 disabled:pointer-events-none focus:ring-1 rounded-md text-md lg:text-lg xl:text-xl w-full sm:w-auto px-5 py-1.5 text-center self-center justify-self-center bg-primary-700 hover:bg-primary-600 focus:ring-primary-300 focus:outline-none"
          >
            Send a message!
          </button>
        ) : (
          <div className="w-full p-5 flex justify-center items-center flex-col gap-4">
            <p className="font-bold text-emerald-600 dark:text-emerald-200">Message Sent!</p>
            <p className="text-center text-emerald-600 dark:text-emerald-200"> We will try to respond to you as soon as we can. Thank you!</p>
          </div>
        )}
        {globalErrors.length ? (
          <div className="w-full flex flex-col p-5">
            {globalErrors.map((err, i) => {
              return (
                <p className="text-red-500 dark:text-red-200 font-thin text-sm before:content-['•_']" key={i}>
                  {err.trim() === "" ? "Something went wrong. Please try again later." : err}
                </p>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
}
