import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import { wordlistToString } from "./WordListChooser";
import WordListItem, { WordListInterface } from "./WordList";
import SearchInput from "../basic/SearchInput";

interface props {
  wordlists?: WordListInterface[];
}
export default function PublicWordlistDashboard(props: props) {
  const { state, dispatch } = useContext(UserContext);
  const [wordlists, setWordlists] = useState<WordListInterface[]>([]);

  const [loading, setLoading] = useState(false);
  const [currentlySearching, setCurrentlySearching] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [resetWords, setResetWords] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loadedWordLists, setLoadedWordLists] = useState(0);

  useEffect(() => {
    setLoading(true);
    if (props.wordlists) {
      setWordlists(props.wordlists);
      setLoading(false);
      return;
    }
    axios.get("/api/wordlists/").then((res) => {
      if (res.status === 200) {
        setWordlists(res.data.results);
        setLoading(false);
      }
      if (res.data.results.length < res.data.count) {
        setHasMore(true);
      }
      setLoadedWordLists(res.data.results.length);
    });
  }, [resetWords]);

  const handleDelete = (id: number, list: string[]) => {
    if (window.confirm("Are you sure you want to remove this wordlist from the public collection: \n" + wordlistToString(list))) {
      axios.delete(`/api/wordlists/${id}/`).then((res) => {
        axios.get("/api/wordlists/").then((res) => {
          if (res.status === 200) {
            setWordlists(res.data.results);
          }
        });
      });
    }
  };

  const handleFavourite = (id: number) => {
    axios.post(`/api/wordlists/${id}/favourite/`).then((res) => {
      dispatch({
        type: AuthTypes.UpdateUserData,
        payload: { ...state, favourite_wordlists: state.favourite_wordlists.concat([res.data]) },
      });
    });
  };
  const startSearch = (term: string) => {
    if (term.trim() === "") {
      return;
    }
    setSearchTerm(term);
    setCurrentlySearching(true);
    setLoading(true);
    setLoadedWordLists(0);
    axios
      .post("/api/wordlists/search/", { query: term, public: true })
      .then((res) => {
        setWordlists(res.data.results);
        setLoadedWordLists(res.data.results.length);
        setHasMore(res.data.results.length < res.data.count);

        setLoading(false);
      })
      .catch((err) => {});
  };

  const endSearch = () => {
    setLoading(false);
    setCurrentlySearching(false);
    setResetWords(!resetWords);
  };

  const loadMore = () => {
    setHasMore(false);
    if (currentlySearching) {
      axios.post("/api/wordlists/search/?limit=50&offset=" + loadedWordLists, { query: searchTerm, public: true }).then((res) => {
        setWordlists(wordlists.concat(res.data.results));
        if (res.data.results.length + loadedWordLists < res.data.count) {
          setHasMore(true);
        }
        setLoadedWordLists(res.data.results.length + loadedWordLists);
      });
    } else {
      axios.get("/api/wordlists/?limit=50&offset=" + loadedWordLists).then((res) => {
        setWordlists(wordlists.concat(res.data.results));
        if (res.data.results.length + loadedWordLists < res.data.count) {
          setHasMore(true);
        }
        setLoadedWordLists(res.data.results.length + loadedWordLists);
      });
    }
  };
  return (
    <div className="user-wordlists w-full p-5  flex justify-center items-center flex-col">
      <div className="w-full bg-blue-100 dark:bg-blue-900 flex flex-col md:flex-row justify-around items-center rounded p-5 gap-5">
        <div className="flex justify-center items-center gap-4">
          <svg xmlns="http://www.w3.org/2000/svg" className=" w-12 text-primary-1200 dark:text-black-900 bg-primary-200 dark:bg-blue-100 rounded-full h-fit p-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
          </svg>
          <h4 className="font-medium self text-lg md:text-xl lg:text-2xl text-primary-1200 dark:text-white">Public Wordlists</h4>
        </div>
        <div className="flex-1 w-full md:w-auto max-w-[500px]">
          <SearchInput
            label="Search Your Wordlists"
            onClear={() => {
              endSearch();
            }}
            onSubmit={(term: string) => {
              startSearch(term);
            }}
            searching={currentlySearching}
          ></SearchInput>
        </div>
      </div>
      {loading ? (
        <svg className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] text-primary-600 dark:text-primary-800" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor" d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z">
            <animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" />
          </path>
        </svg>
      ) : (
        <>
          {wordlists?.map((list) => {
            list.words.sort();
            return (
              <WordListItem
                is_public={true}
                list={list}
                key={list.id}
                listAsString={wordlistToString(list.words)}
                onDelete={() => {
                  handleDelete(list.id, list.words);
                }}
                onFavourite={() => {
                  handleFavourite(list.id);
                }}
              ></WordListItem>
            );
          })}
        </>
      )}{" "}
      {hasMore ? (
        <button
          onClick={() => {
            loadMore();
          }}
          className={
            "border-primary-600 my-5 hover:bg-primary-200/50 px-8 py-4 text-md text-primary-600  dark:border-primary-100 dark:text-primary-100 dark:hover:bg-white/25 inline-block  border-2 font-medium  leading-tight uppercase rounded transition duration-150 ease-in-out"
          }
        >
          LOAD MORE
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
