import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import BlackBgBackdrop from "../basic/BlackBgBackdrop";
import SearchInput from "../basic/SearchInput";
import Gallery from "./Gallery";
import ImageHelpText from "./ImageHelpText";
export type ImageType = "CHARACTERS" | "MONSTERS" | "PROJECTILES" | "BACKGROUNDS";

interface props {
  type: ImageType;
  isPublic: Boolean;
}
export interface ImageInterface {
  created_by: {
    id: number;
    username: string;
    display_name: string | null | undefined;
  };
  created_on: string;
  for_game: number;
  id: number;
  image: string;
  include_in_default: boolean;
  is_private: boolean;
  keep_out_of_list: boolean;
  likes: number;
  name: string;
  thumbnail: string;
  color: string | undefined;
  power: ImageInterface[];
  tags: string[];

  //handled here by frontend
  is_liked?: boolean | false;
}

interface axiosResponse {
  count: number;
  results: ImageInterface[];
  next: string;
  previous: string;
}

export function getShownName(user_data: { id: number; username: string; display_name: string | null | undefined }) {
  const { id, username, display_name } = user_data;
  if (display_name === null || display_name === undefined || display_name === "") {
    return {
      id: id,
      name: username,
    };
  } else {
    return {
      id: id,
      name: display_name,
    };
  }
}

export type UserImageAttr = "favourite_monsters" | "favourite_projectiles" | "favourite_characters" | "favourite_backgrounds";
//used to reduce number of calls to backend
export type UserImageInterfaceAttr = "favourite_monsters_images" | "favourite_projectiles_images" | "favourite_characters_images" | "favourite_backgrounds_images";
export type CacheBooleanAttr = "cached_monsters_images" | "cached_projectiles_images" | "cached_characters_images" | "cached_backgrounds_images";
export type FavouritesCached = "favourite_monsters_cached" | "favourite_projectiles_cached" | "favourite_characters_cached" | "favourite_backgrounds_cached";
export type TotalImages = "total_monsters" | "total_characters" | "total_projectiles" | "total_backgrounds";
export default function ImagePicker(props: props) {
  const [data, setData] = useState<ImageInterface[]>([] as ImageInterface[]);
  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [receivedData, setReceivedData] = useState(false);
  const [currentlyReceivingData, setCurrentlyReceivingData] = useState(false);
  const convertToUserAttr = (type: ImageType) => {
    return ("favourite_" + type.toLowerCase()) as UserImageAttr;
  };
  const convertToUserImageAttr = (type: ImageType) => {
    return ("favourite_" + type.toLowerCase() + "_images") as UserImageInterfaceAttr;
  };
  const convertToCacheBooleanAttr = (type: ImageType) => {
    return ("cached_" + type.toLowerCase() + "_images") as CacheBooleanAttr;
  };
  const convertToFavouriteNumAttr = (type: ImageType) => {
    return ("favourite_" + type.toLowerCase() + "_cached") as FavouritesCached;
  };
  const convertToTotalImageAttr = (type: ImageType) => {
    return ("total_" + type.toLowerCase() + "_cached") as TotalImages;
  };
  const favouriteImage = convertToUserImageAttr(props.type);
  const favourite = convertToUserAttr(props.type);
  const cachedBoolean = convertToCacheBooleanAttr(props.type);
  const favouritesCached = convertToFavouriteNumAttr(props.type);
  const totalImages = convertToTotalImageAttr(props.type);
  const [showHelpText, setShowHelpText] = useState(false);
  const [currentlySearching, setCurrentlySearching] = useState(false);
  const [resetImages, setResetImages] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (props.isPublic && !loading) {
      //slightly different handling of online images. we dont want to cache them

      setLoading(true);
      axios.get("/api/images/" + props.type.toLowerCase() + "/").then((res: { data: axiosResponse }) => {
        res.data.results = res.data.results.map((image) => {
          return {
            ...image,
            is_liked: state[favourite]?.includes(image.id),
          };
        });
        setImagesLoaded(res.data.results.length);
        setHasMore(res.data.results.length < res.data.count);
        setData(res.data.results);
        setLoading(false);
      });
    }
  }, [props.type, state.isAuthenticated, props.isPublic, resetImages]);
  useEffect(() => {
    if (!state.isAuthenticated || loading) {
      return;
    }
    if (!props.isPublic) {
      // console.log(state[favouritesCached], state[favourite]);
      if ((!receivedData && !state[cachedBoolean] && !currentlyReceivingData) || !(state[favouritesCached].every((x) => state[favourite].includes(x)) && state[favourite].every((x) => state[favouritesCached].includes(x)))) {
        //if the lengths of the cached favourite number doesnt match the favourites we have, then were definitely not updated. otherwise its probably handled by the caching system
        //so we dont update the images. a refresh of the page should still refresh it if needed
        setLoading(true);
        setCurrentlyReceivingData(true);
        // console.log("getting " + props.type);
        axios.get("/api/images/" + props.type.toLowerCase() + "/mine/").then((res: { data: axiosResponse }) => {
          res.data.results = res.data.results.map((image) => {
            return {
              ...image,
              is_liked: (state[favourite] || []).includes(image.id),
            };
          });
          setTotal(res.data.count);
          setImagesLoaded(res.data.results.length);
          setHasMore(res.data.results.length < res.data.count);
          let payload = { ...state, [favouriteImage]: res.data.results, [favouritesCached]: state[favourite], [cachedBoolean]: true, [totalImages]: res.data.count };
          dispatch({ type: AuthTypes.UpdateUserData, payload: payload });
          setData(res.data.results);
          setTimeout(() => {
            //something here triggers the useEffect too quickly, so the cache uses the old state. this fixes it
            setLoading(false);
            setReceivedData(true);
          }, 100);
        });
      } else if (state[favouriteImage]) {
        // console.log(state[favouriteImage]);
        // console.log("using cahced " + props.type);
        setData(state[favouriteImage]);
        setTotal(state[totalImages]);
        setImagesLoaded(state[favouriteImage].length);
        setHasMore(state[totalImages] > state[favouriteImage].length);
      }
    }
  }, [props.type, state.isAuthenticated, props.isPublic, resetImages, state[favouritesCached]]);

  const loadMoreImages = () => {
    setCurrentlyReceivingData(true);
    setHasMore(false);
    function getAxiosUrl(isPublic: boolean, isSearch: boolean) {
      if (isPublic && !isSearch) {
        return axios.get("/api/images/" + props.type.toLowerCase() + `/?limit=50&offset=${imagesLoaded}`);
      } else if (!isPublic && !isSearch) {
        return axios.get("/api/images/" + props.type.toLowerCase() + `/mine/?limit=50&offset=${imagesLoaded}`);
      } else {
        return axios.post("/api/images/" + props.type.toLowerCase() + `/search/?limit=50&offset=${imagesLoaded}`, { query: searchTerm, public: isPublic });
      }
    }
    if (props.isPublic) {
      getAxiosUrl(true, currentlySearching).then((res: { data: axiosResponse }) => {
        res.data.results = res.data.results.map((image) => {
          return {
            ...image,
            is_liked: (state[favourite] || []).includes(image.id),
          };
        });
        setTotal(res.data.count);
        setImagesLoaded(imagesLoaded + res.data.results.length);
        setHasMore(imagesLoaded + res.data.results.length < res.data.count);
        setData(data.concat(res.data.results));
        setTimeout(() => {
          //something here triggers the useEffect too quickly, so the cache uses the old state. this fixes it
          setReceivedData(true);
        }, 100);
      });
    } else {
      getAxiosUrl(false, currentlySearching).then((res: { data: axiosResponse }) => {
        res.data.results = res.data.results.map((image) => {
          return {
            ...image,
            is_liked: (state[favourite] || []).includes(image.id),
          };
        });
        setTotal(res.data.count);
        setImagesLoaded(imagesLoaded + res.data.results.length);
        setHasMore(imagesLoaded + res.data.results.length < res.data.count);
        if (currentlySearching) {
          setData(data.concat(res.data.results));
        } else {
          let payload = { ...state, [favouriteImage]: state[favouriteImage].concat(res.data.results), [favouritesCached]: state[favourite], [cachedBoolean]: true, [totalImages]: imagesLoaded + res.data.results.length };
          dispatch({ type: AuthTypes.UpdateUserData, payload: payload });
          setData(state[favouriteImage].concat(res.data.results));
        }
        setTimeout(() => {
          //something here triggers the useEffect too quickly, so the cache uses the old state. this fixes it
          setReceivedData(true);
        }, 100);
      });
    }
  };

  const getHelpMessage = () => {
    if (props.isPublic) {
      return "Simply click the star to add them to your games!";
    }
    if (state[favourite] && state[favourite].length === 1) {
      return "You must have at least one image favourited, you wont be able to unfavourite this last one.";
    }
    if (state.current_plan_type === null) {
      return "You can search online for " + props.type.toLowerCase() + " others posted, or get a Pro plan to create your own!";
    }
    return "The images with a yellow star will appear in your games.";
  };
  const startSearch = (term: string) => {
    if (term.trim() === "") {
      return;
    }
    setCurrentlySearching(true);
    setLoading(true);
    setSearchTerm(term);
    axios
      .post("/api/images/" + props.type.toLowerCase() + "/search/", { query: term, public: props.isPublic })
      .then((res: { data: axiosResponse }) => {
        res.data.results = res.data.results.map((image) => {
          return {
            ...image,
            is_liked: (state[favourite] || []).includes(image.id),
          };
        });
        setData(res.data.results);
        setImagesLoaded(res.data.results.length);
        setTotal(res.data.count);
        setHasMore(res.data.results.length < res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const endSearch = () => {
    setLoading(false);
    setCurrentlySearching(false);
    setResetImages(!resetImages); // we can run all the useeffect code again by triggering this
  };

  return (
    <div className={"w-full flex flex-col h-[90vh] max-h-[600px] border-2 border-slate-300 dark:border-slate-600  object-contain " + (props.isPublic ? "bg-slate-50/30 dark:bg-blue-700/60" : "bg-slate-50 dark:bg-slate-800")}>
      <div className="header flex-col justify-center items-center gap-2">
        <div className={"header h-16  flex shrink justify-center items-center relative " + (props.isPublic ? " text-white bg-sky-400/80 dark:bg-blue-800/80" : "text-primary-1000 dark:text-primary-300")}>
          <h4 className="font-bolder text-sm lg:text-xl  w-full text-center">
            {props.isPublic ? "PUBLIC" : "YOUR"} {props.type} {props.isPublic ? `(showing ${imagesLoaded} total)` : `(showing ${imagesLoaded}/${total} total)`}
          </h4>
          <div className="absolute right-0 h-full w-[50px] md:w-[100px] flex flex-1 justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowHelpText(true)} className="h-5 w-5 lg:h-8 lg:w-8 dark:text-white hover:cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </div>
        <SearchInput label={"Search " + props.type.toLowerCase() + " by name or tags"} searching={currentlySearching} onSubmit={(term: string) => startSearch(term)} onClear={() => endSearch()}></SearchInput>
        <div className={"header h-10 w-full flex justify-center items-center " + (props.isPublic ? "bg-sky-300/30 dark:bg-sky-700/90 text-blue-500 dark:text-white" : "bg-gray-300 dark:bg-slate-600 text-primary-1000 dark:text-primary-200")}>
          <p className=" text-thin italic text-center text-xs">{getHelpMessage()}</p>
        </div>
      </div>
      <div className="flex-1 h-[90vh] max-h-full overflow-y-scroll object-contain">
        <Gallery
          loadMore={() => {
            loadMoreImages();
          }}
          forceReset={() => {
            setResetImages(!resetImages);
          }}
          images={data}
          loading={loading}
          contain={props.type !== "BACKGROUNDS"}
          type={props.type}
          is_public={props.isPublic}
          hasMore={hasMore}
        ></Gallery>
      </div>
      {showHelpText ? (
        <BlackBgBackdrop
          onClose={() => {
            setShowHelpText(false);
          }}
        >
          <ImageHelpText
            type={props.type}
            onClose={() => {
              setShowHelpText(false);
            }}
          ></ImageHelpText>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
    </div>
  );
}
