import { GameInterface } from "./GameLink";

/****
 *
 * This is the response of /api/games/
 * This was useful when the games would've
 * been dynamic with the plan accounts
 * but now they are shown to every user
 * so we don't need to call the api every time,
 * since these games will only be changed once in a while
 *
 */

export const GAME_DATA: GameInterface[] = [
  {
    internal_id: 0,
    name: "Any Game",
    internal_name: "placeholder",
    uses_words: false,
    uses_backgrounds: false,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-05-22T02:03:45.198736Z",
    has_template: false,
    needs_pro: false,
    needs_premium: false,
  },
  {
    internal_id: 1,
    name: "Word Search",
    internal_name: "wordsearch",
    uses_words: true,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-05-22T02:03:57.096113Z",
    has_template: true,
    needs_pro: false,
    needs_premium: false,
  },
  {
    internal_id: 2,
    name: "Fighters",
    internal_name: "fightinggame",
    uses_words: false,
    uses_backgrounds: true,
    uses_projectiles: true,
    uses_characters: true,
    uses_monsters: true,
    creation_time: "2022-05-22T02:04:07.366964Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 3,
    name: "Open The Safe",
    internal_name: "openthesafe",
    uses_words: true,
    uses_backgrounds: false,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-05-22T02:04:27.690872Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 4,
    name: "Wordbrain",
    internal_name: "wordbrain",
    uses_words: true,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-05-22T02:04:57.689180Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 5,
    name: "Hangman Battle",
    internal_name: "hangmanbattle",
    uses_words: true,
    uses_backgrounds: true,
    uses_projectiles: true,
    uses_characters: true,
    uses_monsters: false,
    creation_time: "2022-05-22T02:05:29.612587Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 6,
    name: "Prepositions",
    internal_name: "overunder",
    uses_words: false,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: true,
    uses_monsters: false,
    creation_time: "2022-05-22T02:06:56.883789Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 7,
    name: "Number Line",
    internal_name: "firstsecond",
    uses_words: false,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: true,
    uses_monsters: false,
    creation_time: "2022-05-22T02:07:24.082562Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 8,
    name: "Fighters Basic",
    internal_name: "fightinggamebasic",
    uses_words: false,
    uses_backgrounds: true,
    uses_projectiles: true,
    uses_characters: true,
    uses_monsters: true,
    creation_time: "2022-06-23T13:03:32.186822Z",
    has_template: true,
    needs_pro: false,
    needs_premium: false,
  },
  {
    internal_id: 9,
    name: "Time Trial",
    internal_name: "timetrial",
    uses_words: true,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-06-24T04:43:43.157329Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 10,
    name: "Spot The Word",
    internal_name: "spottheword",
    uses_words: true,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-06-24T04:44:09.275330Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 11,
    name: "Alien Chase",
    internal_name: "alienchase",
    uses_words: true,
    uses_backgrounds: false,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-06-29T11:26:46.030916Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 12,
    name: "Alien Chase Basic",
    internal_name: "alienchasebasic",
    uses_words: true,
    uses_backgrounds: false,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-06-30T11:05:41.091945Z",
    has_template: true,
    needs_pro: false,
    needs_premium: false,
  },
  {
    internal_id: 13,
    name: "UFO Blockade",
    internal_name: "ufoblockade",
    uses_words: true,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-06-30T11:05:58.241414Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 14,
    name: "Monster Mash",
    internal_name: "monstermash",
    uses_words: true,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: true,
    creation_time: "2022-06-30T11:06:25.302498Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 15,
    name: "Twister",
    internal_name: "twister",
    uses_words: true,
    uses_backgrounds: false,
    uses_projectiles: true,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-06-30T11:06:52.049960Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 16,
    name: "Alien Blaster",
    internal_name: "shoottheletters",
    uses_words: true,
    uses_backgrounds: false,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-07-01T07:10:40.018693Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 17,
    name: "Four In A Row",
    internal_name: "fourinarow",
    uses_words: false,
    uses_backgrounds: false,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-07-08T10:00:12.357746Z",
    has_template: true,
    needs_pro: true,
    needs_premium: true,
  },
  {
    internal_id: 18,
    name: "Character Wheel",
    internal_name: "characterwheel",
    uses_words: false,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: true,
    uses_monsters: false,
    creation_time: "2022-07-08T10:00:34.820712Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 19,
    name: "Number Wheel",
    internal_name: "numberwheel",
    uses_words: false,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-07-08T10:00:54.829486Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 20,
    name: "Word Wheel",
    internal_name: "wordwheel",
    uses_words: true,
    uses_backgrounds: true,
    uses_projectiles: false,
    uses_characters: false,
    uses_monsters: false,
    creation_time: "2022-07-08T10:01:14.694414Z",
    has_template: true,
    needs_pro: true,
    needs_premium: false,
  },
  {
    internal_id: 21,
    name: "Space Fight",
    internal_name: "spacefight",
    uses_words: false,
    uses_backgrounds: true,
    uses_projectiles: true,
    uses_characters: true,
    uses_monsters: true,
    creation_time: "2022-07-09T13:53:31.305769Z",
    has_template: true,
    needs_pro: true,
    needs_premium: true,
  },
];
