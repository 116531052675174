export interface BackendError {
  status: ErrorCode;
  messages: readonly string[];
}

enum ErrorCode {
  HTTP_403 = 403,
}

enum ErrorName {
  CSRF = "CSRF",
}

export const BackendErrors: Record<ErrorName, BackendError> = {
  [ErrorName.CSRF]: {
    status: 403,
    messages: ["CSRF Failed: CSRF token missing.", "CSRF Failed: CSRF token from the 'X-Csrftoken' HTTP header incorrect."],
  },
} as const;

export enum FrontEndErrorKey {
  max_sessions_reached = "max_sessions_reached",
}

export interface FrontendError {
  message: string;
}

export const FrontendErrors: Record<FrontEndErrorKey, FrontendError> = {
  [FrontEndErrorKey.max_sessions_reached]: {
    message: "You have been automatically logged out. Your session expired or you have logged in with too many devices. Please log in again.",
  },
};
