import React, { useEffect, useState } from "react";
import GalleryImage from "../images/GalleryImage";
import { ImageInterface } from "../images/ImagePicker";
import AddImageButton from "../images/AddImage";
import BlackBgBackdrop from "./BlackBgBackdrop";
import PowerGallery from "./PowerGallery";

interface props {
  powers: ImageInterface[];
  is_public: Boolean;
  onSelect?: Function;
  errors?: string[];
  editMode?: Boolean | false;
}

//allows choosing powers. uses the PowerGallery to select powers and returns a list of ImageInterface objects when the "select" button is clicked in that menu

export default function PowerSelector(props: props) {
  const [selectingPowers, setSelectingPowers] = useState(false);
  const [allPowers, setAllPowers] = useState<ImageInterface[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    if (props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);
  const closeFullscreen = () => {
    setSelectingPowers(false);
  };
  useEffect(() => {
    setAllPowers(props.powers);
  }, [props.powers]);
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <h5 className="text-primary-1100 dark:text-white">
        Powers: <span className="text-green-900 dark:text-teal-200 text-center"> {allPowers.length ? allPowers.map((image) => image.name).join(", ") : "Random"}</span>
      </h5>
      {props.editMode ? (
        <p className="text-primary-1100 dark:text-white mt-2 text-xs text-center mb-2">If no powers are selected, it will use a random power from your favourites, which can sometimes be fun, and is recommended for monsters.</p>
      ) : (
        <>{allPowers.length ? <></> : <p className="text-primary-1100 dark:text-white mt-2 text-xs text-center mb-2">Uses all the powers that are favourited, at random.</p>}</>
      )}
      <div className="container flex w-full flex-wrap justify-center gap-1 mb-2">
        {allPowers.map((power, i) => {
          return (
            <div key={power.id} className="powers h-20 w-20">
              <GalleryImage contain={true} image={power} is_public={props.is_public} type="PROJECTILES" is_basic={true}></GalleryImage>
            </div>
          );
        })}
        {props.is_public ? <></> : <AddImageButton className="h-20 w-20" onClick={() => setSelectingPowers(true)}></AddImageButton>}
      </div>
      {selectingPowers ? (
        <BlackBgBackdrop onClose={() => closeFullscreen()}>
          <PowerGallery
            selected={allPowers}
            onSelect={(items: ImageInterface[]) => {
              setAllPowers(items);
              closeFullscreen();
              if (props.onSelect) {
                props.onSelect(items);
              }
            }}
            onClose={() => {
              closeFullscreen();
            }}
          ></PowerGallery>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
      <div className="errors text-red-500 w-full flex justify-center flex-col items-center">
        {errors.map((err, i) => {
          return (
            <p key={i} className="font-thin text-center before:content-['_•_'] px-2 w-fit">
              {err}
            </p>
          );
        })}
      </div>
    </div>
  );
}
