import { MouseEventHandler } from "react";

interface props {
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

export default function BackOfflineButton(props: props) {
  return (
    <div
      onClick={props.onClick}
      className="flex relative items-center justify-center p-[2px] text-md font-bold text-primary-600 dark:text-white rounded-lg group bg-gradient-to-br 
          from-slate-400 to-primary-600 focus:from-slate-400 focus:to-primary-600 hover:text-white cursor-pointer select-none
          "
    >
      <span className="group px-5 py-2.5 transition-all ease-in duration-75 bg-sky-200 dark:bg-sky-900 rounded-md group-hover:bg-opacity-0 group-focus:bg-opacity-0 group-active:bg-opacity-0 flex gap-3">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        GO BACK
      </span>
    </div>
  );
}
