import React from "react";

interface props {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  className?: string;
  value?: string;
}

export default function CurrencySelector(props: props) {
  return (
    <div className={props.className}>
      <label htmlFor="currency" className="mb-2 text-sm font-medium text-slate-900 dark:text-slate-400 hidden">
        Currency
      </label>
      <select
        onChange={props.onChange}
        id="currency"
        value={props.value || "USD"}
        className="bg-slate-50 border border-slate-300 text-slate-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      >
        <option value="USD">USD</option>
        <option value="CNY">RMB</option>
      </select>
    </div>
  );
}
