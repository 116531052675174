import axios from "axios";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import BackOfflineButton from "../basic/BackOfflineButton";
import Globe from "../basic/Globe";
import PublicWordlistDashboard from "./PublicWordlistDashboard";

export default function PublicWordListManager() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(UserContext);
  useEffect(() => {
    document.title = "Public Wordlists | whatstheword.io";
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          } else {
            console.error("Log In Response is not 200");
            console.error(res);
            throw Error("Unauthorized");
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
          navigate("/login");
        });
      return;
    }
    if (state.isAuthenticated && !state.has_valid_email) {
      navigate("/activate/reminder");
      return;
    }
  }, [state.isAuthenticated, dispatch, navigate]);

  return (
    <div className="w-full h-auto min-h-full bg-stone-50 dark:bg-slate-900">
      <div className="min-h-[5rem] relative gap-4 p-3 flex flex-col md:flex-row bg-sky-200 z-10 dark:bg-sky-900 justify-center items-center">
        <p className="font-bold text-primary-900 dark:text-primary-100 text-lg">Find wordlists others have published!</p>
        <div className="h-full w-fit relative md:absolute right-0 pr-4 flex justify-center items-center">
          <BackOfflineButton
            onClick={() => {
              navigate("/my-wordlists");
            }}
          ></BackOfflineButton>
        </div>
      </div>
      <div className="w-full h-auto min-h-full relative bg-slate-50/30 dark:bg-blue-700/60">
        <div className="w-full h-full top-0 fixed z-0 overflow-hidden flex justify-center items-center text-primary-500 dark:text-white opacity-10">
          <Globe></Globe>
        </div>
        <div className="w-full h-full z-10 relative flex flex-col justify-center items-center">
          <PublicWordlistDashboard></PublicWordlistDashboard>
        </div>
      </div>
    </div>
  );
}
