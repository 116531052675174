import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useHover } from "src/utils/functions";
import { AuthTypes, User, UserContext } from "src/utils/UserContext";
import BlackBgBackdrop from "./basic/BlackBgBackdrop";
import NavbarButton from "./basic/NavbarButton";

export default function Navbar() {
  const { state, dispatch } = useContext(UserContext);
  const [nav, setNav] = useState(false);
  const [isTiny, setIsTiny] = useState(false);
  const handleClick = () => setNav(!nav);
  const navigate = useNavigate();
  const handleLogOut = () => {
    axios
      .post("/api/users/logout/")
      .then((res) => {
        dispatch({ type: AuthTypes.LogOut });
        dispatch({ type: AuthTypes.UpdateUserData, payload: {} as User });
        navigate("/login");
      })
      .catch((err) => {});
  };
  const location = useLocation();
  useEffect(() => {
    setIsTiny(location.pathname.startsWith("/games"));
  }, [location]);

  const [tinyButtonIsHovering, tinyButtonProps] = useHover();

  return (
    <>
      <div className={isTiny ? "tiny-nav absolute top-0 right-0 h-8 bg-stone-100 dark:bg-slate-800 rounded-bl-xl" : "hidden"}>
        <div
          className=" h-full flex justify-center items-center"
          onClick={() => {
            navigate("");
          }}
        >
          <h4 {...tinyButtonProps} className="title font-bold duration-300 text-sm lg:text-md px-3 text-primary-900 dark:text-primary-300   dark:hover:text-primary-100 tracking-tighter hover:text-primary-500 hover:cursor-pointer">
            {tinyButtonIsHovering ? "back to whatstheword.io" : "←"}
          </h4>
        </div>
      </div>
      <div className={isTiny ? "hidden" : "sticky top-0 flex w-full flex-col z-[1000]"}>
        <div className="w-full h-16 bg-slate-100 dark:bg-slate-800 flex  shadow-sm">
          <Link className=" h-full flex justify-center items-center" to={localStorage.getItem("loggedInBefore") && !state.isAuthenticated ? "/welcome" : "/"}>
            <h4 className="title font-bold text-lg lg:text-2xl xl:text-3xl px-3 lg:px-8 text-primary-900 dark:text-primary-300 tracking-tighter hover:text-primary-500 dark:hover:text-primary-100 hover:cursor-pointer">What's The Word?</h4>
          </Link>
          <div className="spacer h-full flex-grow"></div>
          <div className="h-full hidden lg:flex">
            {!state.isAuthenticated ? (
              <>
                <NavbarButton text="News" href="/news"></NavbarButton>
                <NavbarButton text="Games" href="/all-games"></NavbarButton>
                <NavbarButton text="Pricing" href="/plans"></NavbarButton>
                <NavbarButton text="Contact Us" href="/contact-us"></NavbarButton>
              </>
            ) : (
              <>
                <NavbarButton text="Dashboard" href="/"></NavbarButton>
                <NavbarButton text="News" href="/news"></NavbarButton>
                <NavbarButton text="My Wordlists" href={!state.has_valid_email ? "/activate/reminder" : "/my-wordlists"}></NavbarButton>
                <NavbarButton text="My Images" href={!state.has_valid_email ? "/activate/reminder" : "/my-images"}></NavbarButton>
                <NavbarButton text="My Profile" href="/profile"></NavbarButton>
                {state.has_active_plan ? <></> : <NavbarButton text="Go Pro" href="/plans"></NavbarButton>}
              </>
            )}
          </div>
          <div className="h-full hidden lg:flex">
            {!state.isAuthenticated ? (
              <>
                <Link to="/login">
                  <div className="bg-primary-600 text-white h-full link  px-[1.8rem] flex justify-center items-center cursor-pointer w-[100px] lg:w-[150px] hover:bg-primary-200 dark:bg-primary-1100 dark:hover:bg-primary-900 duration-300 ease-in-out">
                    <p className="select-none lg:text-lg md:text-sm  text-center">Log In</p>
                  </div>
                </Link>
                {process.env.REACT_APP_COMING_SOON === "true" ? (
                  <button className="bg-gray-500 text-gray-300 dark:text-gray-500 w-[100px] lg:w-[150px] dark:bg-neutral-800 pointer-events-none duration-300 ease-in-out">TRIAL COMING SOON</button>
                ) : (
                  <Link to="/signup">
                    <div className="bg-primary-1100 text-white h-full link  px-[1.8rem] flex justify-center items-center cursor-pointer w-[100px] lg:w-[150px] hover:bg-primary-300 dark:bg-primary-1200 dark:hover:bg-primary-900 duration-300 ease-in-out">
                      <p className="select-none lg:text-lg md:text-sm  text-center">Sign Up</p>
                    </div>
                  </Link>
                )}
              </>
            ) : (
              <button className="bg-primary-600 text-white w-[100px] lg:w-[150px] hover:bg-primary-200 dark:bg-primary-1100 dark:hover:bg-primary-900 duration-300 ease-in-out" onClick={() => handleLogOut()}>
                Log Out
              </button>
            )}
          </div>
          <div className="flex justify-center items-center h-full w-20 lg:hidden" onClick={handleClick}>
            {!nav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8  hover:cursor-pointer hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-100 text-primary-900"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8  hover:cursor-pointer hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-100 text-primary-900"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            )}
          </div>
        </div>
        {nav ? (
          <BlackBgBackdrop top={true} onClose={() => setNav(false)}>
            {/** temporary repeat of the above navbar. should refactor //TODO */}
            <div className="w-full h-16 bg-stone-50 dark:bg-slate-800 flex  shadow-sm justify-between">
              <Link className=" h-full flex justify-center items-center" to={localStorage.getItem("loggedInBefore") && !state.isAuthenticated ? "/welcome" : "/"}>
                <h4 className="title font-bold text-lg lg:text-2xl xl:text-3xl px-3 lg:px-8 text-primary-900 dark:text-primary-300 tracking-tighter hover:text-primary-500 dark:hover:text-primary-100 hover:cursor-pointer">
                  What's The Word?
                </h4>
              </Link>
              <div className="flex justify-center items-center h-full w-20 lg:hidden" onClick={handleClick}>
                {!nav ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8  hover:cursor-pointer hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-100 text-primary-900"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8  hover:cursor-pointer hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-100 text-primary-900"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </div>
            </div>
            <div className={nav ? "mobile-menu w-full flex justify-center items-center flex-col px-10 bg-slate-50 dark:bg-slate-800" : "hidden"} onClick={() => setNav(false)}>
              {!state.isAuthenticated ? (
                <>
                  <NavbarButton isMobile={true} text="News" href="/news"></NavbarButton>
                  <NavbarButton isMobile={true} text="Games" href="/all-games"></NavbarButton>
                  <NavbarButton isMobile={true} text="Pricing" href="/plans"></NavbarButton>
                  <NavbarButton isMobile={true} text="Contact Us" href="/contact-us"></NavbarButton>
                </>
              ) : (
                <>
                  <NavbarButton isMobile={true} text="Dashboard" href="/"></NavbarButton>
                  <NavbarButton isMobile={true} text="News" href="/news"></NavbarButton>
                  <NavbarButton isMobile={true} text="My Wordlists" href={!state.has_valid_email ? "/activate/reminder" : "/my-wordlists"}></NavbarButton>
                  <NavbarButton isMobile={true} text="My Images" href={!state.has_valid_email ? "/activate/reminder" : "/my-images"}></NavbarButton>
                  <NavbarButton isMobile={true} text="My Profile" href="/profile"></NavbarButton>
                  {state.has_active_plan ? <></> : <NavbarButton text="Go Pro" isMobile={true} href="/plans"></NavbarButton>}
                </>
              )}
              <div className="h-10 m-2 my-5 gap-10 flex">
                {!state.isAuthenticated ? (
                  <>
                    <Link to="/login">
                      <div className="bg-primary-600 flex justify-center items-center text-white font-thin px-5 py-1.5 rounded text-lg w-[150px] hover:bg-primary-200 dark:bg-primary-1100 dark:hover:bg-primary-900 duration-300 ease-in-out">
                        Log In
                      </div>
                    </Link>
                    {process.env.REACT_APP_COMING_SOON === "true" ? (
                      <button className="bg-gray-300 flex justify-center items-center text-gray-100 dark:text-gray-500 font-thin px-5 py-1.5 rounded text-lg dark:bg-neutral-800 pointer-events-none duration-300 ease-in-out whitespace-nowrap">
                        COMING SOON
                      </button>
                    ) : (
                      <Link to="/signup">
                        <div className="bg-primary-1100 flex justify-center items-center text-white font-thin px-5 py-1.5 rounded text-lg w-[150px] hover:bg-primary-300 dark:bg-primary-1200 dark:hover:bg-primary-900 duration-300 ease-in-out">
                          Sign Up
                        </div>
                      </Link>
                    )}
                  </>
                ) : (
                  <button className="bg-primary-600 font-thin rounded text-white text-lg w-[150px] lg:w-[150px] hover:bg-primary-200 dark:bg-primary-1100 dark:hover:bg-primary-900 duration-300 ease-in-out" onClick={() => handleLogOut()}>
                    Log Out
                  </button>
                )}
              </div>
            </div>
          </BlackBgBackdrop>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
