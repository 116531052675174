import React from "react";

export default function TermsAndConditions() {
  return (
    <div>
      <div className="bg-stone-100 dark:bg-slate-800 rounded-md p-3 flex flex-col gap-3">
        <h1 className="font-bold text-3xl">IN SIMPLE TERMS</h1>

        <h3 className="font-medium text-lg">WHAT ARE YOU PURCHASING?</h3>

        <p>
          This site essentially offers image manipulation tools, in the forms of games. You can use them for personal use as you seem fit. They are directed for educational purposes. You are not purchasing the images, or any user-submitted
          content, or any licenses they have. It is expected that you are a teacher purchasing the plan to use for your students, while under the supervision of the teacher.
        </p>
        <h3 className="font-medium text-lg">WHAT CAN YOU DO? </h3>

        <p>
          You can upload any images you want to your account, to use them in the games. You can use the games to teach students the contents of your class. You can use them just for fun. You can share the images you submitted with other
          users of the site, through the site upload tool. You can use the games in any method you wish, as long as it falls under fair use.
        </p>
        <h3 className="font-medium text-lg">WHAT CANT YOU DO?</h3>

        <p>
          You cannot download the games, reproduce them, or copy them in any way, other than the authorized use of them while logged into the site. You cannot download the images for use in any way that isn’t explicitly allowed by their
          license holders. You cannot use any part of the site for commercial purposes, unless explicitly allowed by What’s The Word.
        </p>

        <p>The images are meant for use in a children’s class, so you cannot post or upload inappropriate materials. We can remove or delete any images uploaded, or restrict their uploads without warning. </p>
        <p>
          We can also restrict upload privileges, suspend or ban users for breaking any terms in Our Terms and Conditions. We will try to send a warning to your email before we do so, but we are not guaranteeing that we do. Any issues and
          concerns regarding restrictions can be emailed to{" "}
          <a className="text-blue-500 dark:text-blue-300 underline" href="mailto:appeals@whatstheword.io">
            appeals@whatstheword.io
          </a>
        </p>
        <h3 className="font-medium text-lg">CAN I GET A REFUND?</h3>
        <p>
          Yes, we can refund up to one month's worth, while your plan is still active. We will not issue refunds for expired plans. For refunds please contact us at{" "}
          <a className="text-blue-500 dark:text-blue-300 underline" href="mailto:billing@whatstheword.io">
            billing@whatstheword.io
          </a>
        </p>
      </div>

      <div className="flex flex-col p-3 my-5 bg-stone-100 dark:bg-slate-800 rounded-md gap-5">
        <h1 className="text-3xl font-bold">FULL TERMS AND CONDITIONS</h1>
        <p>Last updated July 01, 2022</p>
        <h4 className="text-lg font-medium">TABLE OF CONTENTS</h4>
        <ul className="list-inside">
          <li className="font-bold text-md ml-5">1. AGREEMENT TO TERMS</li>
          <li className="font-bold text-md ml-5">2. INTELLECTUAL PROPERTY RIGHTS</li>
          <li className="font-bold text-md ml-5">3. USER REPRESENTATIONS</li>
          <li className="font-bold text-md ml-5">4. USER REGISTRATION</li>
          <li className="font-bold text-md ml-5">5. FEES AND PAYMENT</li>
          <li className="font-bold text-md ml-5">6. FREE TRIAL</li>
          <li className="font-bold text-md ml-5">7. CANCELLATION</li>
          <li className="font-bold text-md ml-5">8. PROHIBITED ACTIVITIES</li>
          <li className="font-bold text-md ml-5">9. USER GENERATED CONTRIBUTIONS</li>
          <li className="font-bold text-md ml-5">10. CONTRIBUTION LICENSE</li>
          <li className="font-bold text-md ml-5">11. SITE MANAGEMENT</li>
          <li className="font-bold text-md ml-5">12. PRIVACY POLICY</li>
          <li className="font-bold text-md ml-5">13. COPYRIGHT INFRINGEMENTS</li>
          <li className="font-bold text-md ml-5">14. TERM AND TERMINATION</li>
          <li className="font-bold text-md ml-5">15. MODIFICATIONS AND INTERRUPTIONS</li>
          <li className="font-bold text-md ml-5">16. GOVERNING LAW</li>
          <li className="font-bold text-md ml-5">17. DISPUTE RESOLUTION</li>
          <li className="font-bold text-md ml-5">18. CORRECTIONS</li>
          <li className="font-bold text-md ml-5">19. DISCLAIMER</li>
          <li className="font-bold text-md ml-5">20. LIMITATIONS OF LIABILITY</li>
          <li className="font-bold text-md ml-5">21. INDEMNIFICATION</li>
          <li className="font-bold text-md ml-5">22. USER DATA</li>
          <li className="font-bold text-md ml-5">23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
          <li className="font-bold text-md ml-5">24. MISCELLANEOUS</li>
          <li className="font-bold text-md ml-5">25. CONTACT US</li>
        </ul>
        <h4 className="font-bold text-lg ml-3">1. AGREEMENT TO TERMS</h4>
        <p>
          These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and What's The Word ("Company", “we”, “us”, or “our”), concerning your access to and use of the
          whatstheword.io website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you
          have read, understood, and agree to be bound by all of these Terms of Use, including the User Agreement posted on the Site, which are incorporated into these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN
          YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        <p>
          Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications
          to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change. Please
          ensure that you check the applicable Terms every time you use our Site so that you understand which Terms apply. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any
          revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.
        </p>
        <p>
          The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us
          to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local
          laws, if and to the extent local laws are applicable.
        </p>
        <p>
          The Site registration is for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Site. Persons under the age of 18 may use the services of the Site under supervision of the
          account holder.
        </p>
        <h4 className="font-bold text-lg ml-3">2. INTELLECTUAL PROPERTY RIGHTS</h4>
        <p>
          Unless otherwise indicated, the Site and its tools are our proprietary property and all Site source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics, code, scripts, HTML,
          CSS, JavaScript, and other explicitly stated games on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are
          protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of Canada, international copyright laws, and international conventions. This does not include Images, Media,
          Graphics, text, writings, video, audio, or photographs, submitted by authorized users (collectively, the “Contributions”). The Content and the Marks are provided on the Site “AS IS” for your information and personal use only.
          Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
        </p>
        <p>
          Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your
          personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.
        </p>
        <h4 className="font-bold text-lg ml-3">3. USER REPRESENTATIONS</h4>
        <p>
          By using the Site, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such
          registration information as necessary; (3) you have the legal capacity and you agree to comply with these Terms of Use; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the Site through
          automated or non-human means, whether through a bot, script or otherwise; (6) you will not use the Site for any illegal or unauthorized purpose; and (7) your use of the Site will not violate any applicable law or regulation.
        </p>
        <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).</p>
        <h4 className="font-bold text-lg ml-3">4. USER REGISTRATION</h4>
        <p>
          You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select
          if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
        </p>
        <h4 className="font-bold text-lg ml-3">5. FEES AND PAYMENT</h4>
        <p>We accept the following forms of payment:</p>- Mastercard - Visa - American Express - Discover - Any Stripe Supported Card Brands (https://stripe.com/docs/payments/cards/supported-card-brands) - Wechat Pay - Alipay
        <p>
          You may be required to purchase or pay a fee to access some of our services. You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Site. You further agree to promptly
          update account info, including email address, so that we can complete your transactions and contact you as needed. We bill you through a Third-Party Service (Stripe) for purchases made via the Site. Sales tax will be added to the
          price of purchases as deemed required by us. We may change prices at any time. All payments shall be in U.S. dollars or Chinese Yuan .
        </p>
        <p>
          You agree to pay all charges or fees at the prices then in effect for your purchases, and you authorize us to charge your chosen payment provider for any such amounts upon making your purchase. If your purchase is subject to
          recurring charges, then you consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until you notify us of your cancellation.
        </p>
        <p>We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. We also reserve the right to refuse any order placed through the Site.</p>
        <h4 className="font-bold text-lg ml-3">6. FREE TRIAL</h4>
        <p>We offer a 7-day free trial to new users who register with the Site. The account will not be charged and the subscription will be suspended until upgraded to a paid version at the end of the free trial.</p>
        <h4 className="font-bold text-lg ml-3">7. CANCELLATION</h4>
        <p>
          You can cancel your subscription at any time by logging into your account, going to My Profile, and clicking on the active Plan, or contacting us using the contact information provided below. Your cancellation will take effect at
          the end of the current paid term.
        </p>
        <p>If you are unsatisfied with our services, please email us at complaints@whatstheword.io.</p>
        <h4 className="font-bold text-lg ml-3">8. PROHIBITED ACTIVITIES</h4>
        <p>
          You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved
          by us.
        </p>
        <p>As a user of the Site, you agree not to:</p>
        <ul className="list-inside">
          <li className="ml-3">• Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
          <li className="ml-3">• Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
          <li className="ml-3">
            • Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the
            Content contained therein.
          </li>
          <li className="ml-3">• Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
          <li className="ml-3">• Use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
          <li className="ml-3">• Make improper use of our support services or submit false reports of abuse or misconduct.</li>
          <li className="ml-3">• Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
          <li className="ml-3">• Engage in unauthorized framing of or linking to the Site.</li>
          <li className="ml-3">
            • Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any
            party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.
          </li>
          <li className="ml-3">• Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
          <li className="ml-3">• Delete the copyright or other proprietary rights notice from any Content.</li>
          <li className="ml-3">• Attempt to impersonate another user or person or use the username of another user.</li>
          <li className="ml-3">
            • Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats
            (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).
          </li>
          <li className="ml-3">• Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.</li>
          <li className="ml-3">• Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.</li>
          <li className="ml-3">• Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.</li>
          <li className="ml-3">• Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, CSS, JavaScript, or other code.</li>
          <li className="ml-3">• Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.</li>
          <li className="ml-3">
            • Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline
            reader that accesses the Site, or using or launching any unauthorized script or other software.
          </li>
          <li className="ml-3">• Use a buying agent or purchasing agent to make purchases on the Site.</li>
          <li className="ml-3">
            • Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or
            under false pretenses.
          </li>
          <li className="ml-3">• Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
          <li className="ml-3">• Use the Site to advertise or offer to sell goods and services.</li>
          <li className="ml-3">• Sell or otherwise transfer your profile.</li>
          <li className="ml-3">• Download images from the online page and use them for commercial purposes.</li>
          <li className="ml-3">• Post or upload inappropriate materials.</li>
        </ul>
        <h4 className="font-bold text-lg ml-3">9. USER GENERATED CONTRIBUTIONS</h4>
        <p>All uses of the Upload Tools and any materials created using the Upload Tools (the “Contributions”)</p>
        <p>
          The Site may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video,
          audio, photographs or graphics (collectively, "Contributions"). Contributions are for Your own personal, non-commercial use solely in connection with the applicable Product, subject to the terms and conditions of this Agreement.
          You are only permitted to distribute the New Materials, without charge (i.e., on a strictly non-commercial basis) to other authorized users who have registered for the Site, solely for use with such users’ own authorized use of
          the Site and in accordance with and subject to the terms and conditions of this Agreement and all applicable laws. Contributions may be viewable by other users of the Site. When you create or make available any Contributions, you
          thereby represent and warrant that:
        </p>
        <ul className="list-inside">
          <li className="ml-2">
            • Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation. • Your Contributions are not obscene, lewd,
            lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).{" "}
          </li>
          <li className="ml-2">• Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone. </li>
          <li className="ml-2">• Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people. </li>
          <li className="ml-2">• Your Contributions do not violate any applicable law, regulation, or rule. </li>
          <li className="ml-2">• Your Contributions do not violate the privacy or publicity rights of any third party. </li>
          <li className="ml-2">• Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors. </li>
          <li className="ml-2">• Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap. </li>
          <li className="ml-2">• Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation. </li>
          <li className="ml-2">
            • You may not cause or permit the sale or other commercial distribution or commercial exploitation (e.g., by renting, licensing, sublicensing, leasing, disseminating, uploading, downloading, transmitting, whether on a
            pay-per-play basis or otherwise) of any New Materials without the express prior written consent of an authorized representative of What’s The Word.{" "}
          </li>
          <li className="ml-2">• You may not copy or use any portions of our site that are not Contributions except within the limits of fair use.</li>
        </ul>{" "}
        <p>If You commit any breach of this Agreement, Your right to use the Site under this Agreement shall automatically terminate, without notice.</p>
        <h4 className="font-bold text-lg ml-3">10. CONTRIBUTION LICENSE</h4>
        <p>
          We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable
          for any statements or representations in your Contributions provided by you in any area on the Site. You are solely responsible for your Contributions to the Site and you expressly agree to exonerate us from any and all
          responsibility and to refrain from any legal action against us regarding your Contributions.
        </p>
        <p>
          We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the Site; and (3) to
          pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.
        </p>
        <h4 className="font-bold text-lg ml-3">11. SITE MANAGEMENT</h4>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use,
          including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically
          feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or
          are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
        </p>
        <h4 className="font-bold text-lg ml-3">12. PRIVACY POLICY</h4>
        <p>
          We care about data privacy and security. We do not store any sensitive information about You. We store your email to facilitate the ability to contact you. Please be advised the Site is hosted in Canada. If you access the Site
          from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Canada, then through your continued use of the Site, you are
          transferring your data to Canada, and you agree to have your data transferred to and processed in Canada.
        </p>
        <h4 className="font-bold text-lg ml-3">13. COPYRIGHT INFRINGEMENTS</h4>
        <p>
          We respect the intellectual property rights of others. If you believe that any material available on or through the Site infringes upon any copyright you own or control, please immediately notify us using the contact information
          provided below (a “Notification”). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to applicable law you may be held liable
          for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Site infringes your copyright, you should consider first contacting an attorney.
        </p>
        <p>If you see anything on our site that you believe infringes your copyright rights, you may notify us by sending the following information:</p>
        <ul className="list-inside">
          <li className="ml-3">
            • Identification of the copyrighted work or works claimed to have been infringed. IMPORTANT: please include your copyright registration number. If your work is not yet registered, please include a copy of the application to
            register the work that you filed with the Copyright Office.{" "}
          </li>
          <li className="ml-3">• Identification of the material on our servers that is claimed to be infringing and that is to be removed, including the name or other information to enable us to locate the material. </li>
          <li className="ml-3">• A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by you as copyright owner, or by your agent, or by law. </li>
          <li className="ml-3">
            • A statement that the information in your notice is accurate, and under penalty of perjury, that you are the owner (or authorized to act on behalf of the owner) of the exclusive copyright right that is allegedly being
            infringed.{" "}
          </li>
          <li className="ml-3">• Your physical or electronic signature, or of someone authorized to act on your behalf. </li>
          <li className="ml-3">• Instructions on how we may contact you: preferably email, but also address and phone.</li>
        </ul>
        <a className="text-blue-500 dark:text-blue-300 underline" href="mailto:dmca@whatstheword.io">
          Email: dmca@whatstheword.io
        </a>
        <p>Because a high percentage of DMCA takedown notices are not valid, What’s the Word reserves the right to ignore DMCA notifications based on unregistered works.</p>
        <h4 className="font-bold text-lg ml-3">14. TERM AND TERMINATION</h4>
        <p>
          These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY
          ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS
          OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
        </p>
        <p>
          If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, an alternate email, or the name of any third party, even if you may
          be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
        </p>
        <h4 className="font-bold text-lg ml-3">15. MODIFICATIONS AND INTERRUPTIONS</h4>
        <p>
          We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve
          the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
        </p>
        <p>
          We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the
          right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused
          by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates,
          or releases in connection therewith.
        </p>
        <h4 className="font-bold text-lg ml-3">16. GOVERNING LAW</h4>
        <p>
          These Terms shall be governed by and defined following the laws of Canada. What's The Word and yourself irrevocably consent that the courts of Canada shall have exclusive jurisdiction to resolve any dispute which may arise in
          connection with these terms.
        </p>
        <h4 className="font-bold text-lg ml-3">17. DISPUTE RESOLUTION</h4>
        <p>
          You agree to irrevocably submit all disputes related to Terms or the legal relationship established by this Agreement to the jurisdiction of the Canada courts. What's The Word shall also maintain the right to bring proceedings as
          to the substance of the matter in the courts of the country where you reside or, if these Terms are entered into in the course of your trade or profession, the state of your principal place of business.
        </p>
        <h4 className="font-bold text-lg ml-3">18. CORRECTIONS</h4>
        <p>
          There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
          inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
        </p>
        <h4 className="font-bold text-lg ml-3">19. DISCLAIMER</h4>
        <p>
          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
          CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
          PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
          INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
          GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND
          WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
          ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        </p>
        <h4 className="font-bold text-lg ml-3">20. LIMITATIONS OF LIABILITY</h4>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS
          OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER
          AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE ONE (1) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO
          NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
          RIGHTS.
        </p>
        <h4 className="font-bold text-lg ml-3">21. INDEMNIFICATION</h4>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand,
          including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Site; (3) breach of these Terms of Use; (4) any breach of your representations and
          warranties set forth in these Terms of Use; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Site with whom you
          connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at
          your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
        </p>
        <h4 className="font-bold text-lg ml-3">22. USER DATA</h4>
        <p>
          We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are
          solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby
          waive any right of action against us arising from any such loss or corruption of such data.
        </p>
        <h4 className="font-bold text-lg ml-3">23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h4>
        <p>
          Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
          communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or
          other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
        </p>
        <h4 className="font-bold text-lg ml-3">24. MISCELLANEOUS</h4>
        <p>
          These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or
          provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any
          time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be
          unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture,
          partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us by virtue of having drafted them.
          You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.
        </p>
        <h4 className="font-bold text-lg ml-3">25. CONTACT US</h4>
        <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:</p>
        <a className="text-blue-500 dark:text-blue-300 underline" href="mailto:staff@whatstheword.io">
          staff@whatstheword.io
        </a>
      </div>
    </div>
  );
}
