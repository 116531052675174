import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthTypes, UserContext } from "src/utils/UserContext";

enum MessageState {
  WAITING_TO_ACTIVATE,
  REMINDER_TO_ACTIVATE,
  ERROR_ACTIVATING,
  TOKEN_EXPIRED,
  EMAIL_SENT,
  ACTIVATED,
}

export default function Activate() {
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const [messageState, setMessageState] = useState(MessageState.WAITING_TO_ACTIVATE);
  const [loading, setLoading] = useState(false);

  const resendEmail = (asReminder = false) => {
    if (!asReminder && params.id) {
      axios.get("/api/users/get_csrf/").then(() => {
        axios.post("/api/activate/" + params.id + "/resendactivationemail/");
      });
    } else if (asReminder) {
      axios.get("/api/users/get_csrf/").then(() => {
        axios.post("/api/activate/reminder/resendactivationemail/");
      });
    }
    setMessageState(MessageState.EMAIL_SENT);
  };

  useEffect(() => {
    if (state.has_valid_email && state.isAuthenticated) {
      navigate("/");
      return;
    } else if (params.id === "reminder") {
      //user logged in but unverified will be sent here
      if (!state.isAuthenticated) {
        //user came here by themselves
        axios
          .get("/api/users/me/")
          .then((res) => {
            if (res.status === 200) {
              dispatch({ type: AuthTypes.LogIn });
              dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
              setMessageState(MessageState.REMINDER_TO_ACTIVATE);
              if (res.data.has_valid_email) {
                navigate("/");
                return;
              }
            } else {
              console.error("Log In Response is not 200");
              console.error(res);
              throw Error("Unauthorized");
            }
          })
          .catch(() => {
            localStorage.removeItem("rememberme");
            navigate("/login");
            return;
          });
        return;
      }
      setMessageState(MessageState.REMINDER_TO_ACTIVATE);
      return;
    }
    setLoading(true);
    axios.get("/api/users/get_csrf/").then(() => {
      axios
        .post("/api/activate/" + params.id + "/" + params.token + "/")
        .then((res) => {
          if (res.status === 200) {
            setMessageState(MessageState.ACTIVATED);
            setLoading(false);
            startTimer();
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.token) {
            setMessageState(MessageState.TOKEN_EXPIRED);
          } else {
            setMessageState(MessageState.ERROR_ACTIVATING);
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
          setLoading(false);
        });
    });
  }, []);

  const [redirectTimer, setRedirectTimer] = useState(5);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const startTimer = () => {
    const interval = setInterval(() => {
      setRedirectTimer((prev) => {
        if (prev <= 0) {
          clearInterval(interval);

          return 0;
        } else {
          return prev - 1;
        }
      });
    }, 1000);
    setIntervalId(interval);
  };

  useEffect(() => {
    if (redirectTimer <= 0) {
      navigate("/login");
    }
  }, [redirectTimer]);
  useEffect(() => {
    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const hideEmail = (email: string) => {
    //returns a hidden email. bob.bobson@gmail.com => b*****n@gmail.com
    let emailSections = email.split("@");
    let first = emailSections[0][0];
    let second = emailSections[0].split("").at(-1);
    return (
      first +
      Array(emailSections[0].length - 1)
        .fill("*")
        .join("") +
      second +
      "@" +
      emailSections[1]
    );
  };
  const getMessage = () => {
    switch (messageState) {
      case MessageState.WAITING_TO_ACTIVATE:
        return "We are activating your account, please wait.";
      case MessageState.TOKEN_EXPIRED:
        return "This token has expired. Please resend the email using the button below.";
      case MessageState.ERROR_ACTIVATING:
        return "This link is invalid. Redirecting to main page.";
      case MessageState.EMAIL_SENT:
        return "Email sent! Check your mailbox.";
      case MessageState.REMINDER_TO_ACTIVATE:
        return "Please activate your email! The email we have is " + hideEmail(state.email_to_activate) + ". If this is wrong, you can change it in your profile settings. You can also resend the email using the button below.";
      case MessageState.ACTIVATED:
        return "You're all set!";
    }
  };

  return (
    <div className="w-full h-full dark:bg-slate-900 flex justify-center items-center">
      <div className="rounded-lg bg-slate-200 dark:bg-slate-800 p-5 m-5 flex justify-center items-center flex-col gap-5">
        <h4 className="text-primary-1200 dark:text-primary-100 text-center">{getMessage()}</h4>
        {[MessageState.REMINDER_TO_ACTIVATE, MessageState.TOKEN_EXPIRED].includes(messageState) ? (
          <button
            onClick={() => {
              resendEmail(messageState === MessageState.REMINDER_TO_ACTIVATE);
            }}
            className="bg-primary-400 font-thin dark:bg-primary-1100 text-primary-1200 dark:text-primary-100 hover:bg-primary-600 hover:dark:bg-primary-1000 duration-100 ease-in-out px-5 py-2.5 rounded-lg flex gap-4 justify-center items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            Resend Email
          </button>
        ) : (
          <></>
        )}
        {messageState === MessageState.ACTIVATED ? <p className="text-sm text-primary-1200 dark:text-primary-100">You will be redirected to the login page in {redirectTimer} seconds.</p> : <></>}
      </div>
    </div>
  );
}
