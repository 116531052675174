import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputType } from "src/utils/InputTypes";
import { AuthTypes, UserContext } from "src/utils/UserContext";
import BlackBgBackdrop from "../basic/BlackBgBackdrop";
import { InputGroup } from "../basic/InputGroup";

enum editMode {
  email,
  display_name,
}

//annoyingly, all 3 editable fields must be handled differently, so all three have separate methods and input fields.
//especially email, since changing it disables the account since now the email is unverified
//and we dont want users with unverified emails to use the service. (for safety, security, and just spam reasons)

export default function InfoBox() {
  const { state, dispatch } = useContext(UserContext);
  const [hidden, setHidden] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const showText = (str: string, isEmail: boolean = false) => {
    //hides text when not shown
    if (hidden && str) {
      return (
        str[0] +
        Array(str.length - 2)
          .fill("*")
          .join("") +
        str[str.length - 1]
      );
    } else if (str && isEmail) {
      let parts = str.split("@");
      return (
        str[0] +
        Array(parts[0].length - 1)
          .fill("*")
          .join("") +
        "@" +
        parts[1]
      );
    }
    return str;
  };

  const [popupOpen, setPopupOpen] = useState(false);

  const editEmail = () => {
    setPopupOpen(true);
    setEditField(editMode.email);
    setEmailInput((prev) => ({ ...prev, errors: [], invalid: false, value: state.email || "" }));
  };

  const editDisplayName = () => {
    setPopupOpen(true);
    setEditField(editMode.display_name);
    setDisplayNameInput((prev) => ({ ...prev, errors: [], invalid: false, value: state.display_name || "" }));
  };
  const submitDisplayName = () => {
    setDisplayNameInput((prev) => ({ ...prev, errors: [], invalid: false }));
    if (displayNameInput.value === "") {
      setDisplayNameInput((prev) => ({ ...prev, errors: ["You must fill out this field."], invalid: true }));
      return;
    }
    if (displayNameInput.value === state.display_name) {
      setDisplayNameInput((prev) => ({ ...prev, errors: ["You haven't made any changes."], invalid: true }));
      return;
    }
    let fd = new FormData();
    setButtonDisabled(true);
    fd.set("display_name", displayNameInput.value);

    axios.get("/api/users/get_csrf/").then(() => {
      axios
        .patch("/api/users/" + state.id + "/", fd)
        .then((res) => {
          dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          setButtonDisabled(false);
          closePopup();
        })
        .catch((err) => {
          setButtonDisabled(false);
          if (err && err.response && err.response.data) {
            for (let key in err.response.data) {
              let errors: string[] = [];
              if (typeof err.response.data[key] === "string") {
                errors = [err.response.data[key]];
              } else {
                errors = err.response.data[key];
              }
              setDisplayNameInput((prev) => ({ ...prev, errors: errors, invalid: true }));
            }
          }
        });
    });
  };

  const submitEmail = () => {
    setEmailInput((prev) => ({ ...prev, errors: [], invalid: false }));
    if (emailInput.value === "") {
      setEmailInput((prev) => ({ ...prev, errors: ["You must fill out this field."], invalid: true }));
      return;
    }
    if (emailInput.value === state.email) {
      setEmailInput((prev) => ({ ...prev, errors: ["You haven't made any changes."], invalid: true }));
      return;
    }
    let fd = new FormData();
    setButtonDisabled(true);
    fd.set("email", emailInput.value);
    axios.get("/api/users/get_csrf/").then(() => {
      axios
        .patch("/api/users/" + state.id + "/", fd)
        .then((res) => {
          dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          setButtonDisabled(false);
          closePopup();
        })
        .catch((err) => {
          setButtonDisabled(false);
          if (err && err.response && err.response.data) {
            for (let key in err.response.data) {
              let errors: string[] = [];
              if (typeof err.response.data[key] === "string") {
                errors = [err.response.data[key]];
              } else {
                errors = err.response.data[key];
              }
              setEmailInput((prev) => ({ ...prev, errors: errors, invalid: true }));
            }
          }
        });
    });
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const [editField, setEditField] = useState(editMode.display_name);

  const [emailInput, setEmailInput] = useState<InputType>({ ...({} as InputType), errors: [], invalid: false, type: "email", required: true });
  const [displayNameInput, setDisplayNameInput] = useState<InputType>({ ...({} as InputType), label: "Display Name", placeholder: " ", errors: [], invalid: false, type: "text", required: true });

  const popupContents = () => {
    switch (editField) {
      case editMode.display_name:
        return (
          <>
            <h4 className="text-lg text-primary-900 dark:text-white font-medium">Edit Your Display Name</h4>
            <p className="text-sm font-thin text-primary-1000 dark:text-primary-200">Your display name will be shown to others, and hide your username.</p>
            <InputGroup
              {...displayNameInput}
              value={displayNameInput.value}
              onChange={(ev) => {
                setDisplayNameInput((prev) => ({ ...prev, value: ev.target.value, errors: [], invalid: false }));
              }}
            ></InputGroup>
            <form className="buttons flex gap-4 justify-center items-center">
              <button
                type="submit"
                onClick={(ev) => {
                  ev.preventDefault();
                  submitDisplayName();
                }}
                className={
                  "text-white font-thin mt-10  focus:ring-1  rounded-md text-md  w-full sm:w-auto px-3 py-1 text-center self-center justify-self-center " +
                  (buttonDisabled ? "bg-gray-500 text-gray-200 pointer-events-none" : "bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 focus:outline-none")
                }
              >
                Submit Edit
              </button>
              <button
                onClick={() => closePopup()}
                type="submit"
                className={
                  "text-primary-900 dark:text-white font-thin mt-10  focus:ring-1  rounded-md text-md  w-full sm:w-auto px-3 py-1 text-center self-center justify-self-center bg-transparent border-primary-1000 dark:border-primary-300 border-[1px] hover:bg-primary-700/50 focus:ring-primary-300 focus:outline-none"
                }
              >
                Cancel
              </button>
            </form>
          </>
        );
      case editMode.email:
        return (
          <>
            <h4 className="text-lg text-primary-900 dark:text-white font-medium">Edit Your Email</h4>
            <p className="text-sm font-thin text-primary-1000 dark:text-primary-200">
              Your email is used for billing, and as contact info for any account issues. It is important to make sure it is a valid email, so orders, subscriptions, and any other info reaches you. Your email will not be shown to others.
            </p>
            <InputGroup
              {...emailInput}
              value={emailInput.value}
              onChange={(ev) => {
                setEmailInput((prev) => ({ ...prev, value: ev.target.value, errors: [], invalid: false }));
              }}
            ></InputGroup>
            <div className="border-amber-500 bg-yellow-300 rounded-md w-full flex justify-center items-center border-2 p-2">
              <p className="text-amber-700 text-md">Important: You must re-validate your email before you can use various account features. If you typed the wrong email, you can always change your email here.</p>
            </div>
            <form className="buttons flex gap-4 justify-center items-center">
              <button
                type="submit"
                onClick={(ev) => {
                  ev.preventDefault();
                  submitEmail();
                }}
                className={
                  "text-white font-thin mt-10  focus:ring-1  rounded-md text-md  w-full sm:w-auto px-3 py-1 text-center self-center justify-self-center " +
                  (buttonDisabled ? "bg-gray-500 text-gray-200 pointer-events-none" : "bg-red-700 hover:bg-red-800 focus:ring-red-300 focus:outline-none")
                }
              >
                Submit Edit
              </button>
              <button
                onClick={() => closePopup()}
                type="submit"
                className={
                  "text-primary-900 dark:text-whitefont-thin mt-10  focus:ring-1  rounded-md text-md  w-full sm:w-auto px-3 py-1 text-center self-center justify-self-center bg-transparent border-primary-1000 dark:border-primary-300 border-[1px] hover:bg-primary-700/50 focus:ring-primary-300 focus:outline-none"
                }
              >
                Cancel
              </button>
            </form>
          </>
        );
    }
  };

  const toggleWordlistSelectMode = () => {
    axios
      .get("/api/users/get_csrf/")
      .then(() => {
        axios.patch("/api/users/" + state.id + "/", { always_save_wordlist_across_devices: !state.always_save_wordlist_across_devices }).then((res) => {
          console.log(res.data);
          dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          setButtonDisabled(false);
        });
      })
      .catch(() => {
        setButtonDisabled(false);
      });
  };

  return (
    <div className="bg-white border-gray-200 border-[1px] dark:border-gray-600  dark:bg-slate-800 rounded m-2 p-2 flex flex-col gap-2 min-w-[250px] text-primary-1100 dark:text-primary-200">
      <h4 className="font-bold text-primary-1200 text-lg dark:text-primary-100 flex gap-1 items-center mb-4 justify-between">
        Your info:
        <span>
          {hidden ? (
            <svg
              onClick={() => {
                setHidden(false);
              }}
              className="cursor-pointer hover:text-primary-200 hover:dark:text-white h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              />
            </svg>
          ) : (
            <svg
              onClick={() => {
                setHidden(true);
              }}
              className="cursor-pointer hover:text-primary-200 hover:dark:text-white h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
          )}
        </span>
      </h4>
      <h4 className="font-medium text-primary-1200 dark:text-primary-100">Account info:</h4>

      <div className="flex gap-1 justify-between items-center">
        <p className="text-thin">
          Display Name: <span className=" text-blue-600 dark:text-white">{state.display_name || "[none]"}</span>
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 hover:text-primary-400 hover:dark:text-white cursor-pointer"
          onClick={() => {
            editDisplayName();
          }}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      </div>
      <div className="flex gap-1 justify-between items-center">
        <p className="text-thin">
          Username: <span className=" text-blue-600 dark:text-white">{state.display_name ? showText(state.username) : state.username}</span>
        </p>
      </div>
      <div className="flex gap-1 justify-between items-center">
        <p className="text-thin">Password</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 hover:text-primary-400 hover:dark:text-white cursor-pointer"
          onClick={() => {
            navigate("/reset-password");
          }}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      </div>

      <div className="flex gap-1 justify-between items-center">
        <p className="text-thin">
          Email: <span className=" text-blue-600 dark:text-white">{showText(state.email, true)}</span>
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 hover:text-primary-400 hover:dark:text-white cursor-pointer"
          onClick={() => {
            navigate("/change-email");
          }}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      </div>
      <div className="flex gap-1 justify-between items-center">
        <p className="text-thin">
          Email Status: <span className={!state.has_valid_email ? "text-red-500 dark:text-red-300" : "text-blue-600 dark:text-white"}>{state.has_valid_email ? "Valid" : "Not Validated"}</span>
        </p>
      </div>
      <div className="flex gap-1 justify-between items-center">
        <p className="text-thin">
          Date Joined: <span className=" text-blue-600 dark:text-white">{state.date_joined?.split("T")[0]}</span>
        </p>
      </div>
      {state.is_superuser ? (
        <div className="flex gap-1 justify-between items-center">
          <p className="text-thin">
            Admin Status: <span className=" text-blue-600 dark:text-white">{state.is_superuser ? "Yes" : "No"}</span>
          </p>
        </div>
      ) : (
        <></>
      )}
      <div className="flex gap-1 justify-between items-center">
        <p className="text-thin">
          Maximum Devices: <span className=" text-blue-600 dark:text-white">{state.max_sessions_allowed}</span>
        </p>
      </div>
      {state.max_sessions_allowed > 1 ? (
        <div className="flex gap-1 flex-wrap justify-between items-center">
          <p className="text-thin flex max-w-[30ch]">Automatically update selected wordlists for all devices:</p>
          <div className="flex gap-2 justify-center items-center">
            <p className=" text-blue-600 dark:text-white">{state.always_save_wordlist_across_devices ? "Yes" : "No"}</p>
            <button
              onClick={() => {
                toggleWordlistSelectMode();
              }}
              disabled={buttonDisabled}
              className="text-thin flex gap-1 border-[1px] hover:text-primary-400 hover:dark:text-white cursor-pointer border-primary-400 hover:border-primary-400 hover:dark:border-white p-1 rounded-sm disabled:text-gray-500 disabled:border-gray-500 disabled:pointer-events-none"
            >
              Toggle
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {popupOpen ? (
        <BlackBgBackdrop onClose={() => closePopup()}>
          <div className="bg-stone-50 dark:bg-slate-800 rounded-lg p-5 m-2 justify-center gap-4 flex flex-col items-center  max-w-[400px]">{popupContents()}</div>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
    </div>
  );
}
